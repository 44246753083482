package mysensors.recorddetail

import app.hashManager
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement
import senscloud.common.domPage.PageBase
import senscloud.common.entity.api.user.mysensors.EntUserApiMySensors
import senscloud.common.entity.api.user.mysensors.EntUserApiMySensorsAllDataV
import senscloud.common.vali.__EMPTY__
import senscloud.user.app.ClientUserApiMySensors
import senscloud.user.app.ClientUserApiMySensorsAllDataV

class RecordDetailP(router:eRouter<HTMLElement>): PageBase(::RecordDetailView, router){
    override suspend fun net(data:eRouterData){
        data.parse(EntUserApiMySensorsAllDataV.request())?.also { ent ->
            ClientUserApiMySensorsAllDataV.net{
                it.rowid = ent.rowid
            }?.also {
                view?.entity(it)
            }
        }?: hashManager.back()
    }
}
package senscloud.common.entity.api.user.member

import ein2b.core.entity.eEntity
import senscloud.common.enm.EnumApiAccessAllow
import senscloud.common.entity.api.user.common.EntUserApi
import senscloud.common.entity.api.user.common.USER_API_MEMBER_PATH
import senscloud.common.vali.ValiMemberFullName
import senscloud.common.vali.ValiMemberPhone
import senscloud.common.vali.ValiMemberProfileUrl
import senscloud.common.vali.ValiMemberUserName

//서브 계정 관리(리스트)
object EntUserApiMemberSub: EntUserApi<EntUserApiMemberSub.Req, EntUserApiMemberSub.Res>(::Req, ::Res){
    const val PATH = "$USER_API_MEMBER_PATH/sub"
    const val PATH_JSON = "$PATH/json"
    class Req: eEntity()
    class Res: eEntity(){
        class Member: eEntity(true){
            var username:String by string(::username)
            var fullName:String by string(::fullName)
            var phone:String by string(::phone)
            var profileUrl:String by string(::profileUrl)
        }
        var memberList: MutableList<Member> by entityList(::memberList, ::Member){
            default(mutableListOf(), false)
        }
    }
    override val accessAllow get() = EnumApiAccessAllow.MASTER
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val rsckeys = "subaccount"
}


//서브 계정 등록 준비
object EntUserApiMemberSubW: EntUserApi<EntUserApiMemberSubW.Req, EntUserApiMemberSubW.Res>(::Req, ::Res){
    const val PATH = "$USER_API_MEMBER_PATH/sub/w"
    const val PATH_JSON = "$PATH/json"
    class Req: eEntity()
    class Res: eEntity(){
        var profileUrlList: MutableList<String> by stringList(::profileUrlList){
            default(mutableListOf(), false)
        }
    }
    override val accessAllow get() = EnumApiAccessAllow.MASTER
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val rsckeys = "subaccount_new_edit"
}

//서브 계정 등록
object EntUserApiMemberSubWp: EntUserApi<EntUserApiMemberSubWp.Req, EntUserApiMemberSubWp.Res>(::Req, ::Res){
    const val PATH = "$USER_API_MEMBER_PATH/sub/wp"
    const val PATH_JSON = "$PATH/json"
    class Req: eEntity(){
        var rsaId:String by string(::rsaId)
        var username:String by string(::username){validator(ValiMemberUserName)}
        var pw:String by string(::pw) //view 단에서 rsa로 변환해서 주기 때문에 밸리 걸 수 없음
        var fullName:String by string(::fullName){validator(ValiMemberFullName)}
        var phone:String by string(::phone){validator(ValiMemberPhone)}
        var profileUrl:String by string(::profileUrl){validator(ValiMemberProfileUrl)}
    }
    class Res: eEntity(){
        companion object{
            private val ERROR = Error()
        }
        class Error: eEntity(true){
            // - 아이디: 이미 등록됨 -> "이미 사용 중인 아이디입니다."
            var username:String by string(::username)
        }
        var error: Error by entity(::error, ::Error){
            default(ERROR, true)
        }
        val isError get() = error !== ERROR
    }
    override val accessAllow get() = EnumApiAccessAllow.MASTER
    override val path = PATH
    override val jsonPath = PATH_JSON
}

//서브 계정 수정 준비
object EntUserApiMemberSubE: EntUserApi<EntUserApiMemberSubE.Req, EntUserApiMemberSubE.Res>(::Req, ::Res){
    const val PATH = "$USER_API_MEMBER_PATH/sub/e"
    const val PATH_JSON = "$PATH/json"
    class Req: eEntity(){
        var username:String by string(::username)
    }
    class Res: eEntity(){
        var username:String by string(::username)
        var fullName:String by string(::fullName)
        var phone:String by string(::phone)
        var profileUrl:String by string(::profileUrl)
        var profileUrlList: MutableList<String> by stringList(::profileUrlList){
            default(mutableListOf(), false)
        }
    }
    override val accessAllow get() = EnumApiAccessAllow.MASTER
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val rsckeys = "subaccount_new_edit"
}

//서브 계정 수정
object EntUserApiMemberSubEp: EntUserApi<EntUserApiMemberSubEp.Req, EntUserApiMemberSubEp.Res>(::Req, ::Res){
    const val PATH = "$USER_API_MEMBER_PATH/sub/ep"
    const val PATH_JSON = "$PATH/json"
    override val rsckeys = "subaccount_new_edit, signup, login, pwchange, myinfo"

    class Req: eEntity(){
        var username:String by string(::username)
        var fullName:String by string(::fullName){validator(ValiMemberFullName)}
        var phone:String by string(::phone){validator(ValiMemberPhone)}
        var profileUrl:String by string(::profileUrl){validator(ValiMemberProfileUrl)}
    }
    class Res: eEntity()
    override val accessAllow get() = EnumApiAccessAllow.MASTER
    override val path = PATH
    override val jsonPath = PATH_JSON
}

//내정보 수정 준비(서브)
object EntUserApiMemberSubInfoE: EntUserApi<EntUserApiMemberSubInfoE.Req, EntUserApiMemberSubInfoE.Res>(::Req, ::Res){
    const val PATH = "$USER_API_MEMBER_PATH/sub/info/e"
    const val PATH_JSON = "$PATH/json"

    class Req: eEntity()
    class Res: eEntity(){
        var fullName:String by string(::fullName)
        var phone:String by string(::phone)
        var profileUrl:String by string(::profileUrl)
        var profileUrlList: MutableList<String> by stringList(::profileUrlList){
            default(mutableListOf(), false)
        }
    }
    override val rsckeys = "myinfo"
    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
}

//내정보 수정(서브)
object EntUserApiMemberSubInfoEp: EntUserApi<EntUserApiMemberSubInfoEp.Req, EntUserApiMemberSubInfoEp.Res>(::Req, ::Res){
    const val PATH = "$USER_API_MEMBER_PATH/sub/info/ep"
    const val PATH_JSON = "$PATH/json"

    class Req: eEntity(){
        var fullName:String by string(::fullName){validator(ValiMemberFullName)}
        var phone:String by string(::phone){validator(ValiMemberPhone)}
        var profileUrl:String by string(::profileUrl){validator(ValiMemberProfileUrl)}
    }
    override val rsckeys = "myinfo"
    class Res: eEntity()
    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON

}
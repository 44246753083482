package app

import address.AddressBookP
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.router.eHashManager
import ein2b.core.view.router.eStaticRouter
import member.changePw.ChangePwP
import member.join.JoinP
import member.login.LoginP
import member.myinfoMaster.MyInfoMasterP
import member.subAdd.SubAddP
import member.subEdit.SubEditP
import member.subInfo.MyInfoSubP
import member.subManagement.SubManagementP
import mysensors.recorddetail.RecordDetailP
import mysensors.recordlist.RecordListP
import mysensors.sensor.SensorP
import rental.rent.RentP
import rental.rentalDetail.RentalDetailP
import rental.rentallist.RentalListP
import senscloud.common.app.Router
import senscloud.common.app.Router.staticRouterEl
import senscloud.common.app.Router.uiRouterEl
import senscloud.user.app.RouterKey.ADDRESS_BOOK
import senscloud.user.app.RouterKey.CHANGE_PW
import senscloud.user.app.RouterKey.LOGIN
import senscloud.user.app.RouterKey.MYINFO_MASTER
import senscloud.user.app.RouterKey.MYINFO_SUB
import senscloud.user.app.RouterKey.MYSENSOR
import senscloud.user.app.RouterKey.MYSENSOR_DATADETAIL
import senscloud.user.app.RouterKey.MYSENSOR_DATALIST
import senscloud.user.app.RouterKey.RENTAL_DETAIL
import senscloud.user.app.RouterKey.RENTAL_LIST
import senscloud.user.app.RouterKey.RENT_A_SENSOR
import senscloud.user.app.RouterKey.SHIPMENT_ADD
import senscloud.user.app.RouterKey.SHIPMENT_DELIVERED
import senscloud.user.app.RouterKey.SHIPMENT_DETAIL
import senscloud.user.app.RouterKey.SHIPMENT_ONGOING
import senscloud.user.app.RouterKey.SIGNUP
import senscloud.user.app.RouterKey.SUB_ACCOUNTS
import senscloud.user.app.RouterKey.SUB_ACCOUNT_ADD
import senscloud.user.app.RouterKey.SUB_ACCOUNT_EDIT
import senscloud.user.modalView.shipping.markAsDeliveredModalClose
import shipment.create.CreateShipmentP
import shipment.delivered.DeliveredP
import shipment.detail.DetailP
import shipment.ongoing.OngoingP
import view.CompViewLnb

internal val hashManager = eHashManager(LOGIN, {
    eLaunch{
        markAsDeliveredModalClose()
        CompViewLnb.addHook(it)
    }
}).apply{
    addRouters(
        eStaticRouter(Router.LoginRouter(uiRouterEl)).apply {
            addFactories(
                LOGIN to { router -> LoginP(router) },
                SIGNUP to { router -> JoinP(router) }
            )
        },
        eStaticRouter(Router.StaticRouter(staticRouterEl)).apply {
            addFactories(
//                HOME to { router -> HomeP(router) },
                MYINFO_MASTER to { router -> MyInfoMasterP(router) },
                MYINFO_SUB to { router -> MyInfoSubP(router) },
                CHANGE_PW to { router -> ChangePwP(router) },
                SUB_ACCOUNTS to { router -> SubManagementP(router) },
                SUB_ACCOUNT_ADD to { router -> SubAddP(router) },
                SUB_ACCOUNT_EDIT to { router -> SubEditP(router) },
                SHIPMENT_ONGOING to { router -> OngoingP(router) },
                SHIPMENT_DELIVERED to { router -> DeliveredP(router)},
                MYSENSOR to { router -> SensorP(router) },
                MYSENSOR_DATALIST to { router -> RecordListP(router) },
                MYSENSOR_DATADETAIL to { router -> RecordDetailP(router) },
                RENT_A_SENSOR to { router -> RentP(router) },
                RENTAL_LIST to { router -> RentalListP(router) },
                RENTAL_DETAIL to { router -> RentalDetailP(router) },
                SHIPMENT_DETAIL to { router -> DetailP(router) },
                SHIPMENT_ADD to { router -> CreateShipmentP(router) },
                ADDRESS_BOOK to { router -> AddressBookP(router) }
            )
        }
    )
}
package senscloud.common.vali

import ein2b.core.validation.eRuleVali

val ValiMasterCompanyName = eRuleVali{
    Case{
        Rule{"$it".trim()}
        MinLength(1, "c@Enter your company name.@common/vali/company/01")
        MaxLength(32, "c@Company name must be under 32 characters.@common/vali/company/02")
    }
}

val ValiMasterEmail = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "c@Enter your email address.@common/vali/email/01")
        MaxLength(255, "c@Email address must be under 255 characters.@common/vali/email/03")
        Email("c@Invalid email address format.@common/vali/email/02")
    }
}

package member.myinfoMaster

import Factory
import senscloud.common.app.App
import senscloud.common.entity.EntInit
import senscloud.common.entity.api.user.member.EntUserApiMemberMasterInfoEp
import senscloud.common.vali.*
import senscloud.user.app.ClientUserApiImageWp
import senscloud.user.app.ClientUserApiMemberMasterInfoE
import senscloud.user.app.ClientUserApiMemberMasterInfoEp
import senscloud.user.entity.member.*
import comp.CompPageTitle
import comp.compProfileImageListSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.click
import ein2b.core.view.eView
import ein2b.core.view.html
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
import prop.*
import view.CompViewToast

private val factory = Factory.htmlUrl("member/myinfoMasterT")

private enum class K{
    fullName, phone, companyName, email,
    editBtn, masterInfo, compProfile,
    MYINFO, CHOOSE;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

suspend fun MyInfoMasterView() = eView(factory){ rootView->
    val editForm = EntUserApiMemberMasterInfoEp.request()
    //lateinit var ent: EntClientProfile

    App.subHtmlFromLabel(rootView,
        K.MYINFO to "c@My info@myinfo/title/myinfo",
        K.CHOOSE to "c@Or select an avatar:@myinfo/label/selectprofileimage"
        )
    //App.emptySub(rootView, K.uploadFile, K.profileList, K.masterInfo, K.editBtn)
    App.emptySub(rootView, K.masterInfo, K.editBtn)
    CompPageTitle(rootView){
        it.title = "c@My info@myinfo/title2/myinfo"
    }

    rootView.compLabelInputSectionTextSet(K.fullName, "c@Full name@myinfo/label/fullname", ValiMemberFullName)

    rootView.compLabelInputSectionTextSet(K.phone, "c@Phone@myinfo/label/phone", ValiMemberPhone)

    rootView.compLabelInputSectionTextSet(K.companyName, "c@Company name@myinfo/label/companyname", ValiMasterCompanyName)

    rootView.compLabelInputSectionTextSet(K.email, "c@Email address@myinfo/label/emailaddress", ValiMasterEmail)

    rootView.sub(K.editBtn) {
        it.html = "c@Save@myinfo/button/save"
        it.click = {_,_ ->
            eLaunch {
                val compFullName = rootView.sub(K.fullName).compLabelInputSectionText()
                val compPhone = rootView.sub(K.phone).compLabelInputSectionText()
                val companyName = rootView.sub(K.companyName).compLabelInputSectionText()
                val compEmail = rootView.sub(K.email).compLabelInputSectionText()
                if(App.checkAll(compFullName, compPhone, companyName, compEmail)) {
                    ClientUserApiMemberMasterInfoEp.net { req ->
                        req.profileUrl = editForm.profileUrl
                        req.fullName = compFullName.out()
                        req.phone = compPhone.out()
                        req.companyName = companyName.out()
                        req.email = compEmail.out()
                    }?.also {
                        ClientUserApiMemberMasterInfoE.net()?.also { res ->
                            rootView.entity(EntClientMemberMasterInfoE(res))
                            CompViewToast.open("c@My info was updated.@myinfo/toast/01", "success-badge", CompViewToast.TOAST_TYPE.SUCCESS)
                        }
                    }
                }
            }
        }
    }
    rootView.compProfileImageListSet(K.compProfile){
        it.title = "c@Profile image@myinfo/title/profileimage"
        it.message = "c@Or select an avatar:@myinfo/label/selectprofileimage"
        it.btnLabel = "c@Upload@myinfo/label/Upload"
    }

    rootView.addEntityHook(EntClientMemberMasterInfoE::class, object:eEntityHook<HTMLElement, EntClientMemberMasterInfoE> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntClientMemberMasterInfoE){
            editForm.profileUrl = entity.profileUrl

            view.sub(K.compProfile).compProfileImageList { comp ->
                comp.setProfileData(entity.profileUrl, entity.profileUrlList)
                comp.setAfterUploaded {
                    eLaunch {
                        ClientUserApiImageWp.uploadNet(it)?.also {
                            comp.setProfileData(it.imageUrl, entity.profileUrlList)
                            editForm.profileUrl = it.imageUrl
                        }
                    }
                }
                comp.setClickItem {
                    editForm.profileUrl = it
                }
            }

            view.entity(EntInit)
            view.sub(K.masterInfo).displayBlock()
            view.sub(K.fullName).compLabelInputSectionText().inputValue(entity.fullName)
            view.sub(K.phone).compLabelInputSectionText().inputValue(entity.phone)
            view.sub(K.companyName).compLabelInputSectionText().inputValue(entity.companyName)
            view.sub(K.email).compLabelInputSectionText().inputValue(entity.email)
        }
    })

    rootView.addEntityHook(EntInit::class, object:eEntityHook<HTMLElement, EntInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntInit){
            view.clearCompValue(K.fullName, K.phone, K.companyName, K.email)
        }
    })

}
package senscloud.user.entity.rental

import ein2b.core.entity.eEntity
import senscloud.common.entity.api.user.rental.EntUserApiRentalV
import senscloud.user.app.AppUser

class EntClientUserRentalV(target: EntUserApiRentalV.Res):eEntity() {
    class Tab1: eEntity(true){
        class Sensor: eEntity(){
            var imageUrl:String by string(::imageUrl)
            var sensorName:String by string(::sensorName)
            var temp:String by string(::temp)
            var contents:String by string(::contents)
            var requestQty:String by string(::requestQty)
        }
        class ShipTo: eEntity(){
            var fullName:String by string(::fullName)
            var phone:String by string(::phone)
            var addr1:String by string(::addr1)
            var addr2:String by string(::addr2){default("-")}
        }
        class RentalInfo: eEntity(){
            var rentalState:String by string(::rentalState)
            var date:String by string(::date)
            var message:String by string(::message){default("-")}
        }
        val sensorList: MutableList<Sensor> by entityList(::sensorList, ::Sensor){default(mutableListOf(), false)}
        var shipTo: ShipTo by entity(::shipTo, ::ShipTo){ default(ShipTo(), false) }
        var rentalInfo: RentalInfo by entity(::rentalInfo, ::RentalInfo){ default(RentalInfo(), false) }
    }
    class Tab2: eEntity(true){
        class Receive: eEntity(){
            var regDate:String by string(::regDate)
            var sensorName:String by string(::sensorName)
            var qty:String by string(::qty)
            var date:String by string(::date)
            var codeList: MutableList<String> by stringList(::codeList){default(mutableListOf())}
        }
        var receiveList: MutableList<Receive> by entityList(::receiveList, ::Receive){ default(mutableListOf(), false) }
        val receiveCnt get() = receiveList.size
    }
    var rentalRowid:String by string(::rentalRowid){default(target.rentalRowid)}
    var title:String by string(::title){ default("${target.rentalRowid}") }
    var tab1: Tab1 by entity(::tab1, ::Tab1){
        default(
            Tab1().also { tab1 ->
                tab1.sensorList += target.sensorList.map { from ->
                    Tab1.Sensor().also { to ->
                        to.imageUrl= from.imageUrl
                        to.sensorName= from.sensorName
                        to.temp= "${from.minTemp} ~ ${from.maxTemp}"
                        to.contents= from.contents
                        to.requestQty= from.requestQty
                    }
                }.toMutableList()
                tab1.shipTo = Tab1.ShipTo().also {
                    it.fullName= target.name
                    it.phone= AppUser.phoneFormatter(target.phone)
                    it.addr1= target.addr1
                    it.addr2= target.addr2.ifEmpty {"-"}
                }
                tab1.rentalInfo = Tab1.RentalInfo().also {
                    it.rentalState= target.rentalState.title
                    it.date= "${target.startDate} ~ ${target.endDate}"
                    it.message= target.message.ifEmpty {"-"}
                }
            }
        )
    }
    var tab2: Tab2 by entity(::tab2, ::Tab2){
        default(
            Tab2().also { tab2 ->
                tab2.receiveList += target.receiveList.map { from ->
                    Tab2.Receive().also { to ->
                        to.regDate = from.regDate
                        to.sensorName = from.sensorName
                        to.qty = from.qty
                        to.date = "${from.startDate} ~ ${from.endDate}"
                        to.codeList = from.codeList
                    }
                }.toMutableList()
            }
        )
    }
}

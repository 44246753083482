package senscloud.common.entity.api.user.member

import senscloud.common.enm.EnumApiAccessAllow
import senscloud.common.entity.api.user.common.EntUserApi
import senscloud.common.entity.api.user.common.USER_API_MEMBER_PATH
import senscloud.common.vali.*
import ein2b.core.entity.eEntity
import senscloud.common.entity.api.user.common.USER_API_PATH

//로그인
object EntUserApiTestMemberLogin: EntUserApi<EntUserApiTestMemberLogin.Req, EntUserApiTestMemberLogin.Res>(::Req, ::Res){
    const val PATH = "$USER_API_PATH/test/member/login"
    const val PATH_JSON = "$PATH/json"
    class Req: eEntity(){
        var username:String by string(::username){validator(ValiMemberLoginUserName)}
        var pw:String by string(::pw)
    }
    class Res: eEntity(true)

    override val accessAllow get() = EnumApiAccessAllow.LOGOUT
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val rsckeys = "login"
}
object EntUserApiMemberLogin: EntUserApi<EntUserApiMemberLogin.Req, EntUserApiMemberLogin.Res>(::Req, ::Res){
    const val PATH = "$USER_API_MEMBER_PATH/login"
    const val PATH_JSON = "$PATH/json"
    class Req: eEntity(){
        var rsaId:String by string(::rsaId)
        var username:String by string(::username){validator(ValiMemberLoginUserName)}
        //view 단에서 rsa로 변환해서 주기 때문에 밸리 걸 수 없음
        var pw:String by string(::pw)
    }
    class Res: eEntity(true){
        companion object{
            private val ERROR = Error()
        }
        class Error: eEntity(true){
            // - 아이디: 존재하지 않음 ->  가입되지 않은 아이디입니다.
            var username:String by string(::username){ default("", false) }
            // - 비밀번호: 일치하지 않음 -> 비밀번호가 일치하지 않습니다.
            var pw:String by string(::pw){ default("", false) }
        }
        var error: Error by entity(::error, ::Error){
            default(ERROR, true)
        }
        val isError get() = error !== ERROR
    }
    override val accessAllow get() = EnumApiAccessAllow.LOGOUT
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val rsckeys = "login"
}

//로그인 체크
object EntUserApiMemberLoginCheck: EntUserApi<EntUserApiMemberLoginCheck.Req, EntUserApiMemberLoginCheck.Res>(::Req, ::Res){
    const val PATH = "$USER_API_MEMBER_PATH/logincheck"
    const val PATH_JSON = "$PATH/json"
    override val rsckeys = "login"
    class Req: eEntity()
    class Res: eEntity()
    override val accessAllow get() = EnumApiAccessAllow.LOGOUT
    override val path = PATH
    override val jsonPath = PATH_JSON
}

//로그아웃
object EntUserApiMemberLogout: EntUserApi<EntUserApiMemberLogout.Req, EntUserApiMemberLogout.Res>(::Req, ::Res){
    const val PATH = "$USER_API_MEMBER_PATH/logout"
    const val PATH_JSON = "$PATH/json"
    class Req: eEntity()
    class Res: eEntity()
    override val accessAllow get() = EnumApiAccessAllow.LOGOUT
    override val midIgnore = true
    override val path = PATH
    override val jsonPath = PATH_JSON
}

//비밀번호 변경 준비
object EntUserApiMemberPwE: EntUserApi<EntUserApiMemberPwE.Req, EntUserApiMemberPwE.Res>(::Req, ::Res){
    const val PATH = "$USER_API_MEMBER_PATH/pw/e"
    const val PATH_JSON = "$PATH/json"
    override val rsckeys = "pwchange"

    class Req: eEntity()
    class Res: eEntity()
    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
}

//비밀번호 변경
object EntUserApiMemberPwEp: EntUserApi<EntUserApiMemberPwEp.Req, EntUserApiMemberPwEp.Res>(::Req, ::Res){
    const val PATH = "$USER_API_MEMBER_PATH/pw/ep"
    const val PATH_JSON = "$PATH/json"
    class Req: eEntity(){
        var rsaId:String by string(::rsaId)
        var oldPw:String by string(::oldPw) //view 단에서 rsa로 변환해서 주기 때문에 밸리 걸 수 없음
        var newPw:String by string(::newPw) //view 단에서 rsa로 변환해서 주기 때문에 밸리 걸 수 없음
    }
    class Res: eEntity(){
        companion object{
            private val ERROR = Error()
        }
        class Error: eEntity(true){
            //기존 비밀번호: 일치하지 않음 ->  "c@Wrong password.@pwchange/vali/currentpw/02"
            var oldPw:String by string(::oldPw)
        }
        var error: Error by entity(::error, ::Error){
            default(ERROR, true)
        }
        val isError get() = error !== ERROR
    }
    override val rsckeys = "pwchange"
    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
}
package mysensors.recorddetail

import Factory
import app.hashManager
import comp.CompPageTitle
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import ein2b.core.view.html
import org.w3c.dom.HTMLElement
import prop.compPageTitle
import senscloud.common.app.App
import senscloud.common.entity.api.user.mysensors.EntUserApiMySensorsAllDataV
import senscloud.user.comp.compSensorExcelSet
import senscloud.user.comp.compSensorPdfBtnSet
import senscloud.user.comp.compSensorPdfSet
import senscloud.util.Chart
import senscloud.util.Chart.chart
import senscloud.util.SensorTempInfoList

private val factory = Factory.htmlUrl("mysensors/recorddetail/recordDetail")

private enum class K{
    nav, DATE, MAX, MIN, LAST,
    date, maxTemp, minTemp, lastTemp, invoiceList, chartTitle, chart,
    exportPDF, exportExcel, pdfWrap;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

suspend fun RecordDetailView() = eView(factory){ rootView ->
    CompPageTitle(rootView) {
        it.backClick = {
            eLaunch { hashManager.back() }
        }
    }
    App.subHtmlFromLabel(rootView,
    K.DATE to "c@Date@recorddetail/title/date",
    K.MAX to "c@Min@recorddetail/title/min",
    K.MIN to "c@Max@recorddetail/title/max",
    K.LAST to "c@Last@recorddetail/title/last")

    App.emptySub(rootView, K.date, K.maxTemp, K.minTemp, K.lastTemp, K.invoiceList, K.chartTitle, K.exportPDF, K.exportExcel)
    val chartId = Chart.id
    rootView.sub(K.chart).chart(chartId)
    val pdfChartId = rootView.compSensorPdfSet(K.pdfWrap)

    rootView.addEntityHook(EntUserApiMySensorsAllDataV.Res::class, object : eEntityHook<HTMLElement, EntUserApiMySensorsAllDataV.Res> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntUserApiMySensorsAllDataV.Res){
            view.sub(K.nav).compPageTitle().setTitle("c@sensorcode - Record #N@recorddetail/title/record", "v0" to entity.sensorCode, "v1" to entity.sensorSessionRowid)
            view.sub(K.date).html = "${entity.record.startDate} ~ ${entity.record.endDate}"
            view.sub(K.maxTemp).html = entity.record.maxTemp
            view.sub(K.minTemp).html = entity.record.minTemp
            view.sub(K.lastTemp).html = entity.record.lastTemp
            view.sub(K.chartTitle).html = "${entity.record.startDate} ~ ${entity.record.endDate}"

            val sensorTempInfoList = SensorTempInfoList().also{ sData->
                sData.itemConditionMinMax = mutableListOf(Chart.tempFloat(entity.record.catMinTemp), Chart.tempFloat(entity.record.catMaxTemp))
                sData.list = mutableListOf(entity.record.tempInfo)
            }
            Chart.tempInfoGraph(chartId, sensorTempInfoList)

            rootView.compSensorPdfBtnSet(K.exportPDF, "c@Download PDF@recorddetail/button/downloadpdf", entity.sensorSessionRowid, pdfChartId, sensorTempInfoList)
            rootView.compSensorExcelSet(K.exportExcel, "c@Download Excel@recorddetail/button/downloadexcel", entity.sensorSessionRowid)
        }
    })
}


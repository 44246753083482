package member.login

import Factory
import app.hashManager
import comp.CompLogin
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import org.w3c.dom.HTMLElement
import senscloud.common.entity.api.user.common.EntUserApiRsa
import senscloud.common.vali.ValiMemberLoginPw
import senscloud.common.vali.ValiMemberLoginUserName
import senscloud.user.app.ClientUserApiMemberLogin
import senscloud.user.app.RouterKey.SHIPMENT_ONGOING
import senscloud.user.app.RouterKey.SIGNUP

private val factory = Factory.htmlUrl("member/loginT")

suspend fun LoginView() = eView(factory) { rootView ->
    val compLogin = CompLogin(rootView,
        "c@Username@login/placeholder/username", ValiMemberLoginUserName,
        "c@Password@login/placeholder/pw", ValiMemberLoginPw ,
        "c@Login@login/button/login", { compReq->
            ClientUserApiMemberLogin.net{ req ->
                req.username = compReq.id
                req.pw = compReq.pw
                req.rsaId = compReq.rsaId
            }?.also{ res ->
                compReq.end(CompLogin.Res(res.isError, res.error.username, res.error.pw))
            }
        }, { hashManager.goUrl(SHIPMENT_ONGOING) },
        "c@Sign up@login/link/signup", { hashManager.goUrl(SIGNUP) })
    rootView.addEntityHook(EntUserApiRsa.Res::class, object : eEntityHook<HTMLElement, EntUserApiRsa.Res> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntUserApiRsa.Res) {
            compLogin.clear()
            compLogin.rsa = CompLogin.Rsa(entity.rsa.id, entity.rsa.key)
        }
    })
}
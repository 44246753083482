package senscloud.user.app

import senscloud.common.app.ApiRsc
import senscloud.common.app.App
import senscloud.common.enm.EnumApiErrorAction
import senscloud.common.enm.EnumApiErrorMethod
import senscloud.common.entity.api.user.common.*
import senscloud.common.entity.api.user.member.*
import senscloud.user.app.RouterKey.CHANGE_PW
import senscloud.user.app.RouterKey.LOGIN
import senscloud.user.app.RouterKey.MYINFO_MASTER
import senscloud.user.app.RouterKey.MYINFO_SUB
import senscloud.user.app.RouterKey.SUB_ACCOUNTS
import ein2b.core.coroutine.eLaunch
import ein2b.core.entity.eEntity
import ein2b.core.log.log
import ein2b.core.net.*
import kotlinx.browser.window
import org.w3c.files.File
import senscloud.common.entity.api.user.address.EntUserApiAddress
import senscloud.common.entity.api.user.address.EntUserApiAddressDp
import senscloud.common.entity.api.user.address.EntUserApiAddressEp
import senscloud.common.entity.api.user.address.EntUserApiAddressWp
import senscloud.common.entity.api.user.mysensors.*
import senscloud.common.entity.api.user.rental.EntUserApiRental
import senscloud.common.entity.api.user.rental.EntUserApiRentalV
import senscloud.common.entity.api.user.rental.EntUserApiRentalW
import senscloud.common.entity.api.user.rental.EntUserApiRentalWp
import senscloud.common.entity.api.user.shipment.*
import senscloud.user.app.RouterKey.MYSENSOR
import senscloud.user.app.RouterKey.SHIPMENT_ONGOING
import view.CompViewAlert
import view.CompViewBlocking
import view.CompViewLnb
import view.CompViewToast
import kotlin.js.Date

object ApiUser{
    init{
        eApi.sender = FetchSender()
    }
    val timeoffset = Date().getTimezoneOffset()
    val domain = if(window.location.hostname.startsWith("senscloud")) "https://api.senscloud.ai" else "http://${window.location.hostname}:${window.location.port}"
    var isLogin = false
    object ReqTimeOffSet:eRequestTask{
        override suspend fun run(request:eRequest):Boolean{
            request.header("timeoffset", "${Date().getTimezoneOffset()}")
            return true
        }
    }

    const val R_ENTITY = "entity"
    suspend fun error(e: EntUserApiResponse.Error, block:(()->Unit)? = null) {
        when(e.method){
            EnumApiErrorMethod.ALERT -> {
                when(e.action){
                    EnumApiErrorAction.MOVE_TO_LOGIN -> App.goUrl(LOGIN)
                    EnumApiErrorAction.NONE -> CompViewAlert.open(e.message, block = block)
                    EnumApiErrorAction.RELOAD -> CompViewAlert.open(e.message){ App.reload() }
                    else -> {}
                }
            }
            EnumApiErrorMethod.TOAST ->{
                if(block != null) block()
                CompViewToast.open(e.message)
            }
            EnumApiErrorMethod.TOAST_MOVE -> {
                when(e.action){
                    EnumApiErrorAction.MOVE_TO_MAIN -> App.goUrl(SHIPMENT_ONGOING)
                    EnumApiErrorAction.RELOAD -> App.reload()
                    EnumApiErrorAction.BACK -> window.history.back()
                    else -> {}
                }
                if(e.message.isNotEmpty()) CompViewToast.open(e.message, "error-badge", CompViewToast.TOAST_TYPE.ERROR)
            }
            else -> console.log(e.message)
        }
    }
}

abstract class UserApi<REQ:eEntity, RES:eEntity>(apiObject: EntUserApi<REQ, RES>): EntClientApi<REQ, RES>(apiObject){
    override suspend fun responseProcess(result: eApiResult): RES? {
        return if(result.isOk){
            @Suppress("UNCHECKED_CAST")
            (result.response!!.result as? UserResponse<RES>)?.let{
                ApiUser.isLogin = it.isLogin
                if(ApiUser.isLogin) {
                    (it.member as EntUserApiCommonMember.Login).also { mem ->
                        AppUser.sessionUserId = mem.username
                        AppUser.userId = mem.username
                        val userMenu = mutableListOf<CompViewLnb.MenuUser.UserMenu>()
                        userMenu += CompViewLnb.MenuUser.UserMenu("key1", "c@My info@common/lnb/link/myinfo") {
                            if(mem.isMaster) App.goUrl(MYINFO_MASTER) else App.goUrl(MYINFO_SUB)
                        }
                        userMenu += CompViewLnb.MenuUser.UserMenu("key2", "c@Change password@common/lnb/link/pwchange"){ App.goUrl(CHANGE_PW) }
                        if(mem.isMaster) {
                            userMenu += CompViewLnb.MenuUser.UserMenu("key3", "c@Sub accounts@common/lnb/link/sub"){ App.goUrl(SUB_ACCOUNTS) }
                        }
                        userMenu += CompViewLnb.MenuUser.UserMenu("key4", "c@Logout@common/lnb/link/logout", "logout"){
                            eLaunch { ClientUserApiMemberLogout.net()?.also {App.goUrl(LOGIN) } }
                        }
                        CompViewLnb.menuUser = CompViewLnb.MenuUser(
                            mem.profileUrl,
                            if(mem.isMaster) "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/24/ae7e3257ca3727c0ceb97d6e50a0f50b.svg" else "",
                            mem.fullName, mem.companyName
                        ).apply {
                            menuList = userMenu
                        }
                        CompViewLnb.setUser()
                        CompViewLnb.menuList.find{ it.key == "sensor" }?.also {
                            it.subList = mem.sensorCatList.map{ sc ->
                                CompViewLnb.MenuItem.SubItem(sc.sensorCatRowid, "┗ ${sc.title}").also{
                                    it.clickBlock = { App.goUrl(MYSENSOR, "sensorCatRowid" to sc.sensorCatRowid) }
                                }
                            }.toMutableList()
                        }
                        CompViewLnb.menuList.find{ it.key == "shipment" }?.also { me ->
                            me.subList.find{ m -> m.key == SHIPMENT_ONGOING }?.also { sme -> sme.cnt = mem.onGoingCount.toInt() }
                        }
                        CompViewLnb.setList()
                    }
                }else (it.member as? EntUserApiCommonMember.Logout)?.also{
                    AppUser.sessionUserId = ""
                    AppUser.userId = ""
                }
                if(it.isError){
                    ApiUser.error(it.error)
                    null
                }else it.data
            }
        }else{
            log(result.err)
            null
        }
    }
    override var api: eApi = eApi("", eApi.DEFAULT to eApiInfo{
        url = "${ApiUser.domain}${apiObject.url()}"
        method = eApi.POST

        items += ApiUser.R_ENTITY
        items += "mid"

        requestTask += ApiUser.ReqTimeOffSet
        //requestTask += eRequestTask.ReadTimeOut(20000)

        requestTask += eRequestTask.Header("mid")

        requestTask += eRequestTask.JsonFromEntity(ApiUser.R_ENTITY)


        responseTask += eResponseTask.Text
        responseTask += eResponseTask.Entity { UserResponse { apiObject.response() } }
    })
    var uploadApi: eApi = eApi("", eApi.DEFAULT to eApiInfo{
        url = "${ApiUser.domain}${apiObject.url()}"
        method = eApi.POST

        items += ApiUser.R_ENTITY
        items += "upfile"
        items += "mid"

        requestTask += ApiUser.ReqTimeOffSet

        requestTask += eRequestTask.Header("mid")

        requestTask += eRequestTask.JsonFromEntity(ApiUser.R_ENTITY)
        requestTask += eRequestTask.BlobFile("upfile")

        responseTask += eResponseTask.Text
        responseTask += eResponseTask.Entity { UserResponse { apiObject.response() } }
    })
    suspend fun net(block:(suspend (req:REQ)->Unit)?=null):RES? {
        val req = apiObject.request()
        CompViewBlocking.open()
        block?.invoke(req)
        if(apiObject.rsckeys.isNotBlank()) ApiRsc.rsc(*ApiRsc.toRscArray(apiObject.rsckeys))
        return net(req)?.also{ CompViewBlocking.close() }
    }
    suspend fun uploadNet(upFile:File, block:(suspend (req:REQ)->Unit)?=null):RES? {
        val req = apiObject.request()
        CompViewBlocking.open()
        block?.invoke(req)
        if(apiObject.rsckeys.isNotBlank()) ApiRsc.rsc(*ApiRsc.toRscArray(apiObject.rsckeys))
        return uploadNet(upFile, req)?.also{ CompViewBlocking.close() }
    }
    private fun checkSession():Boolean {
        val newId = AppUser.userId
        if(newId != AppUser.sessionUserId){
            AppUser.sessionUserId = newId
            if(newId.isNotBlank() && AppUser.loginPage) {
                App.goUrl(SHIPMENT_ONGOING)
                return false
            }
        }
        return true
    }
    protected open suspend fun net(req:REQ):RES? = if(checkSession()) responseProcess(api(ApiUser.R_ENTITY to {req}, "mid" to AppUser.sessionUserId)) else null
    protected open suspend fun uploadNet(upFile:File, req:REQ):RES? = if(checkSession()) responseProcess(uploadApi(
        ApiUser.R_ENTITY to { req }, "upfile" to upFile, "mid" to AppUser.sessionUserId)) else null
}



object ClientUserApiRsa:UserApi<EntUserApiRsa.Req, EntUserApiRsa.Res>(EntUserApiRsa)
object ClientUserApiImageWp:UserApi<EntUserApiMemberImageWp.Req, EntUserApiMemberImageWp.Res>(EntUserApiMemberImageWp)
object ClientUserApiHome:UserApi<EntUserApiHome.Req, EntUserApiHome.Res>(EntUserApiHome)
object ClientUserApiMemberLogin:UserApi<EntUserApiMemberLogin.Req, EntUserApiMemberLogin.Res>(EntUserApiMemberLogin)
object ClientUserApiMemberLoginCheck:UserApi<EntUserApiMemberLoginCheck.Req, EntUserApiMemberLoginCheck.Res>(EntUserApiMemberLoginCheck)
object ClientUserApiMemberLogout:UserApi<EntUserApiMemberLogout.Req, EntUserApiMemberLogout.Res>(EntUserApiMemberLogout)
object ClientUserApiMemberMasterJoinWp:UserApi<EntUserApiMemberMasterJoinWp.Req, EntUserApiMemberMasterJoinWp.Res>(EntUserApiMemberMasterJoinWp)
object ClientUserApiMemberMasterInfoE:UserApi<EntUserApiMemberMasterInfoE.Req, EntUserApiMemberMasterInfoE.Res>(EntUserApiMemberMasterInfoE)
object ClientUserApiMemberMasterInfoEp:UserApi<EntUserApiMemberMasterInfoEp.Req, EntUserApiMemberMasterInfoEp.Res>(EntUserApiMemberMasterInfoEp)
object ClientUserApiMemberSubInfoE:UserApi<EntUserApiMemberSubInfoE.Req, EntUserApiMemberSubInfoE.Res>(EntUserApiMemberSubInfoE)
object ClientUserApiMemberSubInfoEp:UserApi<EntUserApiMemberSubInfoEp.Req, EntUserApiMemberSubInfoEp.Res>(EntUserApiMemberSubInfoEp)
object ClientUserApiMemberPwE:UserApi<EntUserApiMemberPwE.Req, EntUserApiMemberPwE.Res>(EntUserApiMemberPwE)
object ClientUserApiMemberPwEp:UserApi<EntUserApiMemberPwEp.Req, EntUserApiMemberPwEp.Res>(EntUserApiMemberPwEp)
object ClientUserApiMemberSub:UserApi<EntUserApiMemberSub.Req, EntUserApiMemberSub.Res>(EntUserApiMemberSub)
object ClientUserApiMemberSubW:UserApi<EntUserApiMemberSubW.Req, EntUserApiMemberSubW.Res>(EntUserApiMemberSubW)
object ClientUserApiMemberSubWp:UserApi<EntUserApiMemberSubWp.Req, EntUserApiMemberSubWp.Res>(EntUserApiMemberSubWp)
object ClientUserApiMemberSubE:UserApi<EntUserApiMemberSubE.Req, EntUserApiMemberSubE.Res>(EntUserApiMemberSubE)
object ClientUserApiMemberSubEp:UserApi<EntUserApiMemberSubEp.Req, EntUserApiMemberSubEp.Res>(EntUserApiMemberSubEp)

object ClientUserApiShipmentW:UserApi<EntUserApiShipmentW.Req, EntUserApiShipmentW.Res>(EntUserApiShipmentW)
object ClientUserApiShipmentWp:UserApi<EntUserApiShipmentWp.Req, EntUserApiShipmentWp.Res>(EntUserApiShipmentWp)
object ClientUserApiShipmentOngoing:UserApi<EntUserApiShipmentOngoing.Req, EntUserApiShipmentOngoing.Res>(EntUserApiShipmentOngoing)
object ClientUserApiShipmentDeliveredWp:UserApi<EntUserApiShipmentDeliveredWp.Req, EntUserApiShipmentDeliveredWp.Res>(EntUserApiShipmentDeliveredWp)
object ClientUserApiShipmentV:UserApi<EntUserApiShipmentV.Req, EntUserApiShipmentV.Res>(EntUserApiShipmentV)
object ClientUserApiShipmentVSensor:UserApi<EntUserApiShipmentVSensor.Req, EntUserApiShipmentVSensor.Res>(EntUserApiShipmentVSensor)
object ClientUserApiShipmentE:UserApi<EntUserApiShipmentE.Req, EntUserApiShipmentE.Res>(EntUserApiShipmentE)
object ClientUserApiShipmentEp:UserApi<EntUserApiShipmentEp.Req, EntUserApiShipmentEp.Res>(EntUserApiShipmentEp)
object ClientUserApiShipmentDp:UserApi<EntUserApiShipmentDp.Req, EntUserApiShipmentDp.Res>(EntUserApiShipmentDp)
object ClientUserApiShipmentDelivered:UserApi<EntUserApiShipmentDelivered.Req, EntUserApiShipmentDelivered.Res>(EntUserApiShipmentDelivered)

object ClientUserApiMySensors:UserApi<EntUserApiMySensors.Req, EntUserApiMySensors.Res>(EntUserApiMySensors)
object ClientUserApiMySensorsAllData:UserApi<EntUserApiMySensorsAllData.Req, EntUserApiMySensorsAllData.Res>(EntUserApiMySensorsAllData)
object ClientUserApiMySensorsAllDataV:UserApi<EntUserApiMySensorsAllDataV.Req, EntUserApiMySensorsAllDataV.Res>(EntUserApiMySensorsAllDataV)
object ClientUserApiMySensorsAllDataPdf:UserApi<EntUserApiMySensorsAllDataPdf.Req, EntUserApiMySensorsAllDataPdf.Res>(EntUserApiMySensorsAllDataPdf)
object ClientUserApiMySensorsAllDataExcel:UserApi<EntUserApiMySensorsAllDataExcel.Req, EntUserApiMySensorsAllDataExcel.Res>(EntUserApiMySensorsAllDataExcel)
object ClientUserApiRentalW:UserApi<EntUserApiRentalW.Req, EntUserApiRentalW.Res>(EntUserApiRentalW)
object ClientUserApiRentalWp:UserApi<EntUserApiRentalWp.Req, EntUserApiRentalWp.Res>(EntUserApiRentalWp)
object ClientUserApiRental:UserApi<EntUserApiRental.Req, EntUserApiRental.Res>(EntUserApiRental)
object ClientUserApiRentalV:UserApi<EntUserApiRentalV.Req, EntUserApiRentalV.Res>(EntUserApiRentalV)


object ClientUserApiAddress:UserApi<EntUserApiAddress.Req, EntUserApiAddress.Res>(EntUserApiAddress)
object ClientUserApiAddressWp:UserApi<EntUserApiAddressWp.Req, EntUserApiAddressWp.Res>(EntUserApiAddressWp)
object ClientUserApiAddressEp:UserApi<EntUserApiAddressEp.Req, EntUserApiAddressEp.Res>(EntUserApiAddressEp)
object ClientUserApiAddressDp:UserApi<EntUserApiAddressDp.Req, EntUserApiAddressDp.Res>(EntUserApiAddressDp)


package senscloud.common.entity.api.user.common

import ein2b.core.entity.eEntity
import ein2b.core.entity.eEntityUnion
import ein2b.core.entity.field.enum.enum
import ein2b.core.net.eApi
import ein2b.core.net.eApiResult
import senscloud.common.enm.EnumApiAccessAllow
import senscloud.common.enm.EnumApiErrorAction
import senscloud.common.enm.EnumApiErrorMethod

// URL 시작부분
internal const val USER_API_PATH = "/user/api"
internal const val USER_API_ADDRESS_PATH = "$USER_API_PATH/address"
internal const val USER_API_MEMBER_PATH = "$USER_API_PATH/member"
internal const val USER_API_SHIPMENT_PATH = "$USER_API_PATH/shipment"
internal const val USER_API_SENSOR_PATH = "$USER_API_PATH/sensor"
internal const val USER_API_RENTAL_PATH = "$USER_API_PATH/rental"

// 공통 엔티티
sealed class EntUserApiCommonMember: eEntity(){
    companion object:eEntityUnion<EntUserApiCommonMember>(EntUserApiCommonMember::Login, EntUserApiCommonMember::Logout)
    class Login: EntUserApiCommonMember(){
        class SensorCat:eEntity(){
            var sensorCatRowid:String by string(::sensorCatRowid)
            var title:String by string(::title)
        }
        var username:String by string(::username) //userId
        var masterUsername:String by string(::masterUsername) //masterUserId
        var fullName:String by string(::fullName)
        var isMaster: Boolean by bool(::isMaster)
        var phone:String by string(::phone)
        var companyName:String by string(::companyName)
        var profileUrl:String by string(::profileUrl)
        var sensorCatList: MutableList<SensorCat> by entityList(::sensorCatList, ::SensorCat)
        var onGoingCount: Long by long(::onGoingCount)
    }
    class Logout: EntUserApiCommonMember(){
        var none:String by string(::none){
            default("", false)
        }
    }
}

abstract class EntUserApiResponse<DATA>: eEntity(true){
    companion object{
        val NO_ERROR = Error()
        val LOGOUT = EntUserApiCommonMember.Logout()
    }
    class Error(message:String = "", method: EnumApiErrorMethod =EnumApiErrorMethod.ALERT, action: EnumApiErrorAction =EnumApiErrorAction.NONE): eEntity(true){
        var message:String by string(::message){default(message, false)}
        var method: EnumApiErrorMethod by enum(::method){default(method, false)}
        var action: EnumApiErrorAction by enum(::action){default(action, false)}
    }
    var ver:String by string(::ver){default("1", false)}
    var member: EntUserApiCommonMember by entity(::member, EntUserApiCommonMember){
        default(LOGOUT, false)
    }
    var error: Error by entity(::error, ::Error){
        default(NO_ERROR)
    }
    abstract var data:DATA
    val isError get() = error !== NO_ERROR
    val isLogin get() = member is EntUserApiCommonMember.Login
}
class UserResponse<DATA: eEntity>(val factory:()->DATA): EntUserApiResponse<DATA>(){
    override var data: DATA by entity(::data, factory){
        default(factory(), false)
        include { !isError }
    }
}

abstract class EntUserApi <REQ:eEntity, RES:eEntity>(private val req:()->REQ, private val res:()->RES) {
    protected var isJSON = false
    protected abstract val path:String
    protected abstract val jsonPath:String
    fun url() = urlDeco(if(isJSON) jsonPath else path)
    protected open fun urlDeco(path:String) = path
    open val accessAllow get() = EnumApiAccessAllow.MEMBER
    open val midIgnore get() = false //mid 무시여부
    fun request():REQ = req()
    fun response():RES = res()
    open val rsckeys = ""
}

abstract class EntClientApi<REQ:eEntity, RES:eEntity>(val apiObject: EntUserApi<REQ, RES>){
    protected open lateinit var api:eApi
    protected open suspend fun responseProcess(result:eApiResult):RES?{return null}
}
open class EntUserApiPageMeta:eEntity(true){
    var page:String by string(::page){ default("1", false)}
    var totalRows:String by string(::totalRows){ default("1", false)}
    var pagePerGroup:String by string(::pagePerGroup){ default("10", false)}
    var rowPerPage:String by string(::rowPerPage){ default("10", false) }
}

open class EntUserApiSelectItem:eEntity(true) {
    var title:String by string(::title)
    var value:String by string(::value)
    var selected: Boolean by bool(::selected){default(false)}
    var disabled: Boolean by bool(::disabled){default(false)}
}

class EntMainSelectBox: eEntity(true){
    var value:String by string(::value)
    var title:String by string(::title)
    var selected: Boolean by bool(::selected)
    var disabled: Boolean by bool(::disabled)
    var isHided: Boolean by bool(::isHided)
}

class EntApiRowid:eEntity(){
    var r:String by string(::r)
}

//RSA 발급
object EntUserApiRsa: EntUserApi<EntUserApiRsa.Req, EntUserApiRsa.Res>(::Req, ::Res){
    const val PATH = "$USER_API_PATH/rsa"
    const val PATH_JSON = "$PATH/json"

    class Req: eEntity()
    class Res: eEntity(true){
        class RSA: eEntity(true){
            var id:String by string(::id){default("")}
            var key:String by string(::key){default("")}
        }
        var rsa: RSA by entity(::rsa, ::RSA){default(RSA(), false)}
    }
    override val accessAllow get() = EnumApiAccessAllow.LOGOUT
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val rsckeys = "myinfo,login"

}

//이미지 등록
object EntUserApiMemberImageWp: EntUserApi<EntUserApiMemberImageWp.Req, EntUserApiMemberImageWp.Res>(::Req, ::Res){
    const val PATH = "$USER_API_PATH/image/wp"
    const val PATH_JSON = "$PATH/json"
    override val rsckeys = "common"

    class Req: eEntity()
    class Res: eEntity(true){
        var imageUrl:String by string(::imageUrl)
    }
    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
}

open class Address:eEntity(true) {
    var rowid:String by string(::rowid)
    var name:String by string(::name)
    var address:String by string(::address)
}

//open class EntUserApiMemberImageWp2: EntUserApi2<EntUserApiMemberImageWp2.Req>(::Req){
//    companion object{
//        val REF = EntUserApiMemberImageWp2()
//        const val PATH = "$USER_API_PATH/image/wp"
//        const val PATH_JSON = "$PATH/json"
//    }
//    override val path = PATH
//    override val jsonPath = PATH_JSON
//    override val accessAllow get() = EnumApiAccessAllow.MEMBER
//    override val rsckeys = "myinfo"
//
//    var imageUrl:String by string(::imageUrl)
//
//    class Req: eEntity()
//}
//홈
object EntUserApiHome: EntUserApi<EntUserApiHome.Req, EntUserApiHome.Res>(::Req, ::Res){
    const val PATH = "$USER_API_PATH/home"
    const val PATH_JSON = "$PATH/json"
    override val rsckeys = "myinfo"

    class Req: eEntity()
    class Res: eEntity()
    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
}
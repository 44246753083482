package ein2b.core.entity.field.value

import ein2b.core.entity.Report

class FloatValue:ValueField<Float>(){
    companion object{
        fun s2v(str: String, report: Report): Float? {
            val r = str.toFloatOrNull()
            if(r == null) report.add("invalid Float", "field" to this, "json" to str)
            return r
        }
    }
    override fun value(vStr:String, str: String, report: Report): Float? = s2v(vStr, report)
}
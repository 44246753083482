package senscloud.common.entity.api.user.address

import ein2b.core.entity.eEntity
import ein2b.core.entity.field.enum.enum
import senscloud.common.enm.EnumApiAccessAllow
import senscloud.common.enm.EnumApiAddressSort
import senscloud.common.entity.api.user.common.Address
import senscloud.common.entity.api.user.common.EntUserApi
import senscloud.common.entity.api.user.common.EntUserApiPageMeta
import senscloud.common.entity.api.user.common.USER_API_ADDRESS_PATH
import senscloud.common.vali.*

//Address book - address list
object EntUserApiAddress: EntUserApi<EntUserApiAddress.Req, EntUserApiAddress.Res>(::Req, ::Res){
    const val PATH = USER_API_ADDRESS_PATH
    const val PATH_JSON = "$PATH/json"

    class Req: eEntity() {
        var sort: EnumApiAddressSort by enum(::sort) {
            default(EnumApiAddressSort.LAST_CREATED, false)
            validator(ValiAddressSort)
        }
        var page:String by string(::page){
            default("1", false)
            validator(ValiPage)
        }
    }
    class Res: eEntity() {
        class Meta:EntUserApiPageMeta() {
            var sort: EnumApiAddressSort by enum(::sort) {
                default(EnumApiAddressSort.LAST_CREATED, false)
            }
        }
        class Address:eEntity(true) {
            var rowid:String by string(::rowid)
            var name:String by string(::name)
            var address:String by string(::address)
            var lat:String by string(::lat)
            var lng:String by string(::lng)
        }
        var meta: Meta by entity(::meta, ::Meta){ default(Meta(), false) }
        var addressList: MutableList<Address> by entityList(::addressList, ::Address)
    }
    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val rsckeys = "address_book"
}

//Address book - address delete
object EntUserApiAddressDp: EntUserApi<EntUserApiAddressDp.Req, EntUserApiAddressDp.Res>(::Req, ::Res){
    const val PATH = "$USER_API_ADDRESS_PATH/dp"
    const val PATH_JSON = "$PATH/json"

    class Req: eEntity() {
        var rowid:String by string(::rowid) { validator(ValiRowId) }
    }
    class Res: eEntity()
    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
}

//Address book - address edit
object EntUserApiAddressEp: EntUserApi<EntUserApiAddressEp.Req, EntUserApiAddressEp.Res>(::Req, ::Res){
    const val PATH = "$USER_API_ADDRESS_PATH/ep"
    const val PATH_JSON = "$PATH/json"

    class Req: eEntity() {
        var rowid:String by string(::rowid) { validator(ValiRowId) }
        var name:String by string(::name) { validator(ValiAddressName) }
        var address:String by string(::address) { validator(ValiAddressAddress) }
        var lat:String by string(::lat) { validator(ValiLatitude) }
        var lng:String by string(::lng) { validator(ValiLongitude) }
    }
    class Res: eEntity(){
        var isExist: Boolean by bool(::isExist){ default(false) }
    }
    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
}

//Address book - address add
object EntUserApiAddressWp: EntUserApi<EntUserApiAddressWp.Req, EntUserApiAddressWp.Res>(::Req, ::Res){
    const val PATH = "$USER_API_ADDRESS_PATH/wp"
    const val PATH_JSON = "$PATH/json"

    class Req: eEntity() {
        var name:String by string(::name) { validator(ValiAddressName) }
        var address:String by string(::address) { validator(ValiAddressAddress) }
        var lat:String by string(::lat) { validator(ValiLatitude) }
        var lng:String by string(::lng) { validator(ValiLongitude) }
    }
    class Res: eEntity() {
        var addressList: MutableList<Address> by entityList(::addressList, ::Address)
        var addedAddressRowid:String by string(::addedAddressRowid)
        var isExist: Boolean by bool(::isExist){ default(false) }
    }
    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
}
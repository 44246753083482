import comp.*
import comp.input.*
import ein2b.core.validation.eVali
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

suspend fun eView<HTMLElement>.compLabelInputSectionTextSet(
    subKey:Any, label:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100, sectionClass: String = "input-section",
    block:((CompInputText)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompInputText{
       it.vali = vali
        block?.invoke(it)
    }, isInline, width, sectionClass)

suspend fun eView<HTMLElement>.compLabelInputSectionRegexSet(
    subKey:Any, label:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100, sectionClass: String = "input-section", regex: Regex = "".toRegex(),
    block:((CompInputRegex)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompInputRegex{
    it.vali = vali
    it.regex = regex
    block?.invoke(it)
}, isInline, width, sectionClass)

suspend fun eView<HTMLElement>.compLabelInputSectionTextAreaSet(
    subKey:Any, label:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100, sectionClass: String = "input-section",
    block:((CompTextarea)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompTextarea{
    it.vali = vali
    block?.invoke(it)
}, isInline, width, sectionClass)
suspend fun eView<HTMLElement>.compLabelInputSectionDateSet(
    subKey:Any, label:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100, sectionClass: String = "input-section", ymdPattern:String = "Y/m/d(w)", wrapperClass: String = "input-date",
    block:((CompInputDate)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompInputDate{
    it.vali = vali
    block?.invoke(it)
    it.ymdPattern = ymdPattern
    it.wrapperClass = wrapperClass
}, isInline, width, sectionClass)

suspend fun eView<HTMLElement>.compLabelInputSectionPasswordSet(
    subKey:Any, label:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100, sectionClass: String = "input-section",
    block:((CompInputPassword)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompInputPassword{
    it.vali = vali
    block?.invoke(it)
}, isInline, width, sectionClass)

suspend fun <V> eView<HTMLElement>.compLabelInputSectionSelectSet(
    subKey:Any, label:String,
    valiMsg:String = "", placeholder:String = "선택해 주세요", wrapperClass:String = "width6-1",
    isInline:Boolean = false, width:Int = 100,
    block:((CompSelect<V>)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompSelect<V>{
        it.wrapperClass = "$wrapperClass selectbox-border"
        it.placeholder = placeholder
        it.vali = it.singleRule(valiMsg.ifBlank{ "선택해 주세요" })
        block?.invoke(it)
    }, isInline, width)

suspend fun <V> eView<HTMLElement>.compLabelInputSectionGroupSelectSet(
    subKey:Any, label:String,
    valiMsg:String = "", placeholder:String = "선택해 주세요", wrapperClass:String = "width2-1",
    isInline:Boolean = false, width:Int = 100, isReverse:Boolean = false,
    block:((CompGroupSelect<V>)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompGroupSelect<V>{
        it.wrapperClass = "$wrapperClass selectbox-border"
        it.placeholder = placeholder
        it.isReverse = isReverse
        it.vali = it.singleRule(valiMsg.ifBlank{ "선택해 주세요" })
        block?.invoke(it)
    }, isInline, width)

suspend fun <V> eView<HTMLElement>.compLabelInputSectionSelectAddOnSet(
    subKey:Any, label:String,
    valiMsg:String = "", placeholder:String = "선택해 주세요", wrapperClass:String = "width6-1",
    isInline:Boolean = false, width:Int = 100,
    block:((CompSelectAddOn<V>)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompSelectAddOn<V>{
        it.wrapperClass = "$wrapperClass selectbox-border"
        it.placeholder = placeholder
        it.vali = it.singleRule(valiMsg.ifBlank{ "선택해 주세요" })
        block?.invoke(it)
    }, isInline, width)

class CompLabelInputSection<IN:Any,V, OUT,INPUT:CompInput<IN, V, OUT>>(val input:INPUT):Comp, CompValueOut<OUT> {
    companion object{
        //language=html
        private val factory = Factory.html("""
<div class="margin-top20">
    <label data-view="label" class="input-block-label"></label>
    <b data-view="inputSection"></b>
</div>""")
        //language=html
        private val inLineFactory = Factory.html("""
<div class="flex margin-top20 vertical-top">
    <label data-view="label" class="input-inline-label flex-shrink-0" style="margin-top:9px"></label>
    <b data-view="inputSection"></b>
</div>""")
        suspend operator fun <IN:Any,V,OUT,INPUT:CompInput<IN, V, OUT>> invoke(root:eView<HTMLElement>, subKey:Any, label:String, input:INPUT, isInline:Boolean = false, width:Int = 100, sectionClass:String = "input-section"):CompLabelInputSection<IN, V, OUT, INPUT>{
            val comp = CompLabelInputSection(input)
            comp.target = root.sub(subKey, if(isInline) inLineFactory else factory){ subView->
                subView.sub("label"){
                    it.html = label
                    it.className = if(isInline) "input-inline-label flex-shrink-0" else "input-block-label"
                    if(isInline) it.width = "${width}px"
                }
                comp.inputSection = subView.compInputSectionOneSet("inputSection", input, "flex-grow-1", "form-error", sectionClass)
            }
            comp.baseProp(root.sub(subKey), true)
            return comp
        }
    }
    lateinit var target:eView<HTMLElement>
    lateinit var inputSection: CompInputSection<V>
    suspend fun setLabel(label:String){ target.sub("label").html = label }
    override suspend fun clear() = inputSection.clear()
    override fun check() = inputSection.check()
    suspend fun changeError(msg:String, ok:Boolean = false) = inputSection.changeError(ok, msg)
    fun inputValue(v:IN) = input.value.inputValue(v)
    fun displayBlock() { target.displayBlock() }
    fun displayNone() { target.displayNone() }
    override val outs: HashMap<OutType, suspend () -> OUT> = hashMapOf(OutType.DEFAULT to { input.out() })
}
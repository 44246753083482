package member.changePw

import Factory
import senscloud.common.app.App
import senscloud.common.entity.api.user.member.EntUserApiMemberPwE
import senscloud.common.vali.*
import senscloud.user.app.ClientUserApiMemberPwEp
import senscloud.user.app.ClientUserApiRsa
import comp.CompPageTitle
import compLabelInputSectionPasswordSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.click
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import ein2b.core.view.html
import ein2b.js.crypto.eCrypto
import org.w3c.dom.HTMLElement
import prop.clearCompValue
import prop.compLabelInputSectionPassword
import view.CompViewToast

private val factory = Factory.htmlUrl("member/changePwT")

private enum class K{
    pw, newPw, editBtn, CHANGEPW;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

suspend fun ChangePwView() = eView(factory){ rootView->
    App.subHtmlFromLabel(rootView, K.CHANGEPW to "c@Change password@pwchange/title/pwchange")
    CompPageTitle(rootView){ it.title = "c@Change password@pwchange/title2/pwchange" }
    rootView.compLabelInputSectionPasswordSet(K.pw, "c@Current password@pwchange/label/currentpw", ValiMemberCurrPw)
    rootView.compLabelInputSectionPasswordSet(K.newPw, "c@New password@pwchange/label/newpw", ValiMemberNewPw)

    rootView.sub(K.editBtn) {
        it.html = "c@Save@pwchange/button/save"
        it.click = {_,_ ->
            eLaunch {
                val pw = rootView.sub(K.pw).compLabelInputSectionPassword()
                val newPw = rootView.sub(K.newPw).compLabelInputSectionPassword()
                if(App.checkAll(pw, newPw)) {
                    ClientUserApiRsa.net()?.also { rsa ->
                        ClientUserApiMemberPwEp.net { req ->
                            req.oldPw = eCrypto.rsaEncrypt(pw.out(), rsa.rsa.key)
                            req.newPw = eCrypto.rsaEncrypt(newPw.out(), rsa.rsa.key)
                            req.rsaId = rsa.rsa.id
                        }?.also { res ->
                            if(res.isError) {
                                pw.changeError(res.error.oldPw, res.error.oldPw.isBlank())
                            } else{
                                CompViewToast.open("c@Password was changed.@pwchange/toast/01", "success-badge", CompViewToast.TOAST_TYPE.SUCCESS)
                                rootView.entity(EntUserApiMemberPwE.Res())
                            }
                        }
                    }
                }
            }
        }
    }
    rootView.addEntityHook(EntUserApiMemberPwE.Res::class, object: eEntityHook<HTMLElement, EntUserApiMemberPwE.Res> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntUserApiMemberPwE.Res){
            view.clearCompValue(K.pw, K.newPw)
        }
    })
}
package address

import Factory
import GoogleMap
import comp.*
import comp.input.CompSelect
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
import prop.compCustConfirm
import prop.compPagination
import prop.compSelect
import prop.compViewList
import senscloud.common.app.App
import senscloud.common.enm.EnumApiAddressSort
import senscloud.common.entity.api.user.address.EntUserApiAddress
import senscloud.user.app.ClientUserApiAddress
import senscloud.user.app.ClientUserApiAddressDp
import senscloud.user.app.ClientUserApiAddressEp
import senscloud.user.app.ClientUserApiAddressWp
import view.CompViewConfirm

private val factory = Factory.htmlUrl("address/addressBook")
private val addrFactory = Factory.htmlUrl("address/addressT")
private val confirmFactory = Factory.htmlUrl("address/confirmT")
private enum class K{
    select, page, confirm,
    createBtn, cnt, empty, list, listArea,
    addr_name, addr_address, addr_deleteBtn, addr_editBtn,
    confirm_title, confirm_name, confirm_msg;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

suspend fun AddressBookView() = eView(factory){ rootView->

    CompPageTitle(rootView){
        it.title = "c@Address book@address_book/title/01"
    }

    rootView.sub(K.createBtn) {
        it.html = "c@Create new@address_book/button/create"
        it.click = {_, _ ->
            eLaunch {
                GoogleMapModal.open { md ->
                    ClientUserApiAddressWp.net{ req ->
                        req.name = md.name
                        req.lat = "${md.lat}"
                        req.lng = "${md.lng}"
                        req.address = md.addr
                    }?.also { res ->
                        if(!res.isExist) App.reload()
                        return@open !res.isExist
                    }
                    return@open true
                }
            }
        }
    }
    CompSelect<EnumApiAddressSort> {
        it.wrapperClass = "selectbox-flat"
        it.checkBlock = { idx ->
            eLaunch {
                ClientUserApiAddress.net { req ->
                    req.sort = it.itemList[idx].value
                }?.also { res ->
                    rootView.entity(res)
                }
            }
        }
    }.also {
        it.comp(rootView, K.select)
    }
    rootView.sub(K.cnt)
    rootView.sub(K.listArea)
    CompViewList(rootView, K.list, CompViewList.Companion.EnumCompViewListType.LIST_BORDER)
    rootView.sub(K.list)
    rootView.sub(K.empty).html = "c@Couldn't find any address.@address_book/empty/msg"
    rootView.compPaginationSet(K.page, "page"){}
    rootView.compCustConfirmSet(K.confirm) {
        it.wrapperClass = "popup-wrapper"
        it.cancelBtnLabel = "c@Close@common/label/close"
        it.submitBtnLabel = "c@Delete an address@address_book/confirm/delete/title"
    }

    rootView.addEntityHook(EntUserApiAddress.Res::class, object: eEntityHook<HTMLElement, EntUserApiAddress.Res>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntUserApiAddress.Res) {
            GoogleMap.create("mapArea", GoogleMap.MapCreationOption(37.566869252467356, 126.9786522459667, 2), entity.addressList.isNotEmpty(), false)
            if(entity.addressList.isNotEmpty()) {
                GoogleMap.renderMarkList(entity.addressList.map{ GoogleMap.MarkWithId(it.lat.toDouble(), it.lng.toDouble(), it.rowid, it.name) }){}
                view.sub(K.empty).displayNone()
                view.sub(K.listArea).displayBlock()
                view.sub(K.list).compViewList().setList(entity.addressList, addrFactory){ d, v ->
                    App.subHtmlFromEntity(v, d, K.addr_name, K.addr_address)
                    v.sub(K.addr_deleteBtn) {
                        it.html = "c@Delete@address_book/button/delete"
                        it.click = {_, _ ->
                            eLaunch {
                                view.sub(K.confirm).compCustConfirm { cf ->
                                    cf.setContents(confirmFactory) { cv ->
                                        cv.sub(K.confirm_title).html = "c@Delete an address@address_book/confirm/delete/title"
                                        cv.sub(K.confirm_name).html = d.name
                                        cv.sub(K.confirm_msg).html = "c@will be deleted immediately, and you cannot undo this action.@address_book/confirm/delete/msg"
                                    }
                                    cf.setSubmit {
                                        eLaunch {
                                            ClientUserApiAddressDp.net { req ->
                                                req.rowid = d.rowid
                                            }?.also {
                                                App.reload()
                                            }
                                        }
                                    }
                                    cf.open()
                                }
                            }
                        }
                    }
                    v.sub(K.addr_editBtn) {
                        it.html = "c@Edit@address_book/button/edit"
                        it.click = {_, _ ->
                            eLaunch {
                                GoogleMapModal.open(GoogleMap.MapCreationOption(d.lat.toDouble(), d.lng.toDouble(), 20), d.name) { md ->
                                    ClientUserApiAddressEp.net{ req ->
                                        req.rowid = d.rowid
                                        req.name = md.name
                                        req.lat = "${md.lat}"
                                        req.lng = "${md.lng}"
                                        req.address = md.addr
                                    }?.also { res ->
                                        if(!res.isExist) App.reload()
                                        return@open !res.isExist
                                    }
                                    return@open true
                                }
                            }
                        }
                    }
                }
            } else {
                view.sub(K.empty).displayBlock()
                view.sub(K.listArea).displayNone()
            }
            view.sub(K.select).compSelect<EnumApiAddressSort>().setList { select ->
                EnumApiAddressSort.values().map {
                    select.item(it.label, it, it == entity.meta.sort, false)
                }
            }
            view.sub(K.cnt){
                it.attr("v0" to entity.addressList.size)
                it.html = "c@address@address_book/label/cnt/${if(entity.addressList.size > 1) "02" else "01"}"
            }
            view.sub(K.page).compPagination {
                it.setPage(entity.meta.totalRows.toLong(), entity.meta.page.toLong(), entity.meta.rowPerPage.toLong(), entity.meta.pagePerGroup.toLong())
                it.clickEvent = { p ->
                    ClientUserApiAddress.net { req ->
                        req.sort = entity.meta.sort
                        req.page = "$p"
                    }?.also { res ->
                        rootView.entity(res)
                    }
                }
            }
        }
    })
}
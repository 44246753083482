package member.login

import app.hashManager
import senscloud.common.domPage.PageBase
import senscloud.user.app.ApiUser
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement
import senscloud.user.app.ClientUserApiRsa
import senscloud.user.app.RouterKey

class LoginP(router:eRouter<HTMLElement>): PageBase(::LoginView, router){
    override suspend fun net(data:eRouterData){
        ClientUserApiRsa.net()?.also {
            if(ApiUser.isLogin) hashManager.goUrl(RouterKey.SHIPMENT_ONGOING)
            else view?.entity(it)
        }
    }
}
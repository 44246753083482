package member.join

import app.hashManager
import senscloud.common.domPage.PageBase
import senscloud.user.app.ApiUser
import senscloud.user.app.ClientUserApiMemberLoginCheck
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement
import senscloud.user.app.RouterKey

class JoinP(router:eRouter<HTMLElement>): PageBase(::JoinView, router){
    override suspend fun net(data:eRouterData){
        ClientUserApiMemberLoginCheck.net()?.also {
            if(ApiUser.isLogin) hashManager.goUrl(RouterKey.SHIPMENT_ONGOING)
            else view?.entity(it)
        }
    }
}
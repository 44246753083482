package senscloud.user.entity.shipping

import CompLabelInputSection
import comp.input.CompInputData
import ein2b.core.entity.eEntity
import ein2b.core.entity.field.enum.enum
import ein2b.core.entity.field.enum.enumStringMap
import senscloud.util.Chart
import senscloud.common.enm.EnumApiShipmentSearchType
import senscloud.common.entity.api.user.common.EntTempInfo
import senscloud.common.entity.api.user.mysensors.EntUserApiMySensorsAllDataV
import senscloud.common.entity.api.user.shipment.*

class EntClientShipmentWSensorList:eEntity() {
    var sensorDataList: MutableList<Sensor> by entityList(::sensorDataList, ::Sensor){ default(mutableListOf(), false) }
    var sensorList: MutableList<String> by stringList(::sensorList) { default(mutableListOf(""), false) }
    var sensorCompList:MutableList<CompLabelInputSection<String, String, String, CompInputData<String>>> = mutableListOf()
}

class EntClientOngoingShipping(target: EntUserApiShipmentOngoing.Res): eEntity() {
    var searchType: EnumApiShipmentSearchType by enum(::searchType){ default(target.meta.searchType, false) }
    var searchText:String by string(::searchText){ default(target.meta.searchText, false) }
    var filterList: MutableMap<EnumApiShipmentSearchType, String> by enumStringMap(::filterList){ default(target.meta.filterList, false) }
    var cnt:String by string(::cnt){ default("${target.ongoingList.size}", false)}
    var ongoingList: MutableList<EntUserApiShipmentOngoing.Res.Ongoing> by entityList(::ongoingList, EntUserApiShipmentOngoing.Res::Ongoing){ default(target.ongoingList, false) }
}

class EntClientMarkDelivered(shipmentRowid:String, departureDate:String):eEntity(){
    var shipmentRowid:String by string(::shipmentRowid){default(shipmentRowid, false)}
    var departureDate:String by string(::departureDate){default(departureDate, false)}

    fun dateFormatting(dapartureDate:String):String{
        val date = dapartureDate.substring(0, 10)
        val day = dapartureDate.substring(10,15)
        val time = dapartureDate.substring(16,21)
        return "$date $time"
    }
}

class Sensor: eEntity(true){
    var code:String by string(::code)
    var tempRange:String by string(::tempRange)
}

class Shipment: eEntity(true){
    var shipmentRowid:String by string(::shipmentRowid)
    var name:String by string(::name)
    var num:String by string(::num) //reference num
    var description:String by string(::description)
}
class Route: eEntity(true){
    var shipmentRouteRowid:String by string(::shipmentRouteRowid)
    var startName:String by string(::startName){default("")}
    var startAddr:String by string(::startAddr)
    var startDate:String by string(::startDate)
    var startTime:String by string(::startTime)
    var endName:String by string(::endName)
    var endAddr:String by string(::endAddr)
    var endDate:String by string(::endDate)
    var endTime:String by string(::endTime)
}
class EntClientShipmentDetail(target: EntUserApiShipmentV.Res):eEntity() {
    var shipment: Shipment by entity(::shipment, ::Shipment){default(
        Shipment().also {
            it.shipmentRowid = target.shipment.shipmentRowid
            it.name = target.shipment.name
            it.num = target.shipment.num.ifBlank { "-" }
            it.description = target.shipment.description.ifBlank { "-" }
        }
    )}
    var sensorList: MutableList<Sensor> by entityList(::sensorList, ::Sensor){default(
        target.sensorList.map { s ->
            Sensor().also {
                it.code = s.code
                it.tempRange = "${s.minTemp} ~ ${s.maxTemp}"
            }
        }.toMutableList()
    )}
    var route: Route by entity(::route, ::Route){default(Route().also {
        it.shipmentRouteRowid = target.route.shipmentRouteRowid
        it.startAddr = "${target.route.startName} (${target.route.startAddr})"
        it.startDate = target.route.startDate
        it.startTime = target.route.startDate
        it.endAddr = "${target.route.endName} (${target.route.endAddr})"
        it.endDate = target.route.endDate.ifBlank { "-" }
        it.endTime = target.route.endDate
    })}

}

class EntClientShipmentVSensor(target:EntUserApiShipmentVSensor.Res):eEntity(){
    class Sensor:eEntity(true){
        var code:String by string(::code)
        var minTemp:String by string(::minTemp)
        var maxTemp:String by string(::maxTemp)
        var tempRange:String by string(::tempRange)
        var tempInfoList:MutableList<EntTempInfo> by entityList(::tempInfoList, ::EntTempInfo){ default(mutableListOf(), false) }
        var isSmall = true
    }
    var shipmentRowid:String by string(::shipmentRowid){ default(target.shipmentRowid, false) }
    var route:EntUserApiShipmentVSensor.Res.Route by entity(::route, EntUserApiShipmentVSensor.Res::Route){ default(target.route, false) }
    var sensorList:MutableList<Sensor> by entityList(::sensorList, ::Sensor){ default(
        target.sensorList.map{ sensor ->
            Sensor().also { s ->
                s.code = sensor.code
                s.minTemp = sensor.minTemp
                s.maxTemp = sensor.maxTemp
                s.tempRange = "${sensor.minTemp} ~ ${sensor.maxTemp}"
                s.tempInfoList = sensor.tempInfoList
            }
        }.toMutableList(), false)
    }
}

class Address:eEntity(true) {
    var rowid:String by string(::rowid)
    var name:String by string(::name)
    var address:String by string(::address)
}

class EntClientShipmentE(target: EntUserApiShipmentE.Res): eEntity(){
    var addressList: MutableList<Address> by entityList(::addressList, ::Address){ default(
        target.addressList.map { addr ->
            Address().also { a ->
                a.rowid = addr.rowid
                a.name = addr.name
                a.address = addr.address
            }
        }.toMutableList()
    , false)}
    var registeredSensorList: MutableList<Sensor> by entityList(::registeredSensorList, ::Sensor){ default(
        target.registeredSensorList.map { rs ->
            Sensor().also { s ->
                s.tempRange = "${rs.minTemp} ~ ${rs.maxTemp}"
                s.code = rs.code
            }
        }.toMutableList()
        , false)}
    var sensorList: MutableList<Sensor> by entityList(::sensorList, ::Sensor){ default(
        target.sensorList.map { ss ->
            Sensor().also { s ->
                s.code = ss.code
                s.tempRange = "${ss.minTemp} ~ ${ss.maxTemp}"
            }
        }.toMutableList()
        , false)}
    var shipment: Shipment by entity(::shipment, ::Shipment){default(
        Shipment().also {
            it.shipmentRowid = target.shipment.shipmentRowid
            it.name = target.shipment.name
            it.num = target.shipment.num
            it.description = target.shipment.description
        }, false)
    }
    var route: Route by entity(::route, ::Route){default(Route().also {
        it.shipmentRouteRowid = target.route.shipmentRouteRowid
        it.startName = target.route.startName
        it.startAddr = target.route.startAddr
        it.startDate = target.route.startDate.substring(0,10).ifBlank { "" }
        it.startTime = target.route.startDate.substring(16,22).ifBlank { "" }
        it.endName = target.route.endName
        it.endAddr = target.route.endAddr
        it.endDate = target.route.endDate.substring(0,10).ifBlank { "" }
        it.endTime = target.route.endDate.substring(16,22).ifBlank { "" }
    }, false)}
}

class EntClientShipmentVSenSorPdf(target: EntUserApiMySensorsAllDataV.Res): eEntity(){
    class Record : eEntity(true){
        var startDate:String by string(::startDate)
        var endDate:String by string(::endDate)
        var minTemp:String by string(::minTemp)
        var maxTemp:String by string(::maxTemp)
        var lastTemp:String by string(::lastTemp)
        var sensorCode:String by string(::sensorCode)
        var tempInfo: EntTempInfo by entity(::tempInfo, ::EntTempInfo) { default(EntTempInfo(), false) }

        //앱웹에서 필요
        var regDate:String by string(::regDate) //2022-05-03(Tue) 10:00
        var duration:String by string(::duration) //2 hours

        //PDF 에서 필요
        var catMinTemp:String by string(::catMinTemp) //2°C
        var catMaxTemp:String by string(::catMaxTemp) //8°C

        var isMinTempOk: Boolean by bool(::isMinTempOk)
        var isMaxTempOk: Boolean by bool(::isMaxTempOk)
        var isLastTempOk: Boolean by bool(::isLastTempOk)


    }
    val record: Record by entity(::record, ::Record){
        default(Record().also { r ->
            r.startDate = target.record.startDate
            r.endDate = target.record.endDate
            r.minTemp = target.record.minTemp
            r.maxTemp = target.record.maxTemp
            r.lastTemp = target.record.lastTemp
            r.tempInfo = target.record.tempInfo
            r.regDate = target.record.regDate
            r.duration = target.record.duration
            r.catMinTemp = target.record.catMinTemp
            r.catMaxTemp = target.record.catMaxTemp
            r.isMinTempOk = (target.record.minTemp >= target.record.catMinTemp) && (target.record.minTemp <= target.record.catMaxTemp)
            r.isMaxTempOk = (target.record.maxTemp >= target.record.catMinTemp) && (target.record.maxTemp <= target.record.catMaxTemp)
            r.isLastTempOk = (target.record.lastTemp >= target.record.catMinTemp) && (target.record.maxTemp <= target.record.catMaxTemp)
        }, false)}
    var title:String by string(::title){default("${target.sensorCode} (${target.record.catMinTemp}~${target.record.catMaxTemp})", false)}
    var sensorSessionRowid:String by string(::sensorSessionRowid){default(target.sensorSessionRowid, false)}
    class TempData: eEntity(true) {
        var date:String by string(::date)
        var time:String by string(::time)
        var temp:String by string(::temp)
    }
    class Page:eEntity(true) {
        var list: MutableList<TempData> by entityList(::list, ::TempData){default(mutableListOf(), false)}
    }
    var pageList: MutableList<Page> by entityList(::pageList, ::Page){
        val rowCnt = (39 * 3) - 1
        val pageCnt = target.record.tempInfo.cnt / rowCnt
        var i = 0
        default((0..pageCnt).map {
            Page().also { p ->
                var oldDate = ""
                p.list.clear()
                (0 .. rowCnt).forEach{
                    if(i < target.record.tempInfo.cnt) p.list += TempData().also { tempData ->
                        val date = EntTempInfo.DateFormat(target.record.tempInfo.date[i])
                        val currDate ="${date.month}.${date.day}"
                        tempData.date = if(oldDate != currDate) {
                            oldDate = currDate
                            currDate
                        } else ""
                        tempData.time = "${date.hour}:${date.min}"
                        tempData.temp = "${Chart.tempFloat(target.record.tempInfo.temp[i])}°C"
                        i++
                    }
                }
            }
        }.toMutableList(), false)
    }

    var logDataList: MutableList<TempData> by entityList(::logDataList, ::TempData){
        default(target.record.tempInfo.date.mapIndexed { idx, l ->
            TempData().also {
                it.date = "$l"
                it.temp = "${target.record.tempInfo.temp[idx]}"
            }
        }.toMutableList(),false)
    }
}
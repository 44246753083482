package rental.rentalDetail

import app.hashManager
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement
import senscloud.common.domPage.PageBase
import senscloud.common.entity.api.user.rental.EntUserApiRentalV
import senscloud.user.app.ClientUserApiRentalV
import senscloud.user.entity.rental.EntClientUserRentalV

class RentalDetailP(router: eRouter<HTMLElement>): PageBase(::RentalDetailView, router){
    override suspend fun net(data:eRouterData){
        data.parse(EntUserApiRentalV.request())?.also { ent ->
            ClientUserApiRentalV.net {
                it.rentalRowid = ent.rentalRowid
            }?.also {view?.entity(EntClientUserRentalV(it))}
        }?: hashManager.back()
    }
}
package senscloud.user

import GoogleMapModal
import senscloud.common.app.App
import senscloud.user.app.ApiUser
import ein2b.core.core.Now
import ein2b.core.core.err
import ein2b.core.core.jsNow
import ein2b.core.coroutine.eScheduler
import ein2b.core.view.eDomBinder
import ein2b.core.view.eView
import kotlinx.browser.document
import org.w3c.dom.HTMLElement
import senscloud.common.app.ApiRsc
import senscloud.common.vali.ValiAddressName
import senscloud.user.app.RouterKey
import senscloud.user.app.RouterKey.ADDRESS_BOOK
import senscloud.user.app.RouterKey.RENTAL_LIST
import senscloud.user.app.RouterKey.RENT_A_SENSOR
import senscloud.user.app.RouterKey.SHIPMENT_ADD
import senscloud.user.app.RouterKey.SHIPMENT_DELIVERED
import senscloud.user.app.RouterKey.SHIPMENT_ONGOING
import senscloud.user.modalView.shipping.markAsDeliveredModalView
import view.*

lateinit var ani:eScheduler
lateinit var markAsDeliveredModalEV:eView<HTMLElement>

suspend fun commonMain(){
    Now.instance = jsNow
    ani = eScheduler()
    ani.start(15)

    eView.binder = eDomBinder().apply{start()}
    ApiUser

    //View
    val viewEL by lazy{ (document.querySelector("#view") as? HTMLElement) ?: err("invalid #view") }
    markAsDeliveredModalEV = markAsDeliveredModalView()

    listOf(
        markAsDeliveredModalEV
    ).forEach {
        it.setTemplate()
        viewEL.appendChild(it.template!!)
    }

    CompViewUpload.init(viewEL)
    CompViewBlocking.init(viewEL)
    CompViewAlert.init(viewEL)
    CompViewConfirm.init(viewEL)
    CompViewToast.init(viewEL)

    val baseEL by lazy{ (document.querySelector("#base") as? HTMLElement) ?: err("invalid #base") }
    CompViewLnb.init(
        baseEL,
        "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_senscloud/2022/05/04/4f9d079829933412795dd8c68fea6800.svg",
        true
    ){ App.goUrl(SHIPMENT_ONGOING) }

    CompViewLnb.menuList = mutableListOf(
        CompViewLnb.MenuItem(
            "",
            "",
            "shipment"
        ).apply{
            subList = mutableListOf(
                CompViewLnb.MenuItem.SubItem(SHIPMENT_ADD, "c@+ Create a shipment@common/lnb/link/create").also { it.clickBlock = { App.goUrl(SHIPMENT_ADD) } },
                CompViewLnb.MenuItem.SubItem(SHIPMENT_ONGOING, "c@Ongoing@common/lnb/link/ongoing").also { it.clickBlock = { App.goUrl(SHIPMENT_ONGOING) } },
                CompViewLnb.MenuItem.SubItem(SHIPMENT_DELIVERED, "c@Delivered@common/lnb/link/delivered").also { it.clickBlock = { App.goUrl(SHIPMENT_DELIVERED) } },
            )
        },
        CompViewLnb.MenuItem(
            "",
            ""
        ).apply{
            subList = mutableListOf(
                CompViewLnb.MenuItem.SubItem(RENT_A_SENSOR, "c@Rent a sensor@common/lnb/link/rent").also { it.clickBlock = { App.goUrl(RENT_A_SENSOR) } },
                CompViewLnb.MenuItem.SubItem(RENTAL_LIST, "c@Rental list@common/lnb/link/rental").also { it.clickBlock = { App.goUrl(RENTAL_LIST) } },
            )
        },
        CompViewLnb.MenuItem(
            "",
            ""
        ).apply{
            subList = mutableListOf(
                CompViewLnb.MenuItem.SubItem(ADDRESS_BOOK, "c@Address book@common/lnb/link/address").also { it.clickBlock = { App.goUrl(ADDRESS_BOOK) } }
            )
        },
        CompViewLnb.MenuItem(
            "",
            "c@My sensors@common/lnb/title/sensor",
            "sensor"
        ).apply{
            subList = mutableListOf()
        }
    )
    CompViewLnb.setList()

    GoogleMap.init("AIzaSyAvjIk0GCB8zDWzQ5I9SVG9-qwJ3T78lOg")

    ApiRsc.rsc("common")

    GoogleMapModal.inputVali = ValiAddressName
    GoogleMapModal.submitLabel = "c@Use this location@common/label/map/submit"
    GoogleMapModal.title = "c@Search locations on google maps@common/title/map"
    GoogleMapModal.inputLabel = "c@Location name@common/label/map/input"
    GoogleMapModal.addrLabel = "c@Address@common/label/map/addr"
    GoogleMapModal.infoLabel = "c@Click the location on the map.@common/label/map/info"
    GoogleMapModal.errorMsg = "c@This location name is already in use.@common/vali/map/01"
    GoogleMapModal.cancleBtn = "c@Cancel@common/label/cancel"
    GoogleMapModal.init(viewEL)

//    CompViewLnb.dummyOn()
    //리소스 부르느라 0.5초가 소비되서 주석걸음 - sean
//    Api.rsc("menu").also{
//        CompViewLnb.dummyOff()
//    }
//    Api.loginCheck()
}
package address

import senscloud.common.domPage.PageBase
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement
import senscloud.user.app.ClientUserApiAddress

class AddressBookP(router:eRouter<HTMLElement>): PageBase(::AddressBookView, router){
    override suspend fun net(data:eRouterData){
        ClientUserApiAddress.net()?.also {
            view?.entity(it)
        }
    }
}
package member.subManagement

import senscloud.common.domPage.PageBase
import senscloud.user.app.ClientUserApiMemberSub
import senscloud.user.entity.member.EntClientMemberSub
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement

class SubManagementP(router:eRouter<HTMLElement>): PageBase(::SubManagementView, router){
    override suspend fun net(data:eRouterData){
        ClientUserApiMemberSub.net()?.also {
            view?.entity(EntClientMemberSub(it))
        }
    }
}
package senscloud.user.comp

import ein2b.core.view.eView
import ein2b.core.view.html
import ein2b.core.view.image
import org.w3c.dom.HTMLElement
import senscloud.util.Chart

suspend fun eView<HTMLElement>.compSensorChartTempInfoSet(subKey:Any, item:CompSensorChartTempInfo.Companion.Item){
    CompSensorChartTempInfo(this, subKey, item)
}
class CompSensorChartTempInfo{
    companion object{
        private val factory = Factory.htmlUrl("comp/sensorChartTempInfo")
        enum class K{ maxTemp, minTemp, lastTemp, maxTempIcon, minTempIcon, lastTempIcon }
        const val successIcon = "//s3.ap-northeast-2.amazonaws.com/m42admin-v1/2022/06/07/cc6f492c307eec40bbf0616d7f8f6104.svg"
        const val failIcon = "//s3.ap-northeast-2.amazonaws.com/m42admin-v1/2021/09/16/966317fdf92ee5849b7072ef35182fd8.svg"
        class Item(
            catMinTemp:String, catMaxTemp:String,
            var minTemp:String, var maxTemp:String, var lastTemp:String
        ){
            private val catMinTempFloat = Chart.tempFloat(catMinTemp)
            private val catMaxTempFloat = Chart.tempFloat(catMaxTemp)
            fun check(temp:String):String{
                val tempFloat = Chart.tempFloat(temp)
                return if(tempFloat in catMinTempFloat..catMaxTempFloat) successIcon else failIcon
            }
            var minTempIcon = check(minTemp)
            var maxTempIcon = check(maxTemp)
            var lastTempIcon = check(lastTemp)
        }

        suspend operator fun invoke(rootView:eView<HTMLElement>, subKey:Any, item:Item){
            rootView.sub(subKey, factory){ v->
                v.sub(K.minTemp).html = item.minTemp
                v.sub(K.maxTemp).html = item.maxTemp
                v.sub(K.lastTemp).html = item.lastTemp
                v.sub(K.minTempIcon).image = item.minTempIcon
                v.sub(K.maxTempIcon).image = item.maxTempIcon
                v.sub(K.lastTempIcon).image = item.lastTempIcon
            }
        }
    }
}
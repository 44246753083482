package senscloud.user.entity.member

import senscloud.common.entity.api.user.member.*
import ein2b.core.entity.eEntity

abstract class EntClientProfile:eEntity(){
    var profileUrl:String by string(::profileUrl)
    var profileUrlList: MutableList<String> by stringList(::profileUrlList)
}

class EntClientMemberMasterInfoE(target: EntUserApiMemberMasterInfoE.Res): eEntity() {
    var fullName:String by string(::fullName){ default(target.fullName) }
    var phone:String by string(::phone){ default(target.phone) }
    var companyName:String by string(::companyName){ default(target.companyName) }
    var email:String by string(::email){ default(target.email) }
    var profileUrl:String by string(::profileUrl){ default(target.profileUrl) }
    var profileUrlList: MutableList<String> by stringList(::profileUrlList){ default(target.profileUrlList) }
}

class EntClientMemberSubInfoE(target: EntUserApiMemberSubInfoE.Res): eEntity() {
    var fullName:String by string(::fullName){ default(target.fullName) }
    var phone:String by string(::phone){ default(target.phone) }
    var profileUrl:String by string(::profileUrl){ default(target.profileUrl) }
    var profileUrlList: MutableList<String> by stringList(::profileUrlList){ default(target.profileUrlList) }
}

class EntSubProfile:EntClientProfile(){
    companion object{
        operator fun invoke(res: EntClientMemberSubInfoE) = EntSubProfile().also {
            it.profileUrlList = res.profileUrlList
            it.profileUrl = res.profileUrl
        }
    }
}

class EntMasterProfile:EntClientProfile(){
    companion object{
        operator fun invoke(res: EntClientMemberMasterInfoE) = EntMasterProfile().also {
            it.profileUrlList = res.profileUrlList
            it.profileUrl = res.profileUrl
        }
    }
}

class EntSubAddProfile:EntClientProfile(){
    companion object{
        operator fun invoke(res: EntClientMemberSubW) = EntSubProfile().also {
            it.profileUrlList = res.profileUrlList
            it.profileUrl = res.profileUrlList.first()
        }
    }
}

class EntClientMemberSubW(target: EntUserApiMemberSubW.Res): eEntity() {
    var profileUrlList: MutableList<String> by stringList(::profileUrlList){ default(target.profileUrlList) }
}

class EntSubEProfile:EntClientProfile(){
    companion object{
        operator fun invoke(res: EntClientMemberSubE) = EntSubEProfile().also {
            it.profileUrlList = res.profileUrlList
            it.profileUrl = res.profileUrl
        }
    }
}

class EntClientMemberSub(target: EntUserApiMemberSub.Res): eEntity() {
    class Sub:eEntity(){
        var username:String by string(::username)
        var fullName:String by string(::fullName)
        var phone:String by string(::phone)
        var profileUrl:String by string(::profileUrl)
    }
    var cnt:String by string(::cnt){ default("${target.memberList.size}") }
    var subList: MutableList<Sub> by entityList(::subList, ::Sub){ default(
        target.memberList.map { mem ->
            Sub().also { sub ->
                sub.username = mem.username
                sub.phone = mem.phone
                sub.profileUrl = mem.profileUrl
                sub.fullName = mem.fullName
            }
        }.toMutableList())}

    fun phoneFormat(phone:String):String {
        var result = ""
        when(phone.length) {
            11 -> {
                val first = phone.slice(IntRange(0, 2))
                val second = phone.slice(IntRange(3, 6))
                val last = phone.slice(IntRange(7, 10))
                result = "$first-$second-$last"
            }
            10 -> {
                val first = phone.slice(IntRange(0, 2))
                val second = phone.slice(IntRange(3, 5))
                val last = phone.slice(IntRange(6, 9))
                result = "$first-$second-$last"
            }
            else -> console.log("Wrong phone format")
        }
        return result
    }
}

class EntClientMemberSubE(target: EntUserApiMemberSubE.Res): eEntity() {
    var userName:String by string(::userName){ default(target.username) }
    var fullName:String by string(::fullName){ default(target.fullName) }
    var phone:String by string(::phone){ default(target.phone) }
    var profileUrl:String by string(::profileUrl){ default(target.profileUrl) }
    var profileUrlList: MutableList<String> by stringList(::profileUrlList){ default(target.profileUrlList) }
    fun phoneFormat(phone:String):String {
        var result = ""
        when(phone.length) {
            11 -> {
                val first = phone.slice(IntRange(0, 2))
                val second = phone.slice(IntRange(3, 6))
                val last = phone.slice(IntRange(7, 10))
                result = "$first-$second-$last"
            }
            10 -> {
                val first = phone.slice(IntRange(0, 2))
                val second = phone.slice(IntRange(3, 5))
                val last = phone.slice(IntRange(6, 9))
                result = "$first-$second-$last"
            }
            else -> console.log("Wrong phone format")
        }
        return result
    }
}


import kotlinx.browser.window

object eWindow{
    //전역에서 이벤트 감지해서 block 처리
    //홀더 adding 될때 addClick 해줘야하고 캐시잡히는 홀더 같은 경우는 remove되는 경우가 없음
    //UI showed 에서 addClick hided 에서 removeClick 해줘야함.
    const val WINDOW_CLICK = "windowClick"
    private val clickListener = hashSetOf<()->Unit>()
    fun addClick(block:()->Unit) = clickListener.add(block)
    fun removeClick(block:()->Unit) = clickListener.remove(block)
    init{
        window.addEventListener("click", {
            clickListener.forEach{ it() }
        })
    }
}
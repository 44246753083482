package senscloud.common.enm

enum class EnumApiShipmentSearchType(val value:String, val label:String, val type:EnumApiCommonSearchType) {
    ALL("", "", EnumApiCommonSearchType.NONE),
    SHIPMENT_NAME("name", "c@Shipment name@shipment/search/option/name", EnumApiCommonSearchType.TEXT),
    ROUTE_FROM("fromRoute", "c@Route (From)@shipment/search/option/from", EnumApiCommonSearchType.TEXT),
    ROUTE_TO("toRoute", "c@Route (To)@shipment/search/option/to", EnumApiCommonSearchType.TEXT),
    TIME_CREATED("createdTime", "c@Time (Created)@shipment/search/option/created", EnumApiCommonSearchType.DATE),
    TIME_DEPARTURE("departureTime", "c@Time (Departure)@shipment/search/option/departure", EnumApiCommonSearchType.DATE),
    TIME_ARRIVAL("arrivalTime", "c@Time (Arrival)@shipment/search/option/arrival", EnumApiCommonSearchType.DATE);
    companion object {
        fun ongoingList() =
            listOf(ALL, SHIPMENT_NAME, ROUTE_FROM, ROUTE_TO, TIME_CREATED, TIME_DEPARTURE)
        fun ongoingSelectList() = ongoingList().filter { it !== ALL }
        fun deliveredList() =
            listOf(ALL, SHIPMENT_NAME, ROUTE_FROM, ROUTE_TO, TIME_CREATED, TIME_DEPARTURE, TIME_ARRIVAL)
        fun deliveredSelectList() = deliveredList().filter { it !== ALL }
    }
}
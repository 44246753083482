import ein2b.core.core.err
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eVali
import ein2b.core.view.*
import ein2b.js.browser.eScriptLoader
import ein2b.js.js.arr
import ein2b.js.js.isTruthy
import ein2b.js.js.obj
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import prop.compLabelInputSectionText
import kotlin.math.pow
import kotlin.math.sqrt

object GoogleMapModal{
    private val NOT_SELECTED = MapData()
    private var mapData = NOT_SELECTED
    var default = GoogleMap.MapCreationOption(37.566869252467356, 126.9786522459667, 20)
    private var mapInfo = default
    private lateinit var target: eView<HTMLElement>
    private var isInit = true
    var submitLabel = "Use this location"
    var title = "Search locations on google maps"
    var inputLabel = "Location name"
    var addrLabel = "Address"
    var infoLabel = "Click the location on the map."
    var errorMsg = "This location name is already in use."
    var cancleBtn = "Cancel"
    var inputVali: eVali? = null
    class MapData(var lat:Double = 37.566869252467356, var lng:Double = 126.9786522459667, var addr:String = "", var name:String = "")
    //language=html
    private val f = Factory.html("""
    <section class="fixed-block" style='display:none'>
        <div class="contents" style="width:80%;height:80%">
            <div data-view="title" class="h2"></div>
            <div style="position:absolute;top:80px;bottom:20px;left:20px;right:20px;border:1px solid #cdcdcd">
                <div id="googleMap" style="width:100%;height:100%;position:relative;overflow:hidden"></div>
                <div data-view="info" class="view-border" style="position:absolute;top:20px;left:50%;transform:translateX(-50%)"></div>
                <div data-view="form" class="view-border" style="z-index:1;position:absolute;top:10px;left:10px;width:330px;padding-bottom:60px">
                    <b data-view="location" style="margin-top:0"></b>
                    <div data-view="addrLabel" class="input-block-label margin-top20"></div>
                    <div data-view="addr"></div>
                    <div style="position:absolute;right:10px;bottom:10px">
                        <div data-view="cancelBtn" class="btn border"></div>
                        <div data-view="submitBtn" class="btn submit"></div>
                    </div>
                </div>
            </div>
            <div data-view="closeBtn" class="btn-close" style="position:absolute;right:20px;top:40px"></div>
        </div>
    </section>
    """)
    private enum class K{
        title, info, form, location, addr, addrLabel,

        cancelBtn, submitBtn, closeBtn;

        override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
    }

    suspend fun init(rootEl: HTMLElement){
        target = eView(f){
            it.displayNone()
            it.sub(K.title).html = title
            it.sub(K.info){ info ->
                info.html = infoLabel
                info.displayBlock()
            }
            it.sub(K.form).displayNone()
            it.compLabelInputSectionTextSet(K.location, inputLabel, inputVali)
            it.sub(K.addrLabel).html = addrLabel
            it.sub(K.addr)
            it.sub(K.cancelBtn) { btn ->
                btn.html = cancleBtn
                btn.click = {_, _ ->
                    eLaunch { cancel() }
                }
            }
            it.sub(K.submitBtn) { btn ->
                btn.html = submitLabel
            }
            it.sub(K.closeBtn) { btn ->
                btn.click = {_, _ ->
                    target.displayNone()
                }
            }
        }
        target.setTemplate()
        rootEl.appendChild(target.template!!)
    }
    suspend fun open(v:GoogleMap.MapCreationOption = default, name:String = "", submit:(suspend (MapData)->Boolean)? = null){
        clear()
        mapInfo = v
        window.requestAnimationFrame{
            eLaunch {
                GoogleMap.selectMapCreate(
                    "googleMap",
                    GoogleMap.MapCreationOption(mapInfo.lat, mapInfo.lng, mapInfo.zoom),
                    v != default
                ){ addr, lat, lng ->
                    if(isTruthy(addr)){
                        eLaunch { select("$addr", lat, lng) }
                    }
                }
            }
        }
        if(v != default && name.isNotBlank()) {
            target.sub(K.location).compLabelInputSectionText().inputValue(name)
        }
        submit?.also { event ->
            target.sub(K.submitBtn).click = {_, _ ->
                eLaunch {
                    target.sub(K.location).compLabelInputSectionText {
                        if(it.check()) { mapData.name = it.out() }
                    }
                    if(mapData != NOT_SELECTED) {
                        if(event(mapData)) {
                            target.displayNone()
                        } else {
                            target.sub(K.location).compLabelInputSectionText().changeError(errorMsg, false)
                        }
                    }
                }
            }
        }
        target.displayBlock()
    }
    suspend fun select(addr:String, lat:Double, lng:Double) {
        target.sub(K.info).displayNone()
        target.sub(K.form).displayBlock()
        target.sub(K.addr).html = addr
        mapData = MapData(lat, lng, addr)
        //console.log("선택")
    }
    suspend fun cancel() {
        clear()
        mapInfo = GoogleMap.MapCreationOption(mapData.lat, mapData.lng, 20)
        open()
    }
    suspend fun clear() {
        target.sub(K.info).displayBlock()
        target.sub(K.form).displayNone()
        target.sub(K.addr).html = ""
        target.sub(K.location).compLabelInputSectionText().clear()
    }
}



//지도 처리 클래스
object GoogleMap{
    private var isInit = false

    //초기화
    suspend fun init(googleMapKey:String){
        if(isInit) return
        isInit = true
        eScriptLoader.load("https://maps.googleapis.com/maps/api/js?key=$googleMapKey&language=en")
        eScriptLoader.load("${factoryPath()}/js/google.map.js")
    }
    //경도/위도
    open class Position(val lat:Double, val lng:Double)

    //지도생성옵션
    class MapCreationOption(lat:Double, lng:Double, val zoom:Int):Position(lat, lng)

    //시작점
    class Mark(lat:Double, lng:Double, val title:String):Position(lat, lng)

    //마커
    open class MarkWithId(lat:Double, lon:Double, val id:String, val title:String):Position(lat, lon)

    //shipment
    class Shipment(val id:String, val start:Mark, val end:Mark)

    class Point(lat:Double, lng:Double, val title:String, val color:String, val markList:List<MarkWithColorOrd>):Position(lat, lng)
    class MarkWithColorOrd(lat:Double, lng:Double, id:String, title:String, val color:String, val ord:String, val isDisabled:Boolean):MarkWithId(lat, lng, id, title)


    //지도 생성
    suspend fun create(divId:String, mapOpt:MapCreationOption, needMarks:Boolean = true, disableUi:Boolean = true){
        if(!isInit) err("Not init DispatchMap")
        mapCreate(divId, obj {
            this.zoom = mapOpt.zoom
            this.center = obj{
                this.lat = mapOpt.lat
                this.lng = mapOpt.lng
            }
            disableDefaultUI = disableUi
            if(disableUi) gestureHandling = "none"

            /*panControl = false
            zoomControl = true
            mapTypeControl = false
            scaleControl = false
            streetViewControl = false
            overviewMapControl = false
            fullscreenControl = false*/
        }, needMarks)
    }

    suspend fun simpleMapCreate(divId:String, mapOpt:MapCreationOption, mark: Mark){
        simpleMap(divId, obj {
                zoom = mapOpt.zoom
                center = obj{
                    lat = mapOpt.lat
                    lng = mapOpt.lng
                }
                disableDefaultUI = true
                gestureHandling = "none"
            },
            obj{
                lat = mark.lat
                lng = mark.lng
                title = mark.title
            }
        )
    }

    suspend fun selectMapCreate(divId:String, mapOpt:MapCreationOption, isEdit:Boolean, click:(dynamic, Double, Double) -> Unit){
        //console.log("selectMapCreate")
        selectMap(divId, obj {
            zoom = mapOpt.zoom
            center = obj{
                lat = mapOpt.lat
                lng = mapOpt.lng
            }
        }, isEdit, click)
    }

    suspend fun renderMarkPointList(
        pointList:List<Point>,
        isShipping:Boolean,
        isMarkInit:Boolean,
        clickMark:suspend (String)->Unit
    ){
        if(!isInit) err("Not init Map")

        val result = arr {  }
        var i = 0

        pointList.forEach { point ->
            val markList = arr {  }
            var j = 0
            point.markList.forEach { mark ->
                markList[j++] = obj {
                    this.lat = mark.lat
                    this.lng = mark.lng
                    this.id = mark.id
                    this.title = mark.title
                    this.color = mark.color
                    this.isDisabled = mark.isDisabled
                    this.ord = mark.ord
                }
            }
            result[i++] = obj{
                this.lat = point.lat
                this.lng = point.lng
                this.color = point.color
                this.title = point.title
                this.markList = markList
            }
        }
        mapRenderMarkPointList(result, isShipping, isMarkInit){
            println("id = $it")
            eLaunch{ clickMark(it) }
        }
    }

    suspend fun renderStartEndMarkList(
        markList:List<Shipment>,
        clickMark:suspend (String)->Unit
    ){
        if(!isInit) err("Not init Map")

        val result = arr {  }
        var i = 0

        markList.forEach { ship ->
            result[i++] = obj{
                this.start = obj {
                    this.lat = ship.start.lat
                    this.lng = ship.start.lng
                    this.title = ship.start.title
                }
                this.end = obj {
                    this.lat = ship.end.lat
                    this.lng = ship.end.lng
                    this.title = ship.end.title
                }
                this.id = ship.id
            }
        }
        mapRenderStartEndMarkList(result){
            println("id = $it")
            eLaunch{ clickMark(it) }
        }
    }

    suspend fun renderMarkList(
        markList:List<MarkWithId>,
        clickMark:suspend (String)->Unit
    ){
        if(!isInit) err("Not init Map")
        val result = arr {  }
        var i = 0
        markList.forEach {
            result[i++] = obj{
                this.id = it.id
                this.lat = it.lat
                this.lng = it.lng
                this.title = it.title
            }
        }
        mapRenderMarkList(
            result){
            println("id = $it")
            eLaunch{ clickMark(it) }
        }
    }

    //지도에 정보 보여주기
    //infoContent는 html임
    fun showInfo(mark:MarkWithId, infoContent: String){
        mapShowInfo(mark.id, mark.lat, mark.lng, infoContent)
    }

    //특정 마크과 가까이 붙어있는 마크들을 전부 리턴
    fun nearFilter(id:String, markList:List<MarkWithColorOrd>):List<MarkWithColorOrd>{
        val p1 = divPoint(id)
        return markList.filter {
            val p2 = divPoint(it.id)
            val dist = sqrt((p2.first - p1.first).pow(2) + (p2.second - p1.second).pow(2))
            dist < 26.0
        }
    }

    //지도상의 배송의 픽셀 단위 위치
    //지도상에 겹친 Marker를 계산하기 위해 사용함
    private fun divPoint(id:String):Pair<Double,Double> =
        mapDivPoint(id).split(",").let{
            Pair(it[0].toDouble(), it[1].toDouble())
        }
}

private external fun mapCreate(id:String, opts:dynamic, needMarks:Boolean):Unit
private external fun simpleMap(id:String, opts:dynamic, mark:dynamic):Unit
private external fun selectMap(id:String, opts:dynamic, isEdit:Boolean, click:(dynamic, Double, Double) -> Unit):Unit
private external fun mapRenderStartEndMarkList(markList:dynamic, markClick:(deliverId:String)->Unit):Unit //[{eventId:'',lat:'',lng:''},...]
private external fun mapRenderMarkList(markList:dynamic, markClick:(deliverId:String)->Unit):Unit //[{eventId:'',lat:'',lng:''},...]
private external fun mapRenderMarkPointList(pointList:dynamic, isShipping:Boolean, isInit:Boolean, markClick:(deliverId:String)->Unit):Unit //[{eventId:'',lat:'',lng:''},...]
private external fun mapShowInfo(id:String, lat:Double, lng:Double, infoContent:String)
private external fun mapDivPoint(id:String):String
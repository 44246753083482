package senscloud.common.vali

import ein2b.core.validation.eRuleVali

val ValiRentalQuantity = eRuleVali{
    Case {
        Rule("c@Invalid quantity.@rentasensor/vali/quantity/01") {
            _valiPositiveIntCheck(it)
        }
    }
}

val ValiRentalName = eRuleVali{
    Case {
        Rule { "$it".trim() }
        MinLength(1, "c@Enter your full name.@rentasensor/vali/name/01")
        MaxLength(32, "c@Full name must be under 32 characters.@rentasensor/vali/name/02")
    }
}

val ValiRentalPhone = eRuleVali{
    val msg = "c@Invalid phone number format.@rentasensor/vali/phone/01"
    Case{
        String()
        Trim()
        Rule{ "$it".replace("-","").replace(" ", "") }
        MinLength(1, "c@Enter your phone number.@rentasensor/vali/phone/02")
        MaxLength(11, msg)
        Rule(msg){ _valiPhoneCheck(it) }
    }
}

val ValiRentalAddress2 = eRuleVali{
    Case {
        Rule { "$it".trim() }
        MaxLength(1024, "c@Address must be under 1,024 characters.@rentasensor/vali/address/01")
    }
}

val ValiRentalMessage = eRuleVali{
    val msg = "c@Message must be under 200 characters.@rentasensor/vali/message/01"
    Case {
        Rule { "$it".trim() }
        MaxLength(200, msg)
    }
}
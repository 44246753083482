package senscloud.common.pageView.rentalList

import comp.CompPageTitle
import comp.CompViewList
import comp.compPaginationSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import prop.clearCompValue
import prop.compPagination
import prop.compViewList
import senscloud.common.app.App
import senscloud.common.entity.EntInit
import senscloud.common.entity.api.user.rental.EntUserApiRental
import senscloud.user.app.ClientUserApiRental

private enum class K{
    section, emptySection, RENTAL, cnt, rentalList, page,
    rental_title, rental_status, rental_createDate, rental_period, rental_sensor, rental_seeDetails;
    override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
}
suspend fun rentalListView(
    factory:suspend()-> HTMLElement, rentalFactory:suspend()-> HTMLElement,
    listType:CompViewList.Companion.EnumCompViewListType = CompViewList.Companion.EnumCompViewListType.LIST_CARD,
    detail:suspend (String)->Unit
) = eView(factory){ rootView ->
    CompPageTitle(rootView){
        it.title = "c@Rental list@rentallist/title/rentallist"
    }
    App.emptySub(rootView, K.section, K.RENTAL, K.cnt, K.emptySection, K.rentalList)
    rootView.compPaginationSet(K.page, "page"){}

    CompViewList(rootView, K.rentalList, listType)

    rootView.addEntityHook(EntUserApiRental.Res::class, object:eEntityHook<HTMLElement, EntUserApiRental.Res>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntUserApiRental.Res){
            val emptySection = rootView.sub(K.emptySection){ it.html = "c@Couldn't find any rental.@rentallist/text/emptylist" }
            if(entity.rentalList.isEmpty()){
                view.sub(K.section).displayNone()
                emptySection.displayBlock()
            }else{
                view.sub(K.section).displayBlock()
                emptySection.displayNone()
            }
            view.sub(K.cnt).html = entity.meta.totalRows
            view.sub(K.RENTAL){
                it.html = if(entity.meta.totalRows in listOf("0", "1")) "c@rental@rentallist/text/rental" else "c@rentals@rentallist/text/rentals"
            }

            view.sub(K.rentalList).compViewList().setList(entity.rentalList, rentalFactory){ rental, v, li ->
                li.click = {_,_-> if(CompViewList.Companion.EnumCompViewListType.LIST_BORDER == listType) eLaunch{ detail(rental.rowid) } }
                App.subHtmlFromEntity(v, rental, K.rental_period, K.rental_sensor)
                v.sub(K.rental_title) {
                    it.attr("v0" to rental.title)
                    it.html = "c@Rental #@rentallist/text/rentalTitle"
                }
                v.sub(K.rental_createDate) {
                    it.attr("v0" to rental.createDate)
                    it.html = "c@Created at@common/label/createdat"
                }
                v.sub(K.rental_status){
                    it.html = rental.rentalState.title
                    it.className = rental.rentalState.className
                }
                v.sub(K.rental_seeDetails){
                    it.html = "c@See details@rentallist/text/seedetails"
                    it.click = { e,_ ->
                        e.stopPropagation()
                        e.stopImmediatePropagation()
                        eLaunch{ detail(rental.rowid) }
                    }
                }
            }
            view.sub(K.page).compPagination { page ->
                page.setPage(
                    entity.meta.totalRows.toLong(),
                    entity.meta.page.toLong(),
                    entity.meta.rowPerPage.toLong(),
                    entity.meta.pagePerGroup.toLong()
                )
                page.clickEvent = { idx ->
                    ClientUserApiRental.net{
                        it.page = "$idx"
                    }?.let {
                        window.scrollTo(0.0, 0.0)
                        view.entity(it)
                    }
                }
            }
        }
    })
}
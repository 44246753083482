package comp

import Factory
import compLabelInputSectionPasswordSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eRuleVali
import ein2b.core.view.*
import ein2b.js.crypto.eCrypto
import org.w3c.dom.HTMLElement
import prop.compLabelInputSectionPassword
import prop.compLabelInputSectionText

/* 사용법
suspend fun LoginView() = eView(factory) { rootView ->
    val compLogin = CompLogin(rootView,
        "Username", ValiMemberLoginUserName,
        "password", ValiMemberLoginPw ,
        "Login", { compReq->
            ClientUserApiMemberLogin.net{ req ->
                req.username = compReq.username
                req.pw = compReq.pw
                req.rsaId = compReq.rsaId
            }?.also{ res ->
                compReq.end(CompLogin.Res(res.isError, res.error.username, res.error.pw))
            }
        }, { hashManager.goUrl(SHIPMENT_ONGOING) },
        "Sign up", { hashManager.goUrl(SIGNUP) })
    rootView.addEntityHook(EntUserApiRsa.Res::class, object : eEntityHook<HTMLElement, EntUserApiRsa.Res> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntUserApiRsa.Res) {
            compLogin.clear()
            compLogin.rsa = CompLogin.Rsa(entity.rsa.id, entity.rsa.key)
        }
    })
}*/



class CompLogin:Comp{
    class Rsa(val id:String, val key:String)
    class Req(val id:String, val pw:String, val rsaId:String, val end:suspend (Res)->Unit)
    class Res(val isError:Boolean = true, val id:String = "", val pw:String = "")
    companion object{
        private enum class K {
            id, pw, login, loginBtn, signUpBtn;
        }
        //language=html
        private val factory = Factory.html("""
        <section class="loginTemp">
            <form class="form" onsubmit="return false" novalidate style='padding:40px 60px 60px 60px'>
                <b data-view="id" style="text-align:left"></b>
                <b data-view="pw" style="text-align:left;margin-top:25px"></b>
                <button data-view="loginBtn" style="margin-top:25px" class="btn width100per"></button>
            </form>
             <div data-view="signUpBtn" class="link-btn-text margin-top20"></div>
        </section>
        """)

        suspend operator fun invoke(
            root:eView<HTMLElement>,
            idLabel:String, idVail: eRuleVali,
            pwLabel:String, pwVail: eRuleVali,
            loginLabel:String, loginClick: suspend (Req)->Unit, loginEnd:suspend ()->Unit,
            signUpLabel:String, signUpClick:suspend ()->Unit
        ):CompLogin{
            val comp = CompLogin()
            comp.target = root.sub(K.login, factory){ v ->
                val id = v.compLabelInputSectionTextSet(K.id, idLabel, idVail)
                val pw = v.compLabelInputSectionPasswordSet(K.pw, pwLabel, pwVail)
                v.sub(K.loginBtn) {loginBtnView ->
                    loginBtnView.html = loginLabel
                    loginBtnView.click = {_, _ ->
                        eLaunch{
                            if(listOf(id, pw).map{it.check()}.all { it }){
                                val test = Req(id.out(), eCrypto.rsaEncrypt(pw.out(), comp.rsa.key), comp.rsa.id){ res->
                                    if(res.isError){
                                        id.changeError(res.id, res.id.isBlank())
                                        pw.changeError(res.pw, res.pw.isBlank())
                                    }else{
                                        loginEnd()
                                    }
                                }
                                loginClick(test)
                            }
                        }
                    }
                }
                v.sub(K.signUpBtn) {signUpBtnView ->
                    signUpBtnView.html = signUpLabel
                    signUpBtnView.click = {_, _ -> eLaunch{ signUpClick() } }
                }
            }
            comp.baseProp(comp.target)
            return comp
        }
    }
    lateinit var target:eView<HTMLElement>
    lateinit var rsa:Rsa
    suspend fun clear() {
        target.sub(K.id).compLabelInputSectionText().clear()
        target.sub(K.pw).compLabelInputSectionPassword().clear()
    }
}
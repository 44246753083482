package shipment.ongoing

import senscloud.common.domPage.PageBase
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement
import senscloud.user.app.ClientUserApiShipmentOngoing
import senscloud.user.entity.shipping.EntClientOngoingShipping

class OngoingP(router:eRouter<HTMLElement>): PageBase(::OngoingView, router){
    override suspend fun net(data:eRouterData){
        ClientUserApiShipmentOngoing.net()?.also {
            view?.entity(EntClientOngoingShipping(it))
        }
    }
}
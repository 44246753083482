package senscloud.common.entity.api.user.mysensors

import ein2b.core.entity.eEntity
import ein2b.core.entity.field.enum.enum
import senscloud.common.enm.EnumApiAccessAllow
import senscloud.common.enm.EnumApiSensorStatus
import senscloud.common.enm.EnumApiSensorTab
import senscloud.common.entity.api.user.common.EntTempInfo
import senscloud.common.entity.api.user.common.EntUserApi
import senscloud.common.entity.api.user.common.EntUserApiPageMeta
import senscloud.common.entity.api.user.common.USER_API_SENSOR_PATH
import senscloud.common.vali.ValiPage
import senscloud.common.vali.ValiRowId
import senscloud.common.vali.ValiSearchText

//sensor
object EntUserApiMySensors : EntUserApi<EntUserApiMySensors.Req, EntUserApiMySensors.Res>(::Req, ::Res) {
    const val PATH = "$USER_API_SENSOR_PATH"
    const val PATH_JSON = "$PATH/json"
//    init {
//        isJSON = true
//    }

    class Req : eEntity() {
        var sensorCatRowid: String by string(::sensorCatRowid) { validator(ValiRowId) }
        var page: String by string(::page) {
            default("1", false)
            validator(ValiPage)
        }
        var searchText: String by string(::searchText) {
            default("", false)
            validator(ValiSearchText)
        }
        var searchTab: EnumApiSensorTab by enum(::searchTab) {
            default(EnumApiSensorTab.ALL, false)
        }
    }

    class Res : eEntity(true) {
        class Meta : EntUserApiPageMeta() {
            var rowid: String by string(::rowid)
            var searchText: String by string(::searchText) { default("", false) }
            var searchTab: EnumApiSensorTab by enum(::searchTab) { default(EnumApiSensorTab.ALL, false) }
        }
        class Info : eEntity(true) {
            var title: String by string(::title)
            var minTemp: String by string(::minTemp)
            var maxTemp: String by string(::maxTemp)
            var imageUrl: String by string(::imageUrl)
        }
        class Record : eEntity(true) {
            var sensorCode:String by string(::sensorCode) //211111000243
            var sensorSessionRowid:String by string(::sensorSessionRowid) //#2
            var startDate:String by string(::startDate) //2022-04-14(Thu) 5:00 PM
            var endDate:String by string(::endDate) //2022-04-14(Thu) 7:00 PM
            var minTemp:String by string(::minTemp) //5.1°C
            var maxTemp:String by string(::maxTemp) //7.0°C
            var lastTemp:String by string(::lastTemp) //6.0°C
            var tempInfo: EntTempInfo by entity(::tempInfo, ::EntTempInfo) { default(EntTempInfo(), false)}
        }
        class Sensor : eEntity(true) {
            var rowid: String by string(::rowid)
            var code: String by string(::code) //211111000243
            var rental: String by string(::rental) //10
            var periodOfUse: String by string(::periodOfUse) //2022-02-01 ~ 2022-07-31
            var batteryReplacedDate: String by string(::batteryReplacedDate) {default("")}//2021-06-01
            var status: EnumApiSensorStatus by enum(::status) {default(EnumApiSensorStatus.INACTIVE)}// History에서의 - Active, InAcitve
            var tempInfo: EntTempInfo by entity(::tempInfo, ::EntTempInfo) { default(EntTempInfo(), false)}
        }
        var meta: Meta by entity(::meta, ::Meta) { default(Meta(), false) }
        var info: Info by entity(::info, ::Info) { default(Info(), false) }
        var recordList: MutableList<Record> by entityList(::recordList, ::Record) { default(mutableListOf(), false) }
        var sensorList: MutableList<Sensor> by entityList(::sensorList, ::Sensor) { default(mutableListOf(), false) }
    }

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val rsckeys = "common, sensor"
}
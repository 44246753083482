package rental.rent

import CompLabelInputSection
import Factory
import app.hashManager
import comp.CompPageTitle
import comp.compInputSectionSet
import comp.input.CompInputData
import comp.input.CompInputDate
import comp.input.CompInputText
import compLabelInputSectionDateSet
import compLabelInputSectionTextAreaSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.entity.eEntity
import ein2b.core.view.*
import ein2b.js.js.eDate
import org.w3c.dom.HTMLElement
import prop.*
import senscloud.common.app.App
import senscloud.common.entity.EntInit
import senscloud.common.entity.api.user.rental.EntUserApiRentalW
import senscloud.common.entity.api.user.rental.EntUserApiRentalWp
import senscloud.common.vali.*
import senscloud.user.app.ClientUserApiAddressWp
import senscloud.user.app.ClientUserApiRentalWp
import senscloud.user.app.RouterKey
import view.CompViewToast
import kotlin.js.Date

private val factory = Factory.htmlUrl("rental/rent/rent")
private val sensorListT = Factory.htmlUrl("rental/rent/sensorListT")

private enum class K{
    CHOOSEASENSOR, sensorList, SHIPTO, RENTALINFO, periodDate, message, dateInput, cBtn, wpBtn,
    fullNameInput,
    phoneInput,
    addressInput,
    address2Input,
    sensorImg, title, temp, contents, quantityInput, messageInput;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

class ClientSensor: eEntity() {
    lateinit var sensorComp: CompLabelInputSection<String, String, String, CompInputText>
    lateinit var rowid:String
}

suspend fun RentView() = eView(factory) { rootView ->
    val clientSensorList = mutableListOf<ClientSensor>()
    App.subHtmlFromLabel(rootView, K.CHOOSEASENSOR to "c@Choose a sensor@rentasensor/title/choose", K.SHIPTO to "c@Ship to@rentasensor/title/shipto", K.RENTALINFO to "c@Rental Info@rentasensor/title/rentalinfo")
    CompPageTitle(rootView) {
        it.title = "c@Rent a sensor@rentasensor/title/pagetitle"
    }
    App.emptySub(rootView, K.sensorList, K.periodDate, K.message, K.wpBtn)
    rootView.sub(K.cBtn){
        it.html = "c@Cancel@rentasensor/title/cancel"
        it.click = {_,_->
            eLaunch { hashManager.back() }
        }
    }

    rootView.compLabelInputSectionTextSet(K.fullNameInput, "c@Full name@rentasensor/label/fullname", ValiRentalName)
    rootView.compLabelInputSectionTextSet(K.phoneInput, "c@Phone@rentasensor/label/phone", ValiRentalPhone)

    CompLabelInputSection(rootView, K.addressInput, "c@Address@rentasensor/label/address",
        CompInputData<String> {
            it.mustSelect = true
            it.addOn = listOf(CompInputData.AddOnType.ADD_BUTTON, CompInputData.AddOnType.EMPTY)
            it.addMsg = "c@Search locations on google maps@shipment_add_edit/map/addmsg"
            it.emptyMsg = "c@Couldn't find in your address book.@shipment_add_edit/map/emptymsg"
            it.addEvent = {
                eLaunch {
                    GoogleMapModal.open { md ->
                        ClientUserApiAddressWp.net{ req ->
                            req.name = md.name
                            req.lat = "${md.lat}"
                            req.lng = "${md.lng}"
                            req.address = md.addr
                        }?.also { entity ->
                            if(!entity.isExist) {
                                rootView.sub(K.addressInput).compLabelInputSectionDataString {
                                    it.input.dataList = entity.addressList.map { a ->
                                        CompInputData.InputData(a.rowid, "${a.name} (${a.address})", a.name)
                                    }.toMutableList()
                                }
                            }
                            return@open !entity.isExist
                        }
                        return@open true
                    }
                }
            }
            it.wrapperClass = "input-data flex-grow-1"
            it.vali = ValiAddress
        }
    )

    rootView.compLabelInputSectionTextSet(K.address2Input, "c@Address 2 (optional)@rentasensor/label/address2", ValiRentalAddress2)
    rootView.compLabelInputSectionTextAreaSet(K.messageInput, "c@Message (optional)@rentasensor/label/message", ValiRentalMessage)

    rootView.compInputSectionSet<String>(
        K.dateInput, wrapperClass = "width320px", errorClass = "form-error", isOneError = true, inputClass = "input-section-wave"
    ) {
        val today = Date()
        mutableListOf(CompInputDate { comp ->
            comp.ymdPattern = "Y-m-d"
        }, CompInputDate { comp ->
            comp.ymdPattern = "Y-m-d"
            comp.default = eDate.part(comp.ymdPattern, Date(today.getFullYear(), today.getMonth(), today.getDate() + 1))
        })
    }

    rootView.addEntityHook(EntUserApiRentalW.Res::class, object:eEntityHook<HTMLElement, EntUserApiRentalW.Res>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntUserApiRentalW.Res){
            App.subHtmlFromEntity(view, entity, K.periodDate, K.message)
            App.setCommonList(view.sub(K.sensorList), sensorListT, entity.sensorList, K.title, K.temp, K.contents){ _, v, sl ->
                v.sub(K.sensorImg).image = sl.imageUrl
                clientSensorList += ClientSensor().also {
                    it.sensorComp = v.compLabelInputSectionTextSet(K.quantityInput, "c@Quantity@rentasensor/label/quality", ValiRentalQuantity).also {
                        it.inputValue("0")
                    }
                    it.rowid = sl.rowid
                }
            }

            view.sub(K.addressInput).compLabelInputSectionDataString {
                it.input.dataList = entity.addressList.map { a ->
                    CompInputData.InputData(a.rowid, "${a.title} (${a.addr1})", a.title)
                }.toMutableList()
            }

            val fullNameInput = view.sub(K.fullNameInput).compLabelInputSectionText().also {
                it.inputValue(entity.name)
            }
            val phoneInput = view.sub(K.phoneInput).compLabelInputSectionText().also {
                it.inputValue(entity.phone)
            }
            val addressInput = view.sub(K.addressInput).compLabelInputSectionDataString()

            val address2Input = view.sub(K.address2Input).compLabelInputSectionText()
            val dateInput = view.sub(K.dateInput).compInputSection<String>()
//            val startDate =  view.sub(K.startDateInput).compLabelInputSectionDate()
//            val endDate =  view.sub(K.endDateInput).compLabelInputSectionDate()            }
            val messageInput = view.sub(K.messageInput).compLabelInputSectionTextArea()

            view.sub(K.wpBtn){
                it.html = "c@Rent a sensor@rentasensor/title/rentasensor"
                it.click = {_,_->
                    eLaunch {
                        val result1 = clientSensorList.all { it.sensorComp.check() }
                        val result2 = App.checkAll(fullNameInput, phoneInput, addressInput, address2Input, dateInput, messageInput)
                        val result3 =  if(dateInput.inputView(0).compInputDate().out() >= dateInput.inputView(1).compInputDate().out()){
                            dateInput.changeError(false, "c@End date must be later than the start date.@rentasensor/vali/startdate/01")
                            false
                        }else true
                        val result4  = if( clientSensorList.all{ it.sensorComp.out()=="0" }) {
                            CompViewToast.open("c@At least one must be above zero.@rentasensor/toast/error1", type=CompViewToast.TOAST_TYPE.ERROR)
                            false
                        } else true

                        if(result1&&result2&&result3&&result4){
                            ClientUserApiRentalWp.net{
                                clientSensorList.filter{
                                    it.sensorComp.out() != "0"
                                }.forEach { sensor  ->
                                    it.sensorList += EntUserApiRentalWp.Req.Sensor().also {
                                        it.rowid = sensor.rowid
                                        it.requestQty = sensor.sensorComp.out()
                                    }
                                }
                                it.name = fullNameInput.out()
                                it.phone = phoneInput.out()
                                it.addressRowid = addressInput.out()
                                it.addr2 = address2Input.out()
                                it.startDate = dateInput.inputView(0).compInputDate().out()
                                it.endDate = dateInput.inputView(1).compInputDate().out()
                                it.message = messageInput.out()
                            }.also {
                                hashManager.goUrl(RouterKey.RENTAL_LIST)
                                CompViewToast.open("c@Rental request was sent.@rentasensor/toast/confirm", type=CompViewToast.TOAST_TYPE.SUCCESS)
                            }
                        }
                    }
                }
            }
        }
    })

    rootView.addEntityHook(EntInit::class, object:eEntityHook<HTMLElement, EntInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntInit){
            view.clearCompValue(K.addressInput, K.address2Input, K.dateInput, K.messageInput)
            clientSensorList.forEach {
                it.sensorComp.clear()
            }
        }
    })

}
package senscloud.common.vali

import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali
import senscloud.common.enm.EnumApiShipmentSearchType


val ValiShipmentName = eRuleVali{
    Case {
        String()
        Trim()
        MinLength(1, "c@Enter a shipment name.@shipment_add_edit/vali/name/01")
        MaxLength(32, "c@Shipment name must be under 32 characters.@shipment_add_edit/vali/name/02")
    }
}

val ValiReferenceNum = eRuleVali{
    val msg = "c@Reference number must be under 32 characters.@shipment_add_edit/vali/number/01"
    Case {
        String()
        Trim()
        MaxLength(32, msg)
    }
}

val ValiShipmentDescription = eRuleVali{
    val msg = "c@Shipment description must be under 1,024 characters.@shipment_add_edit/vali/description/01"
    Case {
        String()
        Trim()
        MaxLength(1024, msg)
    }
}

val ValiDeliveredShipmentSearchType = eRuleVali{
    Case {
        Rule("delivered shipment 검색용 Type을 잘못 입력했습니다.") {
            EnumApiShipmentSearchType.deliveredList().firstOrNull{it.name=="$it"}?.name ?: eRuleSet.FALSE
        }
    }
}

val ValiOngoingShipmentSearchType = eRuleVali{
    Case {
        Rule("ongoing shipment 검색용 Type을 잘못 입력했습니다.") {
            EnumApiShipmentSearchType.ongoingList().firstOrNull{it.name=="$it"}?.name ?: eRuleSet.FALSE
        }
    }
}

val ValiShipmentSearchText = eRuleVali{
    Case {
        String()
        Trim()
        MinLength(1, "c@Enter a keyword.@ongoing/vali/searchtext/01")
        MaxLength(100, "c@Keyword must be under 100 characters.@vali/searchtext/02")
    }
}
package member.subAdd

import senscloud.common.domPage.PageBase
import senscloud.user.app.ClientUserApiMemberSubW
import senscloud.user.entity.member.EntClientMemberSubW
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement

class SubAddP(router:eRouter<HTMLElement>): PageBase(::SubAddView, router){
    override suspend fun net(data:eRouterData){
        ClientUserApiMemberSubW.net()?.also {
            view?.entity(EntClientMemberSubW(it))
        }
    }
}
package comp

import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

/* ********************* CompCustConfirm 사용법 *********************

//language=html
val exampleViewFactory = Factory.html("""
        <div data-view="A"></div>
""")

        val custConfirm = rootView.compCustConfirm("서브키 값"){
            it.wrapperClass = ""                                            // wrapper 클래스 설정
            it.cancelBtnLabel = "취소"                                       // cancel 버튼 라벨 설정
            it.submitBtnLabel = "확인"                                       // submit 버튼 라벨 설정
        }

        custConfirm.open()                                                  // displayBlock
        custConfirm.setContents(exampleViewFactory){                        // 컨텐츠에 들어갈 뷰와 내용 설정
            it.sub("A"){ view->
                view.html = "컨텐츠 내용"
            }
        }
        custConfirm.setSubmit { eLaunch { console.log("submit!") } }        // submit 버튼 누를때 동작 설정
        //custConfirm.close()                                               // displayNone
*/

suspend fun eView<HTMLElement>.compCustConfirmSet(subKey:Any, block:(CompCustConfirm)->Unit):CompCustConfirm{
    return CompCustConfirm(sub(subKey, CompCustConfirm.factory, null), block)
}
class CompCustConfirm private constructor(private val view:eView<HTMLElement>):Comp{
    companion object{
        const val WRAPPER = "confirmWrapper"
        const val CONFIRM_WRAPPER = "custConfirmWrapper"
        const val CONTENTS = "contents"
        const val BTN_WRAPPER = "btnWrapper"
        const val CANCEL = "cancelBtn"
        const val SUBMIT = "submitBtn"
        internal val factory = Factory.html("""
<div data-view="$WRAPPER">
    <div data-view="$CONFIRM_WRAPPER" class="popup">
        <div data-view="$CONTENTS"></div>
        <div data-view="$BTN_WRAPPER" class="btn-wrapper">
            <div data-view="$CANCEL" class="btn border"></div>
            <div data-view="$SUBMIT"></div>
        </div>
    </div>
</div>""")
        suspend operator fun invoke(block:CompCustConfirm.()->Unit):CompCustConfirm{
            return invoke(eView(factory), block)
        }
        suspend operator fun invoke(view:eView<HTMLElement>, block:(CompCustConfirm)->Unit):CompCustConfirm{
            val comp = CompCustConfirm(view)
            block(comp)
            view.sub(WRAPPER){
                it.displayNone()
                it.className = comp.wrapperClass
            }
            view.sub(CONFIRM_WRAPPER)
            view.sub(CONTENTS)
            view.sub(BTN_WRAPPER)
            view.sub(CANCEL){
                if(!comp.cancelBtn) { it.displayNone() }
                it.html = comp.cancelBtnLabel
                it.click = {_,_-> eLaunch{ comp.close() } }
            }
            view.sub(SUBMIT){
                if(comp.submitBtnLabel=="") it.displayNone()
                it.className = comp.submitBtnClass
                it.html = comp.submitBtnLabel
            }
            comp.baseProp(view)
            return comp
        }
    }
    var wrapperClass = ""
    var cancelBtnLabel = ""
    var submitBtnLabel = ""
    var cancelBtn = true
    var submitBtnClass = "btn margin-left8"
    
    suspend fun setContents(factory:suspend ()->HTMLElement, block:suspend (eView<HTMLElement>)->Unit){
        view.sub(CONTENTS){ contentsView ->
            contentsView.setClearList{ list ->
                list += eView(factory){
                    block(it)
                }
            }
        }
    }
    suspend fun clear() {
        view.sub(CONTENTS).clear()
    }
    suspend fun setSubmit(block:()->Unit){
        view.sub(SUBMIT).click = {_,_-> block() }
    }
    suspend fun open(){
        view.sub(WRAPPER).displayBlock()
    }
    suspend fun close(){
        view.sub(WRAPPER).displayNone()
    }
    suspend fun displayNoneCancel() {
        view.sub(CANCEL).displayNone()
    }
}
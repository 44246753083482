package comp

import Factory
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
import org.w3c.files.File
import view.CompViewUpload

/*
* CompProfileImageList 사용법
*
CompProfileImageList(rootView, K.compProfile) {
    it.title = "Profile image"
    it.message = "Or select an avatar:"
    it.btnLabel = "Upload"
    it.profileList = listOf("", "", "", "") //프로필 url 리스트
    it.uploadedImage = "" //이미 업로드된 프로필 url
}
*
엔티티 훅에서 profileList와 uploadImage 값 넣는 방법
- setProfileData(profileUrl, profileUrlList)를 호출한다
*
엔티티 훅에서 upload 후 액션을 설정하는 방법
-  setAfterUploaded{
        eLaunch {
            ClientUserApiImageWp.uploadNet(it)?.also {
                upload.setProfileData(it.imageUrl, entity.profileUrlList)
                editForm.profileUrl = it.imageUrl
            }
        }
    }
* */
suspend fun eView<HTMLElement>.compProfileImageListSet(subKey:Any, block:(CompProfileImageList)->Unit):CompProfileImageList{
    return CompProfileImageList(this, subKey, block)
}
class CompProfileImageList:Comp{
    companion object{
        private class Profile(var profileUrl:String, var profileUrlList:List<String>)
        const val COMP_NAME = "profileImageComp"
        const val WRAPPER = "${COMP_NAME}_Wrapper"
        const val TITLE = "${COMP_NAME}_Title"
        const val UPLOADED_IMAGE = "${COMP_NAME}_UploadedImage"
        const val UPLOAD_BTN = "${COMP_NAME}_UploadBtn"
        const val MESSAGE ="${COMP_NAME}_Message"
        const val PROFILE_LIST = "${COMP_NAME}_ProfileList"
        const val IMAGE = "${COMP_NAME}_Image"
        const val IMAGE_CHECK = "${COMP_NAME}_ImageCheck"
        //language=html
        val factory = Factory.html("""
        <div class="profile-image-list">
            <div data-view="$TITLE" class="input-block-label"></div>
            <div data-view="$UPLOADED_IMAGE" class="image"></div>
            <b data-view="$UPLOAD_BTN"></b>
            <div data-view="$MESSAGE" class="message"></div>
            <ul data-view="$PROFILE_LIST" class="account-profile" style="margin-bottom:50px"></ul>
        </div>
""")
        private val profileImageT = Factory.html("""<li data-view="$IMAGE"><div data-view="$IMAGE_CHECK"></div></li>""")

        suspend operator fun invoke(rootView:eView<HTMLElement>, subKey:Any, block:(CompProfileImageList)->Unit):CompProfileImageList{
            val comp = CompProfileImageList()
            block(comp)
            val profileData = Profile(comp.uploadedImage, comp.profileList)
            comp.target = rootView.sub(subKey, factory){ view ->
                view.sub(TITLE).html = comp.title
                CompViewUpload.comp(view, UPLOAD_BTN, comp.btnLabel) { file ->
                    comp.afterUploaded?.invoke(file)
                }
                view.sub(MESSAGE).html = comp.message
                comp.setProfileList(view, profileData)
                comp.baseProp(rootView.sub(subKey))
            }
            return comp
        }
    }

    lateinit var target: eView<HTMLElement>
    var title = ""
    var message = ""
    var profileList = listOf<String>()
    var uploadedImage = ""
    var btnLabel = ""
    private var afterUploaded:((File)->Unit)?= null
    private var clickItem:((String)->Unit)?= null

    suspend fun setProfileData(url:String, urlList:List<String>) = setProfileList(target, Profile(url, urlList))
    fun setAfterUploaded(block: ((File)->Unit)?=null) { afterUploaded = block }
    fun setClickItem(block: ((String)->Unit)?=null) { clickItem = block }

    private suspend fun setProfileList(view:eView<HTMLElement>, profileData: Profile) {
        view.sub(UPLOADED_IMAGE).lazyBackgroundImage = "" to profileData.profileUrl
        view.sub(PROFILE_LIST).setClearList { listView ->
            profileData.profileUrlList.forEach { url ->
                listView += eView(profileImageT) { pView ->
                    pView.sub(IMAGE_CHECK) {
                        it.className = if(profileData.profileUrl == url) "profile-check checked" else "profile-check"
                    }
                    pView.sub(IMAGE) {
                        it.className = if(profileData.profileUrl == url) "profile selected" else "profile"
                        it.lazyBackgroundImage = "" to url
                        it.click = {_,_ ->
                            profileData.profileUrl = url
                            eLaunch {
                                clickItem?.invoke(url)
                                setProfileList(view, profileData)
                            }
                        }
                    }
                }
            }
        }
    }
}
package senscloud.common.vali

import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali

val ValiMemberUserName = eRuleVali{
    val rUserId = """^[a-z0-9]+$""".toRegex()
    val msg1 = "c@Username must be between 4~32 characters.@signup/vali/01"
    val msg2 = "c@Only letters and numbers are allowed.@signup/vali/02"
    Case {
        Rule{"$it".trim()}
        MinLength(1, msg1)
        MinLength(4, msg1)
        MaxLength(32, msg1)
        Rule(msg2){
            if(it is String && rUserId.matches(it)) it else eRuleSet.FALSE
        }
    }
}

val ValiMemberLoginUserName = eRuleVali{
    val rUserId = """^[a-z0-9]+$""".toRegex()
    val msg = "c@Enter your username.@login/vali/01"
    Case {
        Rule{"$it".trim()}
        MinLength(1, msg)
        Rule(msg){
            if(it is String && rUserId.matches(it)) it else eRuleSet.FALSE
        }
    }
}

val ValiMemberPw = eRuleVali{
    val rNumberSpecial = """^((?=.*\d)(?=.*\W)).+$""".toRegex()
    val rEnglishNumber = """^((?=.*[a-zA-z])(?=.*\d)).+$""".toRegex()
    val rEnglishSpecial = """^((?=.*[a-zA-z])(?=.*\W)).+$""".toRegex()
    val rAllSpecial = """([\W])""".toRegex()
    val rSpecial = """([!@#$%^&*()_+~=-])""".toRegex()
    val msg1 = "c@Password must be between 8~32 characters.@common/vali/pw/01"
    val msg2 = "c@Try a mix of letters, numbers and special characters.@common/vali/pw/02"
    Case{
        String()
        Trim()
        MinLength(1, msg1)
        MinLength(8, msg1)
        MaxLength(32, msg1)
        Rule(msg2){ if(it is String && (rNumberSpecial.find(it)!=null || rEnglishNumber.find(it)!=null || rEnglishSpecial.find(it)!=null)) it else eRuleSet.FALSE }
        Rule("c@common/vali/pw/03"){ if(it !is String || (rAllSpecial.findAll(it).count() != rSpecial.findAll(it).count())) eRuleSet.FALSE else it }
    }
}

val ValiMemberLoginPw = eRuleVali{
    val msg = "c@Enter your password.@login/vali/02"
    Case{
        String()
        Trim()
        MinLength(1, msg)
    }
}

val ValiMemberFullName = eRuleVali{
    Case {
        String()
        Trim()
        MinLength(1, "c@Enter your full name.@common/vali/name/01")
        MaxLength(32, "c@Full name must be under 32 characters.@common/vali/name/02")
    }
}

val ValiMemberPhone = eRuleVali{
    Case{
        String()
        Trim()
        Rule{ "$it".replace("-","").replace(" ", "") }
        MinLength(1, "c@Enter your phone number.@common/vali/phone/01")
        Rule("c@Invalid phone number format.@common/vali/phone/02"){ _valiPhoneCheck(it) }
    }
}

val ValiMemberProfileUrl = eRuleVali{
    Case {
        String()
        Rule("Invalid image file."){
            _valiImageUrlCheck(it)
        }
    }
}

val ValiMemberCurrPw = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "c@Enter your password.@pwchange/vali/currentpw/01")
    }
}

val ValiMemberNewPw = eRuleVali{
    val rNumberSpecial = """^((?=.*\d)(?=.*\W)).+$""".toRegex()
    val rEnglishNumber = """^((?=.*[a-zA-z])(?=.*\d)).+$""".toRegex()
    val rEnglishSpecial = """^((?=.*[a-zA-z])(?=.*\W)).+$""".toRegex()
    val rAllSpecial = """([\W])""".toRegex()
    val rSpecial = """([!@#$%^&*()_+~=-])""".toRegex()
    val msg = "c@Password must be between 8~32 characters.@pwchange/vali/newpw/01"
    Case{
        String()
        Trim()
        MinLength(1, msg)
        MinLength(8, msg)
        MaxLength(32, msg)
        Rule("c@Try a mix of letters, numbers and special characters.@pwchange/vali/newpw/02"){ if(it is String && (rNumberSpecial.find(it)!=null || rEnglishNumber.find(it)!=null || rEnglishSpecial.find(it)!=null)) it else eRuleSet.FALSE }
        Rule("c@Available special characters@pwchange/vali/newpw/03"){ if(it !is String || (rAllSpecial.findAll(it).count() != rSpecial.findAll(it).count())) eRuleSet.FALSE else it }
    }
}
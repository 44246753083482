package senscloud.common.entity.api.user.mysensors

import ein2b.core.entity.eEntity
import senscloud.common.enm.EnumApiAccessAllow
import senscloud.common.entity.api.user.common.EntTempInfo
import senscloud.common.entity.api.user.common.EntUserApi
import senscloud.common.entity.api.user.common.EntUserApiPageMeta
import senscloud.common.entity.api.user.common.USER_API_SENSOR_PATH
import senscloud.common.vali.ValiPage
import senscloud.common.vali.ValiRowId

//see all data
object EntUserApiMySensorsAllData:EntUserApi<EntUserApiMySensorsAllData.Req, EntUserApiMySensorsAllData.Res>(::Req, ::Res) {
    const val PATH = "$USER_API_SENSOR_PATH/alldata"
    const val PATH_JSON = "$PATH/json"

    class Req : eEntity() {
        var rowid:String by string(::rowid) {validator(ValiRowId)}
        var page:String by string(::page){
            default("1", false)
            validator(ValiPage)
        }
    }
    class Res : eEntity(true) {
        class Meta : EntUserApiPageMeta() {
            var rowid:String by string(::rowid)
        }
        class Record : eEntity(true) {
            var rowid:String by string(::rowid)
            var title:String by string(::title)
            var minTemp:String by string(::minTemp)
            var maxTemp:String by string(::maxTemp)
            var lastTemp:String by string(::lastTemp)
            var startDate:String by string(::startDate)
            var endDate:String by string(::endDate)
            var tempInfo: EntTempInfo by entity(::tempInfo, ::EntTempInfo)
        }
        var title:String by string(::title)
        var recordList: MutableList<Record> by entityList(::recordList, ::Record) { default(mutableListOf(), false) }
        val meta: Meta by entity(::meta, ::Meta) { default(Meta(), false) }
        var minTemp:String by string(::minTemp)
        var maxTemp:String by string(::maxTemp)
    }

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val rsckeys = "recordlist"
    init {
        //isJSON = true
    }

}


//see all data detail
object EntUserApiMySensorsAllDataV:EntUserApi<EntUserApiMySensorsAllDataV.Req, EntUserApiMySensorsAllDataV.Res>(::Req, ::Res){
    const val PATH = "$USER_API_SENSOR_PATH/alldata/v"
    const val PATH_JSON = "$PATH/json"

    open class Req:eEntity() {
        var rowid:String by string(::rowid) {validator(ValiRowId)}
        //앱에서 비인증 로그인때 사용
        var guid:String by string(::guid) { default("", false) }
        //해당 페이지가 쓸거는 아니고, 다시 뒤로가기 했을 때 넘겨줄 값
        var page:String by string(::page){
            default("1", false)
            validator(ValiPage)
        }
    }
    open class Res:eEntity(true) {
        class Record : eEntity(true) {
            var startDate:String by string(::startDate){default("",false)}
            var endDate:String by string(::endDate){default("",false)}
            var minTemp:String by string(::minTemp){default("",false)}
            var maxTemp:String by string(::maxTemp){default("",false)}
            var lastTemp:String by string(::lastTemp){default("",false)}
            var tempInfo: EntTempInfo by entity(::tempInfo, ::EntTempInfo) { default(EntTempInfo(), false) }

            //앱웹에서 필요
            var regDate:String by string(::regDate){default("",false)} //2022-05-03(Tue) 10:00
            var duration:String by string(::duration){default("",false)} //2 hours
            var isSuccess:Boolean by bool(::isSuccess)

            //PDF 에서 필요
            var catMinTemp:String by string(::catMinTemp){default("",false)} //2°C
            var catMaxTemp:String by string(::catMaxTemp){default("",false)} //8°C
        }
        //Req에 있는거 그대로 돌려줌.
        var guid:String by string(::guid){ default("", false) }
        // Req에 있는 page를 그냥 돌려주기만 한다.
        var page:String by string(::page)
        var sensorCode:String by string(::sensorCode)
        var sensorSessionRowid:String by string(::sensorSessionRowid)
        val record: Record by entity(::record, ::Record) { default(Record(), false) }
    }

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val rsckeys = "recorddetail"
}
object EntUserApiMySensorsAllDataAnonymousV:EntUserApi<EntUserApiMySensorsAllDataV.Req, EntUserApiMySensorsAllDataV.Res>(EntUserApiMySensorsAllDataV::Req, EntUserApiMySensorsAllDataV::Res){
    const val PATH = "$USER_API_SENSOR_PATH/alldata/anonymous/v"
    const val PATH_JSON = "$PATH/json"

    override val midIgnore = true
    override val accessAllow get() = EnumApiAccessAllow.LOGOUT
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val rsckeys = "appweb"
}


// excel download
object EntUserApiMySensorsAllDataExcel:EntUserApi<EntUserApiMySensorsAllDataExcel.Req, EntUserApiMySensorsAllDataExcel.Res>(::Req, ::Res){
    const val PATH = "$USER_API_SENSOR_PATH/alldata/excel"
    const val PATH_JSON = "$PATH/json"

    class Req:eEntity(){
        var rowid:String by string(::rowid){ validator(ValiRowId) }
        var guid:String by string(::guid){ default("", false) }
    }
    class Res:eEntity(){
        var fileUrl:String by string(::fileUrl)
    }

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
}
object EntUserApiMySensorsAllDataExcelAnonymous:EntUserApi<EntUserApiMySensorsAllDataExcel.Req, EntUserApiMySensorsAllDataExcel.Res>(EntUserApiMySensorsAllDataExcel::Req, EntUserApiMySensorsAllDataExcel::Res){
    const val PATH = "$USER_API_SENSOR_PATH/alldata/excel/anonymous"
    const val PATH_JSON = "$PATH/json"
    override val accessAllow get() = EnumApiAccessAllow.LOGOUT
    override val path = PATH
    override val jsonPath = PATH_JSON
}
// excel pdf
object EntUserApiMySensorsAllDataPdf:EntUserApi<EntUserApiMySensorsAllDataPdf.Req, EntUserApiMySensorsAllDataPdf.Res>(::Req, ::Res){
    const val PATH = "$USER_API_SENSOR_PATH/alldata/pdf"
    const val PATH_JSON = "$PATH/json"

    class Req:eEntity(){
        var rowid:String by string(::rowid){ validator(ValiRowId) }
        var svg:String by string(::svg){ default("", false) }
        var guid:String by string(::guid){ default("", false) }
    }
    class Res:eEntity(){
        var fileUrl:String by string(::fileUrl)
    }

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
}
object EntUserApiMySensorsAllDataPdfAnonymous:EntUserApi<EntUserApiMySensorsAllDataPdf.Req, EntUserApiMySensorsAllDataPdf.Res>(EntUserApiMySensorsAllDataPdf::Req, EntUserApiMySensorsAllDataPdf::Res){
    const val PATH = "$USER_API_SENSOR_PATH/alldata/pdf/anonymous"
    const val PATH_JSON = "$PATH/json"

    override val midIgnore = true
    override val accessAllow get() = EnumApiAccessAllow.LOGOUT
    override val path = PATH
    override val jsonPath = PATH_JSON
}

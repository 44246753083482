package ein2b.core.cdata

import ein2b.core.entity.eEntity
import ein2b.core.log.log

/*
{
    "cat":{//optional, current category value
        "lang":"en"
    },
    "default":{//optional, default category value
        "sex":"man",
        "lang":"jp"
    },
    "data":{
        "title":{
            "cat":"lang",
            "default":"ko",
            "data":{
                "ko":{
                    "value":"hello"
                },
                "en":{
                    "cat":"sex",
                    "data":{
                        "man":{
                            "value":"man"
                        },
                        "woman":{
                            "cat":"age",
                            "data":{
                                "old":{
                                    "value":"old woman"
                                },
                                "young":{
                                    "value":"young woman"
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
*/
class eCdataLoader: eEntity(){
    companion object{
        internal val observers = hashMapOf<String, MutableSet<(String, Any)->Unit>>()
        fun removeObserver(key:String, target:(String, Any)->Unit){
            observers[key]?.let{
                it.remove(target)
            }
        }
    }
    var data:MutableMap<String, eCdata.Cat> by entityMap(::data, eCdata::Cat){default(hashMapOf())}
    var cat:MutableMap<String, String> by stringMap(::cat){default(hashMapOf())}
    var default:MutableMap<String, String> by stringMap(::default){default(hashMapOf())}
    fun load(){
        if(cat.isNotEmpty()) eCdataCat.data += cat
        if(default.isNotEmpty()) eCdataCat.default += default
        if(data.isNotEmpty()) eCdata.root.data += data
        for((k, v) in observers){
            val data = eCdata.get<Any>(k)
            if(data != null) v.forEach { it(k, data) }
        }
    }
}
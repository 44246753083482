package member.changePw

import senscloud.common.domPage.PageBase
import senscloud.user.app.ClientUserApiMemberPwE
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement

class ChangePwP(router:eRouter<HTMLElement>): PageBase(::ChangePwView, router){
    override suspend fun net(data:eRouterData){
        ClientUserApiMemberPwE.net()?.also {
            view?.entity(it)
        }
    }
}
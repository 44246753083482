package rental.rentallist

import Factory
import app.hashManager
import senscloud.common.pageView.rentalList.rentalListView
import senscloud.user.app.RouterKey

private val factory = Factory.htmlUrl("rental/rentallist/rentalList")
private val rentalFactory = Factory.htmlUrl("rental/rentallist/rentalT")
suspend fun RentalListView() = rentalListView(factory, rentalFactory){
    hashManager.goUrl(RouterKey.RENTAL_DETAIL, "rentalRowid" to it)
}
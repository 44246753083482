package comp

import Factory
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

// setList시 factory는 <div>로 줄 것

class CompViewList:Comp {
    companion object{
        //language=html
        private val DATA = "CompViewList_data"
        private val ITEM = "CompViewList_data_item"
        private val factory:(String)->suspend ()->HTMLElement = {Factory.html("""<ul class="$it"></ul>""")}
        private val itemFactory:(String)->suspend ()->HTMLElement = {Factory.html("""<li data-view="$ITEM" class="$it"><b data-view="$DATA"></b></li>""")}

        enum class EnumCompViewListType(val ulName:String, val liName:String) {
            LIST_CARD("list-card", "list-card-item"),
            LIST_BORDER("list-card", "view-border"),
            LIST_NONE("list-card", "")
        }

        suspend operator fun invoke(root: eView<HTMLElement>, subKey:Any, type:EnumCompViewListType, block:((CompViewList)->Unit)?=null):CompViewList{
            val comp = CompViewList()
            block?.invoke(comp)
            comp.type = type
            comp.target = root.sub(subKey, factory("${type.ulName} ${comp.wrapperClass}")){}
            comp.baseProp(root.sub(subKey))
            return comp
        }
    }
    lateinit var target: eView<HTMLElement>
    var wrapperClass = ""
    private lateinit var type:EnumCompViewListType

    suspend fun<T> setList(list:List<T>, factory:suspend ()->HTMLElement, block:suspend (index:Int, data:T, view:eView<HTMLElement>, li:eView<HTMLElement>)->Unit) =
            setList(list, {_,_->factory}, block)

    suspend fun<T> setList(list:List<T>, factory:suspend ()->HTMLElement, block:suspend (data:T, view:eView<HTMLElement>)->Unit) =
        setList(list, {_,_->factory}, {_, d, v, _ ->block(d, v)})

    suspend fun<T> setList(list:List<T>, factory:suspend ()->HTMLElement, block:suspend (data:T, view:eView<HTMLElement>, li:eView<HTMLElement>)->Unit) =
        setList(list, {_,_->factory}, {_, d, v, l ->block(d, v, l)})

    suspend fun<T> setList(list:List<T>, factory:(index:Int, data:T)->suspend ()->HTMLElement, block:suspend (data:T, view:eView<HTMLElement>)->Unit) =
        setList(list, factory) { _, d, v, _ -> block(d, v) }

    suspend fun<T> setList(list:List<T>, factory:(index:Int, data:T)->suspend ()->HTMLElement, block:suspend (index:Int, data:T, view:eView<HTMLElement>, li:eView<HTMLElement>)->Unit) {
        target.setClearList { lv ->
            list.forEachIndexed { index, data ->
                lv += eView(itemFactory(type.liName)) { v ->
                    v.sub(DATA, factory(index, data)) {
                        block(index, data, it, v.sub(ITEM))
                    }
                }
            }
        }
    }
}
package comp

import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

/*  ********************* CompFilterList 사용법 *********************
        val compFilter = rootView.compFilterButton("filterList1") {                               //subKey로 컴포넌트 잡고
            it.wrapperClass = "filter-wrapper"                                                    //wrapperClass 설정가능
            it.allBtnLabel = "모든필터 삭제 X"                                                      //버튼 라벨 설정
            it.closeEvent = { str ->                                                              //필터 클로즈 버튼 눌렀을때 이벤트 처리
                console.log("클로즈",str)
            }
        }

        var item =  mutableMapOf<String,String>("필터1" to "필터1입니다","필터2" to "필터2입니다")     //임의의 맵을 설정해 setList에 들어갈 필터들 설정
        compFilter.setList(item)

        // 필터리스트는  wrapperClass만 설정할 수 있다 그밑에있는 구조들의 css를 만지고 싶다면 style에 이걸참고해서 조작하자

        .filter-wrapper{ margin:20px 0 }                                                                                                                            //it.wrapperClass와 동일
        .filter-wrapper .all-btn{ margin:5px 0;padding:5px;border:1px solid #cdcdcd;background-color:#fff;border-radius:4px;cursor:pointer }                        //전체 삭제하는 버튼
        .filter-list{ display:flex;margin:0 -5px;align-items:center;flex-wrap:wrap }                                                                                //필터리스트
        .filter-list .filter{ margin:5px;align-items:center;display:flex;padding:5px;border:1px solid #cdcdcd;background-color:#f2f2f2;border-radius:4px }          //필터들
        .filter-list .filter-label{ font-size: 14px;}                                                                                                               //필터라벨
        .filter-list .close-btn{ background-image:url('image/ic-close.svg');background-size:14px;height:14px;width:14px;margin-left:5px;cursor:pointer }            //닫기버튼 (주로 x모양 이미지가 들어간다)
*/
suspend fun <T> eView<HTMLElement>.compFilterListSet(subKey:Any, block:(CompFilterList<T>)->Unit):CompFilterList<T>{
    return CompFilterList(sub(subKey, CompFilterList.factory, null), block)
}
class CompFilterList <T> private constructor(private val view:eView<HTMLElement>):Comp{
    companion object{
        const val WRAPPER = "filterWrapper"
        const val LIST = "filterList"
        const val TITLE = "filterTitle"
        const val LABEL = "filterLabel"
        const val CLOSE = "closeBtn"
        const val ALL = "allBtn"
        const val ALL_LABEL = "allBtnLabel"
        internal val factory = Factory.html("""
<div data-view="$WRAPPER">
    <ul data-view="$LIST" class="filter-list"></ul>
    <div data-view="$ALL" class="link-btn">
        <div class="link-btn-icon ic-reset"></div>
        <span data-view="$ALL_LABEL" class="link-btn-label middle"></span>
    </div>
</div>""")
        private val filterFactory = Factory.html("""
<li data-view="" class="filter-item">
    <span data-view="$TITLE" class="middle"></span>
    <span data-view="$LABEL" class="middle" style="font-weight:bold"></span>
    <div data-view="$CLOSE" class="filter-item-icon ic-delete" style="cursor:pointer"></div>
</li>""")
        suspend operator fun <T> invoke(block:CompFilterList<T>.()->Unit):CompFilterList<T>{
            return invoke(eView(factory), block)
        }
        suspend operator fun <T> invoke(view:eView<HTMLElement>, block:(CompFilterList<T>)->Unit):CompFilterList<T>{
            val comp = CompFilterList<T>(view)
            block(comp)
            view.sub(WRAPPER).className = comp.wrapperClass
            view.sub(LIST)
            view.sub(ALL){
                it.displayNone()
                it.click = {_,_ -> eLaunch{ comp.closeEvent?.invoke(null) } }
            }
            view.sub(ALL_LABEL){ it.html = comp.allBtnLabel }
            comp.baseProp(view)
            return comp
        }
    }
    var allBtnLabel = "모든 필터 삭제"
    var wrapperClass = "search-filter"
    var closeEvent:(suspend (T?)->Unit)? = null

    suspend fun setList(items:MutableMap<T, Pair<String,String>>){
        view.sub(LIST){ viewList ->
            viewList.setClearList{ list ->
                items.forEach{ (k, s)->
                    list += eView(filterFactory){
                        it.value = k
                        it.sub(TITLE){ title -> title.html = s.first }
                        it.sub(LABEL){ label -> label.html = s.second }
                        it.sub(CLOSE){ close ->
                            close.click = {_,_-> eLaunch{ closeEvent?.invoke(k) } }
                        }
                    }
                }
            }
        }
        view.sub(ALL){ if(items.isNotEmpty()) it.displayInlineBlock() else it.displayNone() }
    }
}
package shipment.create

import CompLabelInputSection
import Factory
import GoogleMapModal
import app.hashManager
import comp.CompPageTitle
import comp.input.CompInputData
import comp.input.CompInputDate
import comp.input.CompInputTime
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
import prop.*
import senscloud.common.app.App
import senscloud.common.entity.EntInit
import senscloud.common.entity.api.user.shipment.EntUserApiShipmentW
import senscloud.common.vali.*
import senscloud.user.app.ClientUserApiAddressWp
import senscloud.user.app.ClientUserApiShipmentWp
import senscloud.user.app.RouterKey
import senscloud.user.app.RouterKey.SHIPMENT_ONGOING
import senscloud.user.entity.shipping.EntClientShipmentWSensorList
import senscloud.user.entity.shipping.Sensor
import view.CompViewToast

private val factory = Factory.htmlUrl("shipment/create/createShipment")
private val sensorFactory = Factory.htmlUrl("shipment/create/sensorT")
private enum class K{
    BASICINFO, ROUTE, SENSORS,

    shipmentName, number, description, from, date, time, to,

    arrivalAddBtn, arrivalArea, arrivalDate, arrivalTime, arrivalCloseBtn,

    addSensorBtn, sensorList, sensor_sensor, sensor_closeBtn,

    submitBtn
    ;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

suspend fun CreateShipmentView() = eView(factory){ rootView->

    val sensorListEnt = EntClientShipmentWSensorList()
    var isArrivalTime = false

    CompPageTitle(rootView){
        it.title = "c@Create a shipment@shipment_add_edit/title/createshipment"
    }

    App.subHtmlFromLabel(rootView,
        K.BASICINFO to "c@Basic info@shipment_add_edit/title/basicinfo",
        K.ROUTE to "c@Route@shipment_add_edit/title/route",
        K.SENSORS to "c@Sensors@shipment_add_edit/title/sensors"
    )

    rootView.compLabelInputSectionTextSet(K.shipmentName, "c@Shipment name@shipment_add_edit/label/name", ValiShipmentName)

    rootView.compLabelInputSectionTextSet(K.number, "c@Reference number (optional)@shipment_add_edit/label/number", ValiReferenceNum)

    rootView.compLabelInputSectionTextSet(K.description, "c@Shipment description (optional)@shipment_add_edit/label/description", ValiShipmentDescription)

    CompLabelInputSection(rootView, K.from, "c@From@shipment_add_edit/label/from",
        CompInputData<String> {
            it.mustSelect = true
            it.addOn = listOf(CompInputData.AddOnType.ADD_BUTTON, CompInputData.AddOnType.EMPTY)
            it.addMsg = "c@Search locations on google maps@shipment_add_edit/map/addmsg"
            it.emptyMsg = "c@Couldn't find in your address book.@shipment_add_edit/map/emptymsg"
            it.addEvent = {
                eLaunch {
                    GoogleMapModal.open { md ->
                        ClientUserApiAddressWp.net{ req ->
                            req.name = md.name
                            req.lat = "${md.lat}"
                            req.lng = "${md.lng}"
                            req.address = md.addr
                        }?.also { entity ->
                            if(!entity.isExist) {
                                rootView.sub(K.from).compLabelInputSectionDataString {
                                    it.input.dataList = entity.addressList.map { a ->
                                        CompInputData.InputData(a.rowid, "${a.name} (${a.address})", a.name)
                                    }.toMutableList()
                                    it.input.setInputValueSelect(entity.addedAddressRowid)
                                }
                            }
                            return@open !entity.isExist
                        }
                        return@open true
                    }
                }
            }
            it.wrapperClass = "input-data flex-grow-1"
            it.vali = ValiAddress
        }
    )

    CompLabelInputSection(rootView, K.date, "c@Date@shipment_add_edit/label/date",
        CompInputDate{
            it.ymdPattern = "Y-m-d"
        }
    )

    CompLabelInputSection(rootView, K.time, "c@Time@shipment_add_edit/label/time",
        CompInputTime{}
    )

    CompLabelInputSection(rootView, K.to, "c@To@shipment_add_edit/label/to",
        CompInputData<String> {
            it.mustSelect = true
            it.addOn = listOf(CompInputData.AddOnType.ADD_BUTTON, CompInputData.AddOnType.EMPTY)
            it.addMsg = "c@Search locations on google maps@shipment_add_edit/map/addmsg"
            it.emptyMsg = "c@Couldn't find in your address book.@shipment_add_edit/map/emptymsg"
            it.addEvent = {
                eLaunch {
                    GoogleMapModal.open { md ->
                        ClientUserApiAddressWp.net{ req ->
                            req.name = md.name
                            req.lat = "${md.lat}"
                            req.lng = "${md.lng}"
                            req.address = md.addr
                        }?.also { entity ->
                            if(!entity.isExist) {
                                rootView.sub(K.to).compLabelInputSectionDataString {
                                    it.input.dataList = entity.addressList.map { a ->
                                        CompInputData.InputData(a.rowid, "${a.name} (${a.address})", a.name)
                                    }.toMutableList()
                                    it.input.setInputValueSelect(entity.addedAddressRowid)
                                }
                            }
                            return@open !entity.isExist
                        }
                        return@open true
                    }
                }
            }
            it.wrapperClass = "input-data flex-grow-1"
            it.vali = ValiAddress
        }
    )

    rootView.sub(K.arrivalAddBtn) {
        it.html = "c@+ Add arrival time@shipment_add_edit/link/addarrival"
        it.click = {_, _ ->
            eLaunch {
                isArrivalTime = true
                rootView.sub(K.arrivalAddBtn).displayNone()
                rootView.sub(K.arrivalArea).displayFlex()
            }
        }
    }

    rootView.sub(K.arrivalArea){ it.displayNone() }

    CompLabelInputSection(rootView, K.arrivalDate, "c@Date@shipment_add_edit/label/date",
        CompInputDate{
            it.ymdPattern = "Y-m-d"
        }
    )

    CompLabelInputSection(rootView, K.arrivalTime, "c@Time@shipment_add_edit/label/time",
        CompInputTime{}
    )

    rootView.sub(K.arrivalCloseBtn) {
        it.click = {_, _ ->
            eLaunch {
                isArrivalTime = false
                rootView.sub(K.arrivalAddBtn).displayBlock()
                rootView.sub(K.arrivalArea).displayNone()
            }
        }
    }

    rootView.sub(K.addSensorBtn) {
        it.click = {_, _ ->
            eLaunch {
                sensorListEnt.sensorList += ""
                rootView.entity(sensorListEnt)
            }
        }
    }

    rootView.sub(K.sensorList)

    rootView.sub(K.submitBtn) {
        it.html = "c@Create a shipment@shipment_add_edit/button/submit/01"
    }


    rootView.addEntityHook(EntUserApiShipmentW.Res::class, object: eEntityHook<HTMLElement, EntUserApiShipmentW.Res>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntUserApiShipmentW.Res) {
            view.sub(K.from).compLabelInputSectionDataString {
                it.input.dataList = entity.addressList.map { a ->
                    CompInputData.InputData(a.rowid, "${a.name} (${a.address})", a.name)
                }.toMutableList()
            }
            view.sub(K.to).compLabelInputSectionDataString {
                it.input.dataList = entity.addressList.map { a ->
                    CompInputData.InputData(a.rowid, "${a.name} (${a.address})", a.name)
                }.toMutableList()
            }
            view.entity(
                sensorListEnt.also {
                    it.sensorDataList = entity.sensorList.map { from ->
                        Sensor().also { to ->
                            to.code = from.code
                            to.tempRange = "${from.minTemp} ~ ${from.maxTemp}"
                        }
                    }.toMutableList()
                }
            )
            view.sub(K.submitBtn).click = {_, _ ->
                eLaunch {
                    val name = view.sub(K.shipmentName).compLabelInputSectionText()
                    val refNum = view.sub(K.number).compLabelInputSectionText()
                    val description = view.sub(K.description).compLabelInputSectionText()
                    val from = view.sub(K.from).compLabelInputSectionDataString()
                    val date = view.sub(K.date).compLabelInputSectionDate()
                    val time = view.sub(K.time).compLabelInputSectionTime()
                    val to = view.sub(K.to).compLabelInputSectionDataString()
                    val arrivalDate = view.sub(K.arrivalDate).compLabelInputSectionDate()
                    val arrivalTime = view.sub(K.arrivalTime).compLabelInputSectionTime()
                    val result1 = App.checkAll(name, refNum, description, from, date, time, to)
                    val result2 = App.checkAll(*sensorListEnt.sensorCompList.toTypedArray())
                    val result3 = !isArrivalTime || App.checkAll(arrivalDate, arrivalTime)
                    val result4 = if(isArrivalTime) "${date.out()} ${time.out()}" < "${arrivalDate.out()} ${arrivalTime.out()}" else true

                    if(result1 && result2 && result3 && result4) {
                        ClientUserApiShipmentWp.net { req ->
                            req.name = name.out()
                            req.refNum = refNum.out()
                            req.description = description.out()
                            req.toAddressRowid = to.out()
                            req.fromAddressRowid = from.out()
                            req.startDate = "${date.out()} ${time.out()}"
                            req.sensorCodeList = sensorListEnt.sensorCompList.map{ it.out() }.filter { it.isNotBlank() }.toMutableList()
                            req.arrivalDate = if(isArrivalTime) "${arrivalDate.out()} ${arrivalTime.out()}" else ""
                        }?.also { res ->
                            if(res.isError) {
                                if(res.error.refNum.isNotBlank()) refNum.changeError("c@This reference number is already in use within the ongoing shipment.@shipment_add_edit/vali/number/02", false)
                                if(res.error.sensorCodeList.isNotEmpty()) {
                                    res.error.sensorCodeList.forEach {
                                        sensorListEnt.sensorCompList[sensorListEnt.sensorList.indexOf(it)].changeError("c@Couldn't find the sensor barcode.@shipment_add_edit/vali/sensor/02", false)
                                    }
                                }
                            } else {
                                CompViewToast.open("c@Shipment was created.@shipment_add_edit/toast/success/01", "success-badge", CompViewToast.TOAST_TYPE.SUCCESS)
                                hashManager.goUrl(SHIPMENT_ONGOING)
                            }
                        }
                    }

                    if(!result4) arrivalDate.changeError("c@Arrival date must be later than the departure date.@shipment_add_edit/vali/addr/02")
                }
            }
        }
    })
    rootView.addEntityHook(EntClientShipmentWSensorList::class, object: eEntityHook<HTMLElement, EntClientShipmentWSensorList>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientShipmentWSensorList) {
            rootView.sub(K.addSensorBtn){
                it.attr("v0" to entity.sensorList.size)
                it.html = "c@+ Add sensor@shipment_add_edit/link/addsensor"
            }
            sensorListEnt.sensorCompList = mutableListOf()
            rootView.sub(K.sensorList).setClearList { lv ->
                entity.sensorList.forEachIndexed { idx, s ->
                    lv += eView(sensorFactory) { v ->
                        sensorListEnt.sensorCompList += CompLabelInputSection(v, K.sensor_sensor, "c@Sensor barcode (optional)@shipment_add_edit/label/sensor",
                            CompInputData { comp ->
                                comp.wrapperClass = "input-data flex-grow-1"
                                comp.changeBlock = { str ->
                                    sensorListEnt.sensorList[idx] = str
                                }
                                comp.dataList = entity.sensorDataList.map { sd ->
                                    CompInputData.InputData(
                                        sd.code,
                                        sd.code,
                                        sd.code
                                    )
                                }.toMutableList()
                                comp.dataListClick = { str ->
                                    sensorListEnt.sensorList[idx] = str
                                }
                                comp.clearClick = {
                                    sensorListEnt.sensorList[idx] = ""
                                }
                                comp.refreshClick = {
                                    sensorListEnt.sensorList[idx] = ""
                                }
                                comp.suffix = { str ->
                                    entity.sensorDataList.find { it.code == str }?.let{
                                        "<span class='state-round-badge'>${it.tempRange}</span>"
                                    } ?: ""
                                }
                                comp.vali = ValiSensorBarcodeOrBlank
                            }
                        )
                        v.sub(K.sensor_sensor).compLabelInputSectionDataString{ comp ->
                            comp.input.dataList.find { it.value == s }?.also {
                                comp.input.setInputValue(it.title, true)
                            } ?: comp.input.setInputValue(s)
                        }
                        v.sub(K.sensor_closeBtn) {
                            if(entity.sensorList.size == 1) it.displayNone() else it.displayBlock()
                            it.click = {_, _ ->
                                if(entity.sensorList.size > 1) {
                                    eLaunch {
                                        sensorListEnt.sensorList.removeAt(idx)
                                        view.entity(sensorListEnt)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    })
    rootView.addEntityHook(EntInit::class, object:eEntityHook<HTMLElement, EntInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntInit){
            view.clearCompValue(K.shipmentName, K.number, K.description, K.from, K.date, K.time, K.to, K.arrivalDate, K.arrivalTime)
        }
    })
}
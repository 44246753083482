package member.subInfo

import Factory
import senscloud.common.app.App
import senscloud.common.entity.EntInit
import senscloud.common.entity.api.user.member.EntUserApiMemberSubInfoEp
import senscloud.common.vali.*
import senscloud.user.app.*
import senscloud.user.entity.member.*
import comp.CompPageTitle
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.click
import ein2b.core.view.eView
import ein2b.core.view.html
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
import prop.*
import view.CompViewToast
import view.CompViewUpload

private val factory = Factory.htmlUrl("member/myinfoSubT")
private val profileT = Factory.htmlUrl("common/profileFactoryT")

private enum class K{
    fullName, phone, companyName, email, uploadFile, uploadBtn, profileList,

    editBtn,

    pList_check, pList_profile,

    MYINFO, PROFILEIMAGE, CHOOSE;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

suspend fun MyInfoSubView() = eView(factory){ rootView->
    val editForm = EntUserApiMemberSubInfoEp.request()
    lateinit var ent: EntClientProfile

    App.subHtmlFromLabel(rootView,
        K.MYINFO to "c@My info@myinfo/title/myinfo",
        K.PROFILEIMAGE to "c@Profile image@myinfo/title/profileimage",
        K.CHOOSE to "c@Or select an avatar:@myinfo/label/selectprofileimage"
    )

    App.emptySub(rootView, K.uploadFile, K.profileList, K.editBtn)
    CompPageTitle(rootView){
        it.title = "c@My info@myinfo/title2/myinfo"
    }
    rootView.compLabelInputSectionTextSet(K.fullName, "Full name", ValiMemberFullName)

    rootView.compLabelInputSectionTextSet(K.phone, "Phone", ValiMemberPhone)

    CompViewUpload.comp(rootView, K.uploadBtn, "c@Upload@myinfo@button/fileupload") { file ->
        eLaunch {
            ClientUserApiImageWp.uploadNet(file)?.also {
                ent.profileUrl = it.imageUrl
                rootView.entity(ent)
                rootView.sub(K.uploadFile).lazyBackgroundImage = "" to it.imageUrl
                editForm.profileUrl = it.imageUrl
            }

        }
    }

    rootView.sub(K.editBtn) {
        it.html = "c@Save@myinfo/button/editmyinfo"
        it.click = {_,_ ->
            eLaunch {
                val compFullName = rootView.sub(K.fullName).compLabelInputSectionText()
                val compPhone = rootView.sub(K.phone).compLabelInputSectionText()
                if(App.checkAll(compFullName, compPhone)) {
                    ClientUserApiMemberSubInfoEp.net { req ->
                        req.fullName = compFullName.out()
                        req.phone = compPhone.out()
                        req.profileUrl = editForm.profileUrl
                    }?.also {
                        ClientUserApiMemberSubInfoE.net()?.also { res ->
                            rootView.entity(EntClientMemberSubInfoE(res))
                            CompViewToast.open("c@My info was updated.@myinfo/toast/01", "success-badge", CompViewToast.TOAST_TYPE.SUCCESS)
                        }
                    }
                }
            }
        }
    }

    rootView.addEntityHook(EntClientMemberSubInfoE::class, object:eEntityHook<HTMLElement, EntClientMemberSubInfoE> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntClientMemberSubInfoE){
            val entProfile = EntSubProfile(entity)
            view.entity(entProfile)
            view.sub(K.fullName).compLabelInputSectionText().inputValue(entity.fullName)
            view.sub(K.phone).compLabelInputSectionText().inputValue(entity.phone)
        }
    })

    rootView.addEntityHook(EntSubProfile::class, object:eEntityHook<HTMLElement, EntSubProfile> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntSubProfile){
            ent = entity
            editForm.profileUrl = ent.profileUrl
            view.sub(K.uploadFile).lazyBackgroundImage = "" to entity.profileUrl
            view.sub(K.profileList).setClearList { list ->
                ent.profileUrlList.forEach { url ->
                    list += eView(profileT) { p ->
                        p.sub(K.pList_check){ // 체크
                            it.className = if(entity.profileUrl==url) "profile-check checked" else "profile-check"
                        }
                        p.sub(K.pList_profile){ // 사진
                            it.className = if(entity.profileUrl==url) "profile selected" else "profile"
                            it.lazyBackgroundImage = "" to url
                            it.click = {_,_ ->
                                ent.profileUrl = url
                                eLaunch {
                                    view.entity(ent)
                                }
                            }
                        }
                    }
                }
            }
        }
    })

    rootView.addEntityHook(EntInit::class, object:eEntityHook<HTMLElement, EntInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntInit){
            view.clearCompValue(K.fullName, K.phone)
        }
    })

}
package shipment.ongoing

import Factory
import GoogleMap
import app.hashManager
import comp.*
import comp.input.CompInputDate
import comp.input.CompInputText
import comp.input.CompSelect
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
import prop.*
import senscloud.common.app.App
import senscloud.common.enm.EnumApiCommonSearchType
import senscloud.common.enm.EnumApiShipmentSearchType
import senscloud.common.entity.EntInit
import senscloud.common.vali.ValiShipmentSearchText
import senscloud.user.app.ClientUserApiShipmentOngoing
import senscloud.user.app.RouterKey.SHIPMENT_DETAIL
import senscloud.user.entity.shipping.EntClientMarkDelivered
import senscloud.user.entity.shipping.EntClientOngoingShipping
import senscloud.user.modalView.shipping.markAsDeliveredModalOpen

private val factory = Factory.htmlUrl("shipment/ongoing/ongoing")
private val shippingT = Factory.htmlUrl("shipment/ongoing/shippingT")
private enum class K{
    searchSelect, searchText, searchDate, searchBtn, shipmentList,
    title, regDate, fromAddressName, toAddressName, cnt,
    emptyMsg, compFilter,
    vBtn, deliveredBtn;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

suspend fun OngoingView() = eView(factory){ rootView->
    App.emptySub(rootView, K.shipmentList, K.title, K.regDate, K.fromAddressName, K.toAddressName, K.cnt, K.searchBtn)
    App.subHtmlFromLabel(rootView, K.emptyMsg to "c@Couldn't find any shipment.@ongoing/vali/empty")

    CompPageTitle(rootView){
        it.title = "c@Ongoing@ongoing/title/ongoing"
    }

    CompSelect<EnumApiShipmentSearchType> { comp ->
        comp.wrapperClass = "selectbox-border width6-1"
    }.also {
        it.comp(rootView, K.searchSelect)
    }

    rootView.compInputSectionOneSet<String>(
        K.searchText,
        CompInputText{ comp ->
            comp.inputClass = "form-input width4-1"
            comp.vali = ValiShipmentSearchText
        }, wrapperClass = "margin-left15", errorClass = "form-error"
    )

    rootView.compInputSectionSet<String>(
        K.searchDate,
        wrapperClass = "margin-left15", errorClass = "form-error", isOneError = true, inputClass = "input-section-wave"
    ){
        mutableListOf(
            CompInputDate{ comp ->
                comp.ymdPattern = "Y-m-d"
            },
            CompInputDate{ comp ->
                comp.ymdPattern = "Y-m-d"
            }
        )
    }

    rootView.compFilterListSet<EnumApiShipmentSearchType>(K.compFilter) {
        it.wrapperClass = "filter-wrapper"
    }

    rootView.addEntityHook(EntClientOngoingShipping::class, object: eEntityHook<HTMLElement, EntClientOngoingShipping>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientOngoingShipping) {
            val searchType = entity.searchType
            view.sub(K.searchSelect).compSelect<EnumApiShipmentSearchType> { select ->
                select.setList { list ->
                    EnumApiShipmentSearchType.ongoingSelectList().map { op ->
                        list.item(op.label, op, if(searchType==EnumApiShipmentSearchType.ALL) op == EnumApiShipmentSearchType.SHIPMENT_NAME else op == searchType, false)
                    }
                }
                select.checkBlock = { idx ->
                    eLaunch {
                        val searchText = view.sub(K.searchText).compInputSection<String>()
                        val searchDate = view.sub(K.searchDate).compInputSection<String>()
                        searchText.displayNone()
                        searchDate.displayNone()
                        when(select.itemList[idx].value.type) {
                            EnumApiCommonSearchType.TEXT -> { searchText.displayBlock() }
                            EnumApiCommonSearchType.DATE -> { searchDate.displayBlock() }
                            else -> {}
                        }
                    }
                }
            }
            val searchText = view.sub(K.searchText).compInputSection<String>()
            val searchDate = view.sub(K.searchDate).compInputSection<String>().also { it.out{ it.values().joinToString("~") } }
            searchText.displayNone()
            searchDate.displayNone()
            when(searchType.type) {
                EnumApiCommonSearchType.NONE, EnumApiCommonSearchType.TEXT -> { searchText.displayBlock() }
                EnumApiCommonSearchType.DATE -> { searchDate.displayBlock() }
            }


            GoogleMap.create("ongoingMap", GoogleMap.MapCreationOption(37.566869252467356, 126.9786522459667, 2), entity.ongoingList.isNotEmpty(), false)

            if(entity.ongoingList.isNotEmpty()) {
                GoogleMap.renderStartEndMarkList(entity.ongoingList.map { og ->
                    GoogleMap.Shipment(
                        og.shipmentRowid,
                        GoogleMap.Mark(og.from.lat.toDouble(), og.from.lng.toDouble(), og.from.name),
                        GoogleMap.Mark(og.to.lat.toDouble(), og.to.lng.toDouble(), og.to.name)
                    )
                }){
                    eLaunch {
                        hashManager.goUrl(SHIPMENT_DETAIL, "shipmentRowid" to it)
                    }
                }
            }


            //임의의 맵을 설정해 setList에 들어갈 필터들 설정
            view.sub(K.compFilter).compFilterList<EnumApiShipmentSearchType> { compFilterList ->
                val item = entity.filterList.map { it.key to (it.key.label to it.value)}.toMap()
                compFilterList.setList(item.toMutableMap())
                compFilterList.closeEvent = { type ->
                    ClientUserApiShipmentOngoing.net { req ->
                        req.filterList = type?.let {
                            entity.filterList.minus(it).toMutableMap()
                        } ?: mutableMapOf()
                    }?.also { res ->
                        view.entity(EntClientOngoingShipping(res))
                    }
                }
            }

            view.sub(K.compFilter).displayNone()
            if(entity.filterList.isEmpty()) view.sub(K.compFilter).displayNone()
            else view.sub(K.compFilter).displayBlock()

            searchText.inputView().compInputText {
                it.value.inputValue(entity.searchText)
                it.enterEvent = { submit(view, entity) }
            }
            view.sub(K.searchBtn) {
                it.click = {_,_ ->
                    eLaunch { submit(view, entity) }
                }
            }


            view.sub(K.emptyMsg).displayNone()
            if(entity.ongoingList.isEmpty()) view.sub(K.emptyMsg).displayBlock()
            else view.sub(K.emptyMsg).displayNone()

            view.sub(K.cnt) {
                it.attr("v0" to entity.cnt)
                it.html =
                when {
                    entity.filterList.isNotEmpty() -> "c@Search results@ongoing/title/cnt/03"
                    entity.cnt.toInt()>1 -> "c@shipments@ongoing/title/cnt/02"
                    else -> "c@shipment@ongoing/title/cnt/01"
                }
            }

            view.sub(K.shipmentList).setClearList { listView ->
                entity.ongoingList.forEach { og ->
                    listView += eView(shippingT) { sView ->
                        sView.sub(K.title).html = og.name
                        sView.sub(K.regDate){
                            it.attr("v0" to og.regDate)
                            it.html = "c@Created at @common/label/createdat"
                        }
                        sView.sub(K.fromAddressName).html = og.from.name
                        sView.sub(K.toAddressName).html = og.to.name
                        sView.sub(K.vBtn) {
                            it.html = "c@See details@ongoing/button/detail"
                            it.click = {_,_ ->
                                eLaunch {
                                    hashManager.goUrl(SHIPMENT_DETAIL, "shipmentRowid" to og.shipmentRowid)
                                }
                            }
                        }
                        sView.sub(K.deliveredBtn) {
                            it.html = "c@Mark as delivered@ongoing/button/mark"
                            it.click = {_,_ ->
                                eLaunch {
                                    markAsDeliveredModalOpen(EntClientMarkDelivered(og.shipmentRowid, og.departureDate), view)
                                }
                            }
                        }
                    }
                }
            }

        }
    })

    rootView.addEntityHook(EntInit::class, object: eEntityHook<HTMLElement, EntInit>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntInit) {
            view.clearCompValue(K.searchSelect, K.searchText)
        }
    })
}

private suspend fun submit(view: eView<HTMLElement>, entity: EntClientOngoingShipping) {
    val select = view.sub(K.searchSelect).compSelect<EnumApiShipmentSearchType>()
    if(App.checkAll(select)) {
        val t = select.out()
        val v:String? = when(t.type) {
            EnumApiCommonSearchType.TEXT -> {
                val text = view.sub(K.searchText).compInputSection<String>()
                if(App.checkAll(text)) text.out() else null
            }
            EnumApiCommonSearchType.DATE -> {
                val date = view.sub(K.searchDate).compInputSection<String>()
                if(App.checkAll(date)) date.out() else null
            }
            else -> ""
        }
        v?.also{
            ClientUserApiShipmentOngoing.net { req ->
                req.filterList = entity.filterList
                req.searchType = t
                req.searchText = it
            }?.also { res ->
                view.entity(EntClientOngoingShipping(res))
            }
        }
    }
}
package comp

import Factory
import comp.input.CompInput
import comp.input.CompValue
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eVali
import ein2b.core.view.*
import kotlinx.browser.window
import org.w3c.dom.HTMLElement

/* ********************* CompTabs 사용법 *********************
                CompTabs<Int> {
                    it.wrapperClass = "tabs-list"                                    // 컴포넌트 클래스 설정
                    it.afterInited = {                                                  // 값 초기화
                        it.setList(
                            it.item("체크1", 1, isSelect = true, isDisabled = false),     // 라벨명, 밸류, select 상태, 활성화/비활성화
                            it.item("체크2", 2, isSelect = false, isDisabled = false),
                            it.item("체크3", 3, isSelect = false, isDisabled = true)
                        )
                    }
                    it.vali = it.singleRule("Tabs 벨리 에러.")                            //벨리데이션 검증(check시 발동)
                    it.checkBlock = {
                            test0A.html = "선택한 값 IDX-> $it"                                 //select 선택시 idx 값이 온다.
                        }
                })
        }
*/
suspend fun <T:Any> eView<HTMLElement>.compTabsSet(subKey: Any, block:(CompTabs<T>)->Unit): CompTabs<T> {
    val view = this
    return CompTabs(block).apply{ comp(view, subKey) }
}
class CompTabs<V:Any> private constructor(): CompInput<List<Int>, List<V>, V> {
    companion object{
        const val WRAPPER = "CompTabs_wrapper"
        operator fun <V:Any> invoke(block:(CompTabs<V>)->Unit): CompTabs<V> {
            val comp = CompTabs<V>()
            block(comp)
            return comp
        }
    }
    override val factory: suspend () -> HTMLElement = Factory.html("""<div><ul data-view="$WRAPPER"></ul></div>""")
    val subKey:String = WRAPPER
    private val itemFactory:suspend ()->HTMLElement = Factory.html("""<li data-view="" class="tab"><div data-view="label"></div><div data-view="info" class="info-badge"></div></li>""")
    suspend fun setList(vararg list: TabItem<V>){
        itemList = list
        target.setClearList{ viewList ->
            list.forEachIndexed{ idx, item ->
                viewList += eView(itemFactory){ itemView ->
                    item.view = itemView
                    itemView.sub("label").html = item.label
                    itemView.sub("info") {
                        if(!needInfo) it.displayNone()
                        else if(item.isDisabled) it.displayBlock()
                        else if(item.cnt==-1L) it.displayNone()
                        else {
                            it.html = "${item.cnt}"
                            it.displayBlock()
                        }
                    }
                    itemView.value = idx
                    itemView.className = setClassName(item)
                    itemView.click = { _, _ ->
                        if(!item.isDisabled) eLaunch{
                            list.forEach{
                                it.isSelect = false
                                it.view?.className = setClassName(it)
                            }
                            item.isSelect = true
                            itemView.className = setClassName(item)
                            value.inputValue(listOf(idx))
                            checkBlock?.invoke(idx)
                        }
                    }
                }
                if(item.isSelect) value.inputValue(listOf(idx))
            }
        }
    }
    class TabItem<V>(val label:String = "", val value:V, val cnt:Long, var isSelect:Boolean, val isDisabled:Boolean){
        var view:eView<HTMLElement>? = null
    }
    private fun setClassName(item: TabItem<V>):String = "$tabClass${
        when{
            item.isDisabled -> " $tabDisabledClass"
            item.isSelect -> " $tabSelectedClass"
            else -> ""
        }
    }"

    override var placeholder = ""
    override var errorListener:((Boolean, String)->Unit)? = null
    override var vali: eVali? = null
    override lateinit var value: CompValue<List<Int>, List<V>>
    override suspend fun init(it:eView<HTMLElement>){
        target = it.sub(subKey).also{
            it.className = wrapperClass
        }
        afterTargetInited?.invoke()
        value = CompValue(listOf(), listOf(), vali, errorListener, converter = { it.map{idx -> itemList[idx].value } }){}
        window.requestAnimationFrame{ eLaunch{ afterInited?.invoke() } }
    }
    fun<V> item(label:String = "", value:V, cnt:Long, isSelect:Boolean, isDisabled:Boolean) = TabItem(label, value, cnt, isSelect, isDisabled)
    override suspend fun error(isOk:Boolean){}
    override suspend fun clear(){}
    var wrapperClass = ""
    var afterTargetInited:(suspend ()->Unit)? = null
    var afterInited:(suspend ()->Unit)? = null
    lateinit var target:eView<HTMLElement>
    lateinit var itemList:Array<out TabItem<V>>
    var tabClass = "tab"
    var tabSelectedClass = "selected"
    var tabDisabledClass = "disabled"
    var checkBlock:((Int)->Unit)? = null
    var needInfo:Boolean = true
    override val outs: HashMap<OutType, suspend () -> V> = hashMapOf(OutType.DEFAULT to { value.value.first() })
}
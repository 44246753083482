package rental.rent

import senscloud.common.domPage.PageBase
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement
import senscloud.user.app.ClientUserApiRentalW

class RentP(router:eRouter<HTMLElement>): PageBase(::RentView, router){
    override suspend fun net(data:eRouterData){
        ClientUserApiRentalW.net{}?.let {
            view?.entity(it)
        }

    }
}
package view

import Factory
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

object CompViewLnb{
    // language=html
    private val viewFactory = Factory.html("""
    <aside id="menu" class="menu">
        <div data-view="logoBox" style="text-align:center;padding:34px 0">
            <div data-view="logo"></div>
            <!--<img src="" style="margin:0 auto;display:block;height:22px;width:150px;background:rgba(255,255,255,.5)" alt="Logo">-->
        </div>
        <div data-view="subTitle" class="ellipsis" style="text-align:left;background-color:#74749E;height:22px;border-radius:5px;margin:0 20px 20px;padding:5px 10px;color:#FFFFFF"></div>
        <b data-view="user"></b>
        <ul data-view="menuList" class="menu-list scroll1"></ul>
    </aside>
    """)
    // language=html
    private val userFactory = Factory.html("""
    <div class="user">
        <div class="user-image">
            <div data-view="profile" class="user-profile dummy-image"></div>
            <div data-view="role" class="user-role dummy-image"></div>
        </div>
        <div class="user-info">
            <div data-view="listBtn" style="cursor:pointer">
                <div>
                    <div data-view="title" class="user-title dummy-title ellipsis"></div>
                    <div data-view="subTitle" class="user-sub-title dummy-title ellipsis"></div>
                </div>
                <div class="list-btn dummy-image"></div>
            </div>
            <ul data-view="menuList" class="user-menu-list"></ul>
        </div>
    </div>
    """)
    // language=html
    private val userMenuFactory = Factory.html("""
    <li data-view="link" class="user-menu-item flex-center">
        <div data-view="icon" class="material-icons margin-right4" style="font-size:14px;line-height:20px"></div>
        <div data-view="title"></div>
    </li>
    """)
    // language=html
    private val menuFactory = Factory.html("""
    <li class="menu-item">
        <div data-view='menuList' class="lnb-title">
            <div data-view="icon" class="icon dummy-image"></div>
            <div data-view="title" class="title dummy-title"></div>
            <div data-view="urlIcon" class="url-icon"></div>
        </div>
        <div data-view="info" class="menu-info dummy-title"></div>
        <ul data-view="subList" class="sub-list"></ul>
    </li>
    """)
    // language=html
    private val menuSubFactory = Factory.html("""
        <li data-view="item" class="sub-item">
            <div data-view="title" class="dummy-title ellipsis"></div>
            <div data-view="new" class="item-new"></div>
            <div data-view="cnt" class="item-cnt"></div>
        </li>
    """)

    class MenuUser(val profileUrl:String, val roleUrl:String, val title:String, val subTitle:String) {
        class UserMenu(val key:String, val title:String, val icon:String="", val clickBlock:(()->Unit) = {})
        var menuList = mutableListOf<UserMenu>()
    }
    class MenuItem(val icon:String, val title:String, val key:String = "", val urlIcon:String = "", val info:String = "", val clickBlock:(()->Unit)? = null){
        class SubItem(val key:String, val title:String){
            var isSelect:Boolean = false
            var isNew:Boolean = false
            var cnt:Int = 0
            var clickBlock:(()->Unit) = {}
        }
        var subList = mutableListOf<SubItem>()
    }
    var menuList = mutableListOf<MenuItem>()
    var menuUser:MenuUser? = null
    var userView:eView<HTMLElement>? = null
    private var key:String = ""

    lateinit var target:eView<HTMLElement>
    suspend fun init(rootEl:HTMLElement, logo:String, isImage:Boolean=false, subTitle:String="", logoClickBlock:(()->Unit)? = null){
        target = eView(viewFactory){
            it.sub("logoBox")
            it.sub("logo"){ logoView->
                if(isImage){
                    logoView.className = "logo-image"
                    logoView.lazyBackgroundImage = "" to logo
                } else {
                    logoView.className = "logo"
                    logoView.html = logo
                }
                logoView.cursor = "default"
                logoClickBlock?.also{
                    logoView.cursor = "pointer"
                    logoView.click = {_,_-> it() }
                }
            }
            it.sub("subTitle").displayNone()
            it.sub("menuList")
            userView = it.sub("user", userFactory) { user ->
                user.sub("profile")
                user.sub("role")
                user.sub("title")
                user.sub("subTitle")
                user.sub("menuList")
                user.sub("listBtn")
            }
        }
        target.setTemplate()
        CompDummy.invoke(target, "menu")
        rootEl.appendChild(target.template!!)
    }
    suspend fun addHook(key:String){
        this.key = key
        setUser()
        setList()
    }
    suspend fun setSubTitle(subTitle: String) {
        target.sub("logoBox") {
            it.padding = "34px 0px 14px"
        }
        target.sub("subTitle") { st ->
            st.displayBlock()
            st.html = subTitle
        }
        target.sub("menuList").top = "${defaultTop + 32}px"
    }
    suspend fun setUser(){
        eWindow.addClick{ eLaunch{ menuListClose() } }
        userView?.also { user ->
            menuUser?.also { userData ->
                user.sub("profile"){ it.lazyBackgroundImage = "" to userData.profileUrl }
                user.sub("role"){
                    it.lazyBackgroundImage = "" to userData.roleUrl
                    if(userData.roleUrl.isBlank()) it.displayNone() else it.displayBlock()
                }
                user.sub("title"){ it.html = userData.title }
                user.sub("subTitle"){ it.html = userData.subTitle }
                val menuList = user.sub("menuList")
                menuList.setClearList { vList ->
                    userData.menuList.forEach { userMenuData ->
                        vList += eView(userMenuFactory){ menu ->
                            menu.sub("link"){
                                it.click = {e,_ ->
                                    e.stopPropagation()
                                    e.stopImmediatePropagation()
                                    userMenuData.clickBlock()
                                    menuList.displayNone()
                                }
                            }
                            menu.sub("icon"){
                                if(userMenuData.icon.isBlank()) it.displayNone() else it.displayBlock()
                                it.html = userMenuData.icon
                            }
                            menu.sub("title"){ it.html = userMenuData.title }
                        }
                    }
                }
                user.sub("listBtn"){
                    it.selected = false
                    it.click = {e,_ ->
                        e.stopPropagation()
                        e.stopImmediatePropagation()
                        if(it.selected!!) menuList.displayNone() else menuList.displayBlock()
                        it.selected = !it.selected!!
                    }
                }
            }
        }
    }
    suspend fun setList(){

        target.sub("menuList").setClearList{ mutableListList->
            menuList.forEach{ menuData ->
                mutableListList += eView(menuFactory){ menuView->
                    if(menuData.title.isEmpty()) menuView.sub("menuList").displayNone() else menuView.sub("menuList").displayFlex()
                    if(menuData.icon == "")  menuView.sub("icon").displayNone() else menuView.sub("icon").displayInlineBlock()
                    menuView.sub("urlIcon"){ urlIconView->
                        menuData.clickBlock?.also{
                            urlIconView.displayBlock()
                            urlIconView.click = {_,_ -> it() }
                            urlIconView.lazyBackgroundImage = "" to menuData.urlIcon
                        } ?:also{
                            urlIconView.displayNone()
                            urlIconView.click = {_,_ ->}
                        }
                    }
                    menuView.sub("icon").image = menuData.icon
                    menuView.sub("title").html = menuData.title
                    menuView.sub("info") {
                        if(menuData.info.isBlank()) it.displayNone() else it.displayBlock()
                        it.html = menuData.info
                    }
                    menuView.sub("subList").setClearList{
                        menuData.subList.forEach{ subData->
                            it += eView(menuSubFactory){ subView->
                                subView.sub("item"){
                                    subData.isSelect = subData.key == key
                                    it.className = "sub-item${if(subData.isSelect) " selected" else ""}"
                                    it.click = {_,_ ->
                                        subData.clickBlock()
                                    }
                                }
                                subView.sub("title").html = subData.title
                                subView.sub("new"){
                                    if(subData.isNew) it.displayBlock()
                                    else it.displayNone()
                                }
                                subView.sub("cnt"){
                                    if(subData.cnt > 0) {
                                        it.html = "${subData.cnt}"
                                        it.displayBlock()
                                    } else it.displayNone()
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    private suspend fun menuListClose(){
        userView?.also { user ->
            user.sub("listBtn").selected = false
            user.sub("menuList").displayNone()
        }
    }
    private val defaultTop = 240
    suspend fun dummyOn() = target.entity(EntDummyOn)
    suspend fun dummyOff() = target.entity(EntDummyOff)
}
package shipment.delivered

import Factory
import app.hashManager
import comp.*
import comp.input.*
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
import prop.*
import senscloud.common.app.App
import senscloud.common.enm.EnumApiCommonSearchType
import senscloud.common.enm.EnumApiShipmentSearchType
import senscloud.common.entity.api.user.shipment.EntUserApiShipmentDelivered
import senscloud.common.vali.ValiShipmentSearchText
import senscloud.common.vali.__EMPTY__
import senscloud.user.app.ClientUserApiShipmentDelivered
import senscloud.user.app.RouterKey
import senscloud.user.app.RouterKey.SHIPMENT_DETAIL

private val factory = Factory.htmlUrl("shipment/delivered/delivered")
private val itemFactory = Factory.htmlUrl("shipment/delivered/deliveredItemT")
private enum class K{
    searchSelect, searchText, searchDate, compFilter, page,
    searchBtn, cnt, empty, listArea,
    list,
    item_name, item_regDate, item_fromAddressName, item_toAddressName, item_detailBtn
    ;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

suspend fun DeliveredView() = eView(factory){ rootView->
    CompPageTitle(rootView){
        it.title = "c@Delivered@delivered/title/delivered"
    }

    CompSelect<EnumApiShipmentSearchType> { comp ->
        comp.wrapperClass = "selectbox-border width6-1"
    }.also {
        it.comp(rootView, K.searchSelect)
    }

    rootView.compInputSectionOneSet<String>(
        K.searchText,
        CompInputText{ comp ->
            comp.inputClass = "form-input width4-1"
            comp.vali = ValiShipmentSearchText
        }, wrapperClass = "margin-left15", errorClass = "form-error"
    )

    rootView.compInputSectionSet<String>(
        K.searchDate,
        wrapperClass = "margin-left15", errorClass = "form-error", isOneError = true, inputClass = "input-section-wave"
    ){
        mutableListOf(
            CompInputDate{ comp ->
                comp.ymdPattern = "Y-m-d"
            },
            CompInputDate{ comp ->
                comp.ymdPattern = "Y-m-d"
            }
        )
    }

    rootView.sub(K.searchBtn)
    rootView.sub(K.cnt)
    rootView.sub(K.empty).html = "c@Couldn't find any shipment.@delivered/empty/msg"
    rootView.sub(K.listArea)

    rootView.compFilterListSet<EnumApiShipmentSearchType>(K.compFilter) {
        it.wrapperClass = "filter-wrapper"
    }

    CompViewList(rootView, K.list, CompViewList.Companion.EnumCompViewListType.LIST_CARD) {
        it.wrapperClass = "margin-top15"
    }

    rootView.compPaginationSet(K.page, "page"){}

    rootView.addEntityHook(EntUserApiShipmentDelivered.Res::class, object: eEntityHook<HTMLElement, EntUserApiShipmentDelivered.Res>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntUserApiShipmentDelivered.Res) {
            val searchType = entity.meta.searchType
            view.sub(K.cnt){
                it.attr("v0" to entity.deliveredList.size)
                it.html =  "c@shipments@delivered/label/cnt/${if(searchType == EnumApiShipmentSearchType.ALL && entity.meta.filterList.isEmpty()) {
                    if(entity.deliveredList.size>1) "03" else "02"
                } else "01"}"
            }
            view.sub(K.searchSelect).compSelect<EnumApiShipmentSearchType> { select ->
                select.setList { list ->
                    EnumApiShipmentSearchType.deliveredSelectList().map { op ->
                        list.item(op.label, op, if(searchType==EnumApiShipmentSearchType.ALL) op == EnumApiShipmentSearchType.SHIPMENT_NAME else op == searchType, false)
                    }
                }
                select.checkBlock = { idx ->
                    eLaunch {
                        val searchText = view.sub(K.searchText).compInputSection<String>()
                        val searchDate = view.sub(K.searchDate).compInputSection<String>()
                        searchText.displayNone()
                        searchDate.displayNone()
                        when(select.itemList[idx].value.type) {
                            EnumApiCommonSearchType.TEXT -> { searchText.displayBlock() }
                            EnumApiCommonSearchType.DATE -> { searchDate.displayBlock() }
                            else -> {}
                        }
                    }
                }
            }
            val searchText = view.sub(K.searchText).compInputSection<String>()
            val searchDate = view.sub(K.searchDate).compInputSection<String>().also { it.out{ it.values().joinToString("~") } }
            searchText.displayNone()
            searchDate.displayNone()
            when(searchType.type) {
                EnumApiCommonSearchType.NONE, EnumApiCommonSearchType.TEXT -> { searchText.displayBlock() }
                EnumApiCommonSearchType.DATE -> { searchDate.displayBlock() }
            }
            view.sub(K.compFilter).compFilterList<EnumApiShipmentSearchType> { filter ->
                val item = entity.meta.filterList.map { it.key to (it.key.label to it.value)}.toMap()
                filter.setList(item.toMutableMap())
                filter.closeEvent = { type ->
                    ClientUserApiShipmentDelivered.net { req ->
                        req.filterList = type?.let {
                            entity.meta.filterList.minus(it).toMutableMap()
                        } ?: mutableMapOf()
                    }?.also { res ->
                        view.entity(res)
                    }
                }
            }
            if(entity.deliveredList.isEmpty()){
                view.sub(K.empty).displayBlock()
                view.sub(K.listArea).displayNone()
            } else {
                view.sub(K.empty).displayNone()
                view.sub(K.listArea).displayBlock()
            }
            view.sub(K.list).compViewList().setList(entity.deliveredList, itemFactory) {d, v ->
                App.subHtmlFromEntity(v, d, K.item_name, K.item_regDate, K.item_fromAddressName, K.item_toAddressName)
                v.sub(K.item_regDate) {
                    it.attr("v0" to d.regDate)
                    it.html = "c@Created at@delivered/label/regdate"
                }
                v.sub(K.item_detailBtn) {
                    it.html = "c@See details@delivered/button/detail"
                    it.click = {_, _ ->
                        hashManager.goUrl(SHIPMENT_DETAIL, "shipmentRowid" to d.shipmentRowid)
                    }
                }
            }
            view.sub(K.page).compPagination {
                it.setPage(entity.meta.totalRows.toLong(), entity.meta.page.toLong(), entity.meta.rowPerPage.toLong(), entity.meta.pagePerGroup.toLong())
                it.clickEvent = { p ->
                    ClientUserApiShipmentDelivered.net { req ->
                        req.filterList = entity.meta.filterList
                        req.searchType = entity.meta.searchType
                        req.searchText = entity.meta.searchText
                        req.page = "$p"
                    }?.also { res ->
                        rootView.entity(res)
                    }
                }
            }
            searchText.inputView().compInputText{
                it.value.inputValue(if(entity.meta.searchText==__EMPTY__) "" else entity.meta.searchText)
                it.enterEvent = {
                    submit(view, entity)
                }
            }
            view.sub(K.searchBtn).click = {_,_ ->
                eLaunch { submit(view, entity) }
            }
        }
    })
}

private suspend fun submit(view:eView<HTMLElement>, entity:EntUserApiShipmentDelivered.Res) {
    val select = view.sub(K.searchSelect).compSelect<EnumApiShipmentSearchType>()
    if(App.checkAll(select)) {
        val t = select.out()
        val v:String? = when(t.type) {
            EnumApiCommonSearchType.TEXT -> {
                val text = view.sub(K.searchText).compInputSection<String>()
                if(App.checkAll(text)) text.out() else null
            }
            EnumApiCommonSearchType.DATE -> {
                val date = view.sub(K.searchDate).compInputSection<String>()
                if(App.checkAll(date)) date.out() else null
            }
            else -> ""
        }
        v?.also{
            ClientUserApiShipmentDelivered.net { req ->
                req.filterList = entity.meta.filterList
                req.searchType = t
                req.searchText = it
            }?.also { res ->
                view.entity(res)
            }
        }
    }
}
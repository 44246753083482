package senscloud.common.entity.api.user.rental

import ein2b.core.entity.eEntity
import ein2b.core.entity.field.enum.enum
import senscloud.common.enm.EnumApiAccessAllow
import senscloud.common.enm.EnumApiRentalState
import senscloud.common.entity.api.user.common.EntUserApi
import senscloud.common.entity.api.user.common.USER_API_RENTAL_PATH
import senscloud.common.vali.ValiRowId

//Request Detail
object EntUserApiRentalV: EntUserApi<EntUserApiRentalV.Req, EntUserApiRentalV.Res>(EntUserApiRentalV::Req, EntUserApiRentalV::Res){
    const val PATH = "$USER_API_RENTAL_PATH/v"
    const val PATH_JSON = "$PATH/json"
    class Req: eEntity(){
        var rentalRowid:String by string(::rentalRowid){validator(ValiRowId)}
    }
    class Res: eEntity(true){
        class Receive: eEntity(){
            var regDate:String by string(::regDate)
            var sensorName:String by string(::sensorName)
            var qty:String by string(::qty)
            var startDate:String by string(::startDate)
            var endDate:String by string(::endDate)
            var codeList: MutableList<String> by stringList(::codeList){default(mutableListOf())}
        }
        class Sensor: eEntity(){
            var imageUrl:String by string(::imageUrl)
            var sensorName:String by string(::sensorName)
            var minTemp:String by string(::minTemp)
            var maxTemp:String by string(::maxTemp)
            var contents:String by string(::contents)
            var requestQty:String by string(::requestQty)
        }
        var rentalRowid:String by string(::rentalRowid)
        var companyName:String by string(::companyName)

        var name:String by string(::name)
        var phone:String by string(::phone)
        var addr1:String by string(::addr1)
        var addr2:String by string(::addr2){default("-")}

        var rentalState: EnumApiRentalState by enum(::rentalState)
        var startDate:String by string(::startDate)
        var endDate:String by string(::endDate)
        var message:String by string(::message){default("-")}

        val sensorList: MutableList<Sensor> by entityList(::sensorList, ::Sensor){default(mutableListOf(), false)}
        val receiveList: MutableList<Receive> by entityList(::receiveList, ::Receive){default(mutableListOf(), false)}
    }
    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val rsckeys = "rentallist_detail"
}
package senscloud.common.app

import ein2b.core.cdata.eCdataLoader
import ein2b.core.entity.eEntity
import ein2b.core.log.log
import ein2b.core.net.eApi
import ein2b.core.net.eApiInfo
import ein2b.core.net.eRequestTask
import ein2b.core.net.eResponseTask
import ein2b.js.browser.eLocalStorage
import senscloud.user.app.ApiUser

object ApiRsc{
    private var URL = "${ApiUser.domain}/rsc/cdata"
    //1. rscver 를 서버에 물어본다.
    //2. eLocalStorage 에 저장되어있는 rscver 를 확인해서 일치하면 그냥 사용.
    //3. rscver 가 다르면 다시 요청
    open class EntApiResourceVer: eEntity(true){
        var rscver: String by string(::rscver){ default("") }
    }
    class EntApiResourceCdata: EntApiResourceVer(){
        var ver: String by string(::ver){ default("1") }
        var cdata: eCdataLoader by entity(::cdata, ::eCdataLoader)
    }
    val toRscArray:(String) -> Array<String> = {s -> s.replace(" ", "").split(",").toTypedArray()}

    const val RSCKEYS = "rsckeys"
    const val RSCVER = "rscver"
    val apiKeys = eApi(RSCKEYS, eApi.DEFAULT to eApiInfo{
        method = eApi.POST
        url = URL
        items += RSCKEYS
        requestTask += eRequestTask.Header(RSCKEYS)
        responseTask += eResponseTask.Text
    })
    private suspend fun loadRSC(keys:String) = apiKeys(RSCKEYS to keys).also{ result->
        if(result.isOk) (result.response?.result as? String)?.also{
            eEntity.parse(EntApiResourceCdata(), it){
                it.data.forEach{ (k,v)-> console.log("report:", k, v.toList().joinToString()) }
            }?.also{
                val cdataText = it.cdata.stringify()
                eEntity.parse(eCdataLoader(), cdataText)?.load()

                val (key, ver) = it.rscver.split(":")

                eLocalStorage[key] = cdataText
                eLocalStorage["${key}_ver"] = ver
            }
        }
        else log(result.err)
    }

    val apiVer = eApi(RSCVER, eApi.DEFAULT to eApiInfo{
        method = eApi.POST
        url = URL
        items += RSCVER
        requestTask += eRequestTask.Header(RSCVER)
        responseTask += eResponseTask.Text
    })
    suspend fun rsc(vararg keys:String) = apiVer(RSCVER to keys.joinToString(",")).also{ result->
        if(result.isOk) (result.response?.result as? String)?.also{
            eEntity.parse(EntApiResourceVer(), it)?.also{
                val (k, currVer) = it.rscver.split(":")
                if(eLocalStorage.isSame("${k}_ver", currVer) && eLocalStorage[k] != null){
                    eLocalStorage[k]?.let{ eEntity.parse(eCdataLoader(), it)?.load() }
                }else{
                    eLocalStorage.remove("${k}_ver")
                    eLocalStorage.remove(k)
                    loadRSC(k)
                }
            }
        }
        else log(result.err)
    }
}
package senscloud.user.app

object RouterKey{
    const val LOGIN = "000"
    const val SIGNUP = "100"
    const val MYINFO_MASTER = "200"
    const val MYINFO_SUB = "210"
    const val CHANGE_PW = "220"
    const val SUB_ACCOUNTS = "230"
    const val SUB_ACCOUNT_ADD = "240"
    const val SUB_ACCOUNT_EDIT = "250"

    const val SHIPMENT_ADD = "300"
    const val SHIPMENT_ONGOING = "310"
    const val SHIPMENT_DELIVERED = "320"
    const val SHIPMENT_DETAIL = "330"

    const val MYSENSOR ="400"
    const val MYSENSOR_DATALIST = "410"
    const val MYSENSOR_DATADETAIL = "420"

    const val RENT_A_SENSOR = "500"

    const val RENTAL_LIST = "600"
    const val RENTAL_DETAIL = "610"

    const val ADDRESS_BOOK = "700"
}
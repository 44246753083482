package shipment.create

import senscloud.common.domPage.PageBase
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement
import senscloud.user.app.ClientUserApiShipmentW

class CreateShipmentP(router:eRouter<HTMLElement>): PageBase(::CreateShipmentView, router){
    override suspend fun net(data:eRouterData){
        ClientUserApiShipmentW.net()?.also {
            view?.entity(it)
        }
    }
}
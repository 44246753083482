package shipment.detail

import CompLabelInputSection
import Factory
import GoogleMapModal
import app.hashManager
import comp.CompPageTitle
import comp.compCustConfirmSet
import comp.compTabsSet
import comp.input.CompInputData
import comp.input.CompInputDate
import comp.input.CompInputTime
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
import prop.*
import senscloud.common.app.App
import senscloud.common.entity.EntInit
import senscloud.common.vali.*
import senscloud.user.app.*
import senscloud.user.app.RouterKey.SHIPMENT_ONGOING
import senscloud.user.entity.shipping.*
import senscloud.user.modalView.shipping.markAsDeliveredModalOpen
import senscloud.util.Chart
import senscloud.util.Chart.chart
import senscloud.util.SensorTempInfoList
import view.CompViewToast

private val factory = Factory.htmlUrl("shipment/detail/detail")
private val infoT = Factory.htmlUrl("shipment/detail/detail_info")
private val charListT = Factory.htmlUrl("shipment/detail/detail_chart")
private val editT = Factory.htmlUrl("shipment/detail/detail_edit")
private val sensorT = Factory.htmlUrl("shipment/detail/sensorT")
private val chartT = Factory.htmlUrl("shipment/detail/chartT")
private val sensorFactory = Factory.htmlUrl("shipment/detail/sensorFormT")
private val custConfirmT = Factory.htmlUrl("shipment/detail/detail_confirm")
private enum class K{
    nav, section, stateTab,

    SHIPNAME, shipment_name, REFNUM, num, DESC, description,
    sensorList, code, range, sensor_SENSORBARCODE, noSensor,
    startAddr, startDate, endAddr, endDate, editBtn,
    sensorListList, addSensorSensorBtn,
    sensor_sensor, sensor_closeBtn, confirm,
    emptyData, emptyMsg, markBtn, chartListArea,

    edit_BASICINFO, edit_name, edit_num, edit_description,
    edit_SENSORS, edit_ROUTE,
    edit_from, edit_date, edit_time, edit_to, edit_arrivalAddBtn, edit_arrivalArea,
    edit_arrivalDate, edit_arrivalTime, edit_arrivalCloseBtn, edit_submitBtn, edit_cancelBtn, edit_deleteBtn,

    c_title, c_shipName, c_msg,

    chart_SENSORBARCODE, chart_MINTEMP, chart_MAXTEMP, chart_LASTTEMP,
    chart_sensorCode, chart_minTemp, chart_maxTemp, chart_lastTemp,
    chart_tempRange, chart_graph, chart_label, chart_sizeBtn, chart_noData, chart_noDataMsg;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}
suspend fun infoView(view:eView<HTMLElement>, entity:EntClientShipmentDetail){
    view.sub(K.section).setClearList{ sectionView ->
        sectionView += eView(infoT){ infoView ->
            infoView.sub(K.shipment_name).html = entity.shipment.name
            infoView.sub(K.num).html = entity.shipment.num
            infoView.sub(K.description).html = entity.shipment.description
            App.subHtmlFromLabel(infoView,
                K.SHIPNAME to "c@Shipment name@shipment_detail/label/shipmentname",
                K.REFNUM to "c@Reference number (optional)@shipment_detail/label/referencenumber",
                K.DESC to "c@Shipment description (optional)@shipment_detail/label/shipmentdescription"
            )
            infoView.sub(K.noSensor){
                it.displayNone()
                it.html = "c@Sensor was not added.@shipment_detail/text/sensor/empty/01"
            }
            if(entity.sensorList.isEmpty()) infoView.sub(K.noSensor).displayBlock()
            else infoView.sub(K.noSensor).displayNone()
            infoView.sub(K.sensorList).setClearList{ sensorListView ->
                entity.sensorList.forEach{ s ->
                    sensorListView += eView(sensorT){ sensorView ->
                        sensorView.sub(K.sensor_SENSORBARCODE).html = "c@Sensor barcode@shipment_detail/label/sensorbarcode"
                        sensorView.sub(K.code).html = s.code
                        sensorView.sub(K.range).html = s.tempRange
                    }
                }
            }
            infoView.sub(K.startAddr).html = entity.route.startAddr
            infoView.sub(K.startDate).html = entity.route.startDate
            infoView.sub(K.endAddr).html = entity.route.endAddr
            infoView.sub(K.endDate).html = entity.route.endDate
            infoView.sub(K.editBtn){
                it.html = "c@Edit@shipment_detail/button/edit"
                it.click ={_,_ ->
                    eLaunch{
                        ClientUserApiShipmentE.net{ req ->
                            req.shipmentRowid = entity.shipment.shipmentRowid
                        }?.also{ res ->
                            view.entity(EntClientShipmentE(res))
                        }
                    }
                }
            }
        }
    }
}
suspend fun sensorHook(view:eView<HTMLElement>, sensorListEnt:EntClientShipmentWSensorList){
    view.sub(K.addSensorSensorBtn){
        it.attr("v0" to sensorListEnt.sensorList.size)
        it.html = "c@+ Add sensor@shipment_add_edit/link/addsensor"
    }
    sensorListEnt.sensorCompList = mutableListOf()
    view.sub(K.sensorListList).setClearList{ lv ->
        sensorListEnt.sensorList.forEachIndexed{ idx, s ->
            lv += eView(sensorFactory){ v ->
                sensorListEnt.sensorCompList += CompLabelInputSection(v, K.sensor_sensor, "c@Sensor barcode (optional)@shipment_add_edit/label/sensor",
                    CompInputData{ comp ->
                        comp.wrapperClass = "input-data flex-grow-1"
                        comp.changeBlock ={ str ->
                            sensorListEnt.sensorList[idx] = str
                        }
                        comp.dataList = sensorListEnt.sensorDataList.map{ sd ->
                            CompInputData.InputData(
                                sd.code,
                                sd.code,
                                sd.code
                            )
                        }.toMutableList()
                        comp.dataListClick ={ str ->
                            sensorListEnt.sensorList[idx] = str
                        }
                        comp.clearClick ={
                            sensorListEnt.sensorList[idx] = ""
                        }
                        comp.refreshClick ={
                            sensorListEnt.sensorList[idx] = ""
                        }
                        comp.suffix ={ str ->
                            sensorListEnt.sensorDataList.find{ it.code == str }?.let{
                                "<span class='state-round-badge'>${it.tempRange}</span>"
                            } ?:""
                        }
                        comp.vali = ValiSensorBarcodeOrBlank
                    }
                )
                v.sub(K.sensor_sensor).compLabelInputSectionDataString{ comp ->
                    comp.input.dataList.find{ it.value == s }?.also{
                        comp.input.setInputValueSelect(it.title)
                    } ?:comp.input.setInputValueSelect(s)
                }
                v.sub(K.sensor_closeBtn){
                    if(sensorListEnt.sensorList.size == 1) it.displayNone() else it.displayBlock()
                    it.click ={_, _ ->
                        if(sensorListEnt.sensorList.size > 1){
                            eLaunch{
                                sensorListEnt.sensorList.removeAt(idx)
                                sensorHook(view, sensorListEnt)
                            }
                        }
                    }
                }
            }
        }
    }
}

suspend fun DetailView() = eView(factory){ rootView->
    val sensorListEnt = EntClientShipmentWSensorList()
    App.emptySub(rootView, K.section, K.emptyData)
    CompPageTitle(rootView){ it.backClick = { eLaunch{ hashManager.back() } } }
    rootView.compTabsSet<Int>(K.stateTab){
        it.wrapperClass = "tab-list"
        it.afterInited ={
            it.setList(
                it.item("c@Info@shipment_detail/tab/info/01", 0, -1, isSelect = true, isDisabled = false),
                it.item("c@Chart@shipment_detail/tab/chart/01", 1, -1, isSelect = false, isDisabled = false),
            )
        }
    }

    //info tab
    rootView.addEntityHook(EntClientShipmentDetail::class, object:eEntityHook<HTMLElement, EntClientShipmentDetail>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientShipmentDetail){
            view.sub(K.nav).compPageTitle{ compTitle ->
                compTitle.setTitle(entity.shipment.name)
                compTitle.backClick = { eLaunch{ hashManager.back() } }
            }
            view.sub(K.stateTab).compTabs<Int>{ stateTab ->
                stateTab.checkBlock = { idx ->
                    eLaunch{
                        when(idx){
                            0 -> view.entity(entity)
                            1 ->{
                                ClientUserApiShipmentVSensor.net{ req ->
                                    req.shipmentRowid = entity.shipment.shipmentRowid
                                }?.also{
                                    view.entity(EntClientShipmentVSensor(it))
                                }
                            }
                        }
                    }
                }
            }
            infoView(view, entity)
        }
    })
    // edit
    rootView.addEntityHook(EntClientShipmentE::class, object:eEntityHook<HTMLElement, EntClientShipmentE>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientShipmentE){
            var isToAddrChanged = false
            var isFromAddrChanged = false
            var isArrivalTime = entity.route.endTime.isNotBlank()

            view.sub(K.section).setClearList{ sectionView ->
                sectionView += eView(editT){ editView ->
                    App.subHtmlFromLabel(editView,
                        K.edit_BASICINFO to "c@Basic info@shipment_detail/label/basicinfo",
                        K.edit_ROUTE to "c@Route@shipment_detail/label/route",
                        K.edit_SENSORS to "c@Sensors@shipment_detail/label/sensors"
                    )
                    editView.compLabelInputSectionTextSet(K.edit_name, "c@Shipment name@shipment_detail/label/shipmentname/02", ValiShipmentName).inputValue(entity.shipment.name)
                    editView.compLabelInputSectionTextSet(K.edit_num, "c@Reference number (optional)@shipment_detail/label/referencenumber/02", ValiReferenceNum).inputValue(entity.shipment.num)
                    editView.compLabelInputSectionTextSet(K.edit_description, "c@Shipment description (optional)@shipment_detail/label/shipmentdescription/02", ValiShipmentDescription).inputValue(entity.shipment.description)

                    CompLabelInputSection(editView, K.edit_from, "c@From@shipment_detail/label/from",
                        CompInputData<String>{
                            it.mustSelect = true
                            it.addOn = listOf(CompInputData.AddOnType.ADD_BUTTON, CompInputData.AddOnType.EMPTY)
                            it.addMsg = "c@Search locations on google maps@shipment_detail/text/addr/search/01"
                            it.emptyMsg = "c@Couldn't find in your address book.@shipment_detail/text/addr/empty/01"
                            it.addEvent = {
                                eLaunch{
                                    GoogleMapModal.open{ md ->
                                        ClientUserApiAddressWp.net{ req ->
                                            req.name = md.name
                                            req.lat = "${md.lat}"
                                            req.lng = "${md.lng}"
                                            req.address = md.addr
                                        }?.also{ entity ->
                                            if(!entity.isExist){
                                                editView.sub(K.edit_from).compLabelInputSectionDataString{ comp ->
                                                    comp.input.dataList = entity.addressList.map{ a ->
                                                        CompInputData.InputData(a.rowid, "${a.name} (${a.address})", a.name)
                                                    }.toMutableList()
                                                }
                                            }
                                            return@open !entity.isExist
                                        }
                                        return@open true
                                    }
                                }
                            }
                            it.vali = ValiAddress
                            it.wrapperClass = "input-data flex-grow-1"
                            it.dataList = entity.addressList.map{ a ->
                                CompInputData.InputData(a.rowid, "${a.name} (${a.address})", "${a.name}${a.address}")
                            }.toMutableList()
                            it.refreshClick ={
                                isFromAddrChanged = true
                            }
                        }
                    )

                    editView.sub(K.edit_from).compLabelInputSectionDataString{ comp->
                        comp.input.dataList.find{ it.title == entity.route.startAddr }?.also{
                            comp.input.setInputValue("${entity.route.startName} (${entity.route.startAddr})" , true)
                        } ?:comp.input.setInputValue("${entity.route.startName} (${entity.route.startAddr})", true)
                    }

                    CompLabelInputSection(editView, K.edit_date, "c@Date@shipment_detail/label/date/01",
                        CompInputDate{
                            it.ymdPattern = "Y-m-d"
                            it.default = entity.route.startDate
                        }
                    )

                    CompLabelInputSection(editView, K.edit_time, "c@Time@shipment_detail/label/time/01",
                        CompInputTime{
                            it.default = entity.route.startTime
                        }
                    )

                    CompLabelInputSection(editView, K.edit_to, "c@To@shipment_detail/label/to",
                        CompInputData<String>{
                            it.mustSelect = true
                            it.addOn = listOf(CompInputData.AddOnType.ADD_BUTTON, CompInputData.AddOnType.EMPTY)
                            it.addMsg = "c@Search locations on google maps@shipment_detail/text/addr/search/02"
                            it.emptyMsg = "c@Couldn't find in your address book.@shipment_detail/text/addr/empty/02"
                            it.addEvent = {
                                eLaunch{
                                    GoogleMapModal.open{ md ->
                                        ClientUserApiAddressWp.net{ req ->
                                            req.name = md.name
                                            req.lat = "${md.lat}"
                                            req.lng = "${md.lng}"
                                            req.address = md.addr
                                        }?.also{ entity ->
                                            if(!entity.isExist){
                                                editView.sub(K.edit_to).compLabelInputSectionDataString{
                                                    it.input.dataList = entity.addressList.map{ a ->
                                                        CompInputData.InputData(a.rowid, "${a.name} (${a.address})", a.name)
                                                    }.toMutableList()
                                                }
                                            }
                                            return@open !entity.isExist
                                        }
                                        return@open true
                                    }
                                }
                            }
                            it.wrapperClass = "input-data flex-grow-1"
                            it.dataList = entity.addressList.map{ a ->
                                CompInputData.InputData(a.rowid, "${a.name} (${a.address})", "${a.name}${a.address}")
                            }.toMutableList()
                            it.vali = ValiAddress
                            it.refreshClick ={
                                isToAddrChanged = true
                            }
                        }
                    )
                    editView.sub(K.edit_to).compLabelInputSectionDataString{ comp->
                        comp.input.dataList.find{ it.title == entity.route.endAddr }?.also{
                            comp.input.setInputValue("${entity.route.endName} (${entity.route.endAddr})", true)
                        } ?:comp.input.setInputValue("${entity.route.endName} (${entity.route.endAddr})", true)
                    }

                    editView.sub(K.edit_arrivalAddBtn){
                        it.html = "c@+ Add arrival time@shipment_add_edit/link/addarrival"
                        it.click ={_, _ ->
                            eLaunch{
                                isArrivalTime = true
                                editView.sub(K.edit_arrivalAddBtn).displayNone()
                                editView.sub(K.edit_arrivalArea).displayFlex()
                            }
                        }
                    }

                    editView.sub(K.edit_arrivalArea){ it.displayNone() }
                    editView.sub(K.edit_arrivalAddBtn){ it.displayNone() }
                    if(entity.route.endDate.isBlank()){
                        editView.sub(K.edit_arrivalArea){ it.displayNone() }
                        editView.sub(K.edit_arrivalAddBtn){ it.displayBlock() }
                    } else{
                        editView.sub(K.edit_arrivalArea){ it.displayFlex() }
                        editView.sub(K.edit_arrivalAddBtn){ it.displayNone() }
                    }

                    CompLabelInputSection(editView, K.edit_arrivalDate, "c@Date@shipment_detail/label/date/02",
                        CompInputDate{
                            it.ymdPattern = "Y-m-d"
                            if(entity.route.endDate.isNotBlank()) it.default = entity.route.endDate
                        }
                    )

                    CompLabelInputSection(editView, K.edit_arrivalTime, "c@Time@shipment_detail/label/time/02",
                        CompInputTime{
                            if(entity.route.endTime.isNotBlank()) it.default = entity.route.endTime
                        }
                    )

                    editView.sub(K.edit_arrivalCloseBtn){
                        it.click ={_, _ ->
                            eLaunch{
                                isArrivalTime = false
                                editView.sub(K.edit_arrivalAddBtn).displayBlock()
                                editView.sub(K.edit_arrivalArea).displayNone()
                                editView.clearCompValue(K.edit_arrivalDate, K.edit_arrivalTime)
                            }
                        }
                    }

                    editView.sub(K.edit_submitBtn){
                        it.html = "c@Save@shipment_detail/button/save"
                        it.click ={_,_ ->
                            eLaunch{
                                val name = editView.sub(K.edit_name).compLabelInputSectionText()
                                val num = editView.sub(K.edit_num).compLabelInputSectionText()
                                val description = editView.sub(K.edit_description).compLabelInputSectionText()
                                val from = editView.sub(K.edit_from).compLabelInputSectionDataString()
                                val date = editView.sub(K.edit_date).compLabelInputSectionDate()
                                val time = editView.sub(K.edit_time).compLabelInputSectionTime()
                                val to = editView.sub(K.edit_to).compLabelInputSectionDataString()
                                val arrivalDate = editView.sub(K.edit_arrivalDate).compLabelInputSectionDate()
                                val arrivalTime = editView.sub(K.edit_arrivalTime).compLabelInputSectionTime()
                                val result1 = App.checkAll(name, num, description, date, time)
                                val result2 = App.checkAll(*sensorListEnt.sensorCompList.toTypedArray())
                                val result3 = !isArrivalTime || App.checkAll(arrivalDate, arrivalTime)
                                val result4 = !isFromAddrChanged || App.checkAll(from)
                                val result5 = !isToAddrChanged || App.checkAll(to)
                                if(result1 && result2 && result3 && result4 && result5){
                                    val result6 = !isArrivalTime || ("${arrivalDate.out()} ${arrivalTime.out()}" >= "${date.out()} ${time.out()}")
                                    if(!result6){
                                        arrivalDate.changeError("c@Arrival date must be later than the departure date.@shipment_add_edit/vali/addr/02")
                                    } else{
                                        ClientUserApiShipmentEp.net{ req ->
                                            req.shipmentRowid = entity.shipment.shipmentRowid
                                            req.name = name.out()
                                            req.refNum = num.out()
                                            req.description = description.out()
                                            req.toAddressRowid = if(isToAddrChanged) to.out() else "0"
                                            req.fromAddressRowid = if(isFromAddrChanged) from.out() else "0"
                                            req.startDate = "${date.out()} ${time.out()}"
                                            req.sensorCodeList = sensorListEnt.sensorCompList.map{ it.out() }.filter{ it.isNotBlank() }.toMutableList()
                                            req.arrivalDate = if(isArrivalTime) "${arrivalDate.out()} ${arrivalTime.out()}" else ""
                                        }?.also{ res ->
                                            if(res.isError){
                                                if(res.error.refNum.isNotBlank()) num.changeError("c@This reference number is already in use within the ongoing shipment.@shipment_add_edit/refnum/vali", false)
                                                if(res.error.sensorCodeList.isNotEmpty()){
                                                    res.error.sensorCodeList.forEach{
                                                        sensorListEnt.sensorCompList[sensorListEnt.sensorList.indexOf(it)].changeError("c@Couldn't find the sensor barcode.@shipment_add_edit/vali/sensor/02", false)
                                                    }
                                                }
                                            }else{
                                                eLaunch{
                                                    ClientUserApiShipmentV.net{ req->
                                                        req.shipmentRowid = entity.shipment.shipmentRowid
                                                    }?.also{ res ->
                                                        rootView.entity(EntClientShipmentDetail(res))
                                                        CompViewToast.open("c@Shipment was edited.@shipment_add_edit/toast/edit", "success-badge", CompViewToast.TOAST_TYPE.SUCCESS)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                    editView.sub(K.edit_cancelBtn){
                        it.html = "c@Cancel@shipment_detail/button/cancel"
                        it.click ={_,_ ->
                            eLaunch{
                                ClientUserApiShipmentV.net{ req ->
                                    req.shipmentRowid = entity.shipment.shipmentRowid
                                }?.also{ res ->
                                    view.entity(EntClientShipmentDetail(res))
                                }
                            }
                        }
                    }

                    val cust = editView.compCustConfirmSet(K.confirm){
                        it.wrapperClass = "popup-wrapper"
                        it.cancelBtnLabel = "c@Close@shipment_add_edit/confirm/button/close"
                        it.submitBtnLabel = "c@Delete a shipment@shipment_add_edit/confirm/button/delete"
                        it.submitBtnClass = "btn warning margin-left8"
                    }
                    cust.setSubmit{
                        eLaunch{
                            ClientUserApiShipmentDp.net{ req ->
                                req.shipmentRowid = entity.shipment.shipmentRowid
                            }?.also{
                                CompViewToast.open("c@Shipment was deleted.@shipment_add_edit/toast/deletecomplete", "success-badge", CompViewToast.TOAST_TYPE.SUCCESS)
                                hashManager.goUrl(SHIPMENT_ONGOING)
                            }
                        }
                    }
                    cust.setContents(custConfirmT){ cView ->
                        cView.sub(K.c_title).html = "c@Delete a shipment@shipment_add_edit/confirm/title"
                        cView.sub(K.c_shipName).html = "${entity.shipment.name} "
                        cView.sub(K.c_msg).html = "c@ will be deleted immediately. and you cannot undo this action.@shipment_add_edit/confirm/message"
                    }

                    cust.close()
                    editView.sub(K.edit_deleteBtn){
                        it.html = "c@Delete@shipment_add_edit/button/delete"
                        it.click ={_,_ -> eLaunch{ cust.open() } }
                    }


                    editView.sub(K.addSensorSensorBtn){
                        it.click ={_, _ ->
                            eLaunch{
                                sensorListEnt.sensorList += ""
                                sensorHook(editView, sensorListEnt)
                            }
                        }
                    }

                    editView.sub(K.sensorListList)

                    sensorListEnt.also{
                        it.sensorList = entity.registeredSensorList.map{ it.code }.toMutableList() // 등록된 애
                        it.sensorDataList = entity.sensorList.map{ from -> // 전체 센서 데이터
                            Sensor().also{ to ->
                                to.code = from.code
                                to.tempRange = from.tempRange
                            }
                        }.toMutableList()
                    }
                    sensorHook(editView, sensorListEnt)
                }
            }

        }
    })

    rootView.addEntityHook(EntClientShipmentVSensor::class, object:eEntityHook<HTMLElement, EntClientShipmentVSensor>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientShipmentVSensor){
            Chart.chartIdDataMapInit()
            view.sub(K.section).setClearList{ sectionView ->
                sectionView += eView(charListT){ infoView ->
                    App.emptySub(infoView, K.emptyMsg, K.markBtn)

                    infoView.sub(K.emptyData).displayNone()
                    infoView.sub(K.chartListArea).displayNone()

                    when{
                        entity.sensorList.isEmpty()->{
                            infoView.sub(K.emptyData).displayBlock()
                            infoView.sub(K.emptyMsg).html = "c@Sensor was not added@shipment_detail/text/sensor/empty/01"
                            infoView.sub(K.markBtn).displayNone()
                        }
                        entity.route.endDate.isBlank()->{
                            infoView.sub(K.emptyData).displayBlock()
                            infoView.sub(K.emptyMsg).html = "c@Chart is not available for ongoing shipments.@shipment_detail/text/chart/empty"
                            infoView.sub(K.markBtn){
                                it.displayInlineBlock()
                                it.html = "c@Mark as delivered@shipment_detail/button/mark"
                                it.click = {_,_ ->
                                    eLaunch{
                                        markAsDeliveredModalOpen(EntClientMarkDelivered(entity.shipmentRowid, entity.route.startDate), infoView)
                                    }
                                }
                            }
                        }
                        else->{
                            infoView.sub(K.chartListArea).displayBlock()
                            infoView.sub(K.chartListArea).setClearList{ chartListArea ->
                                entity.sensorList.forEach{ s ->
                                    val chartId = Chart.id
                                    val isNoData = s.tempInfoList.isEmpty()
                                    val sensorTempInfoList = SensorTempInfoList().also{ sData->
                                        sData.itemConditionMinMax = mutableListOf(Chart.tempFloat(s.minTemp), Chart.tempFloat(s.maxTemp))
                                        sData.deliveryStartDate = entity.route.startDate
                                        sData.deliveryEndDate = entity.route.endDate
                                        sData.list = s.tempInfoList
                                        sData.isSmall = s.isSmall
                                    }
                                    Chart.chartIdDataMapAdd(chartId, sensorTempInfoList)

                                    chartListArea += eView(chartT){ cView ->
                                        App.subHtmlFromLabel(cView,
                                            K.chart_SENSORBARCODE to "c@Sensor barcode@shipment_detail/label/sensorbarcode/02",
                                            K.chart_MINTEMP to "c@Min@common/temp/min",
                                            K.chart_MAXTEMP to "c@Max@common/temp/max",
                                            K.chart_LASTTEMP to "c@Last@common/temp/last"
                                        )
                                        cView.sub(K.chart_sensorCode).html = s.code
                                        cView.sub(K.chart_tempRange).html = s.tempRange
                                        cView.sub(K.chart_minTemp).html = if(isNoData) "-" else "${sensorTempInfoList.list.minOf{ it.temp.minOf{ t-> Chart.tempFloat(t) } }}°C"
                                        cView.sub(K.chart_maxTemp).html = if(isNoData) "-" else "${sensorTempInfoList.list.maxOf{ it.temp.maxOf{ t-> Chart.tempFloat(t) } }}°C"
                                        cView.sub(K.chart_lastTemp).html = if(isNoData) "-" else "${sensorTempInfoList.list.last().temp.last().let{ t-> Chart.tempFloat(t) }}°C"

                                        val label = cView.sub(K.chart_label){
                                            it.displayNone()
                                            it.html = "${entity.route.startDateLabel} ~ ${entity.route.endDateLabel}"
                                        }
                                        cView.sub(K.chart_sizeBtn){
                                            if(isNoData) it.displayNone() else it.displayBlock()
                                            it.click = { _,_->
                                                s.isSmall = !s.isSmall
                                                if(s.isSmall) label.displayNone() else label.displayBlock()
                                                Chart.tempInfoGraph(chartId, sensorTempInfoList, Chart.chartWidth, if(s.isSmall) Chart.chartMinHeight else Chart.chartMaxHeight, s.isSmall)
                                            }
                                        }
                                        cView.sub(K.chart_graph).chart(chartId)
                                        cView.sub(K.chart_noData){ if(isNoData) it.displayBlock() else it.displayNone() }
                                        cView.sub(K.chart_noDataMsg).html = "c@No Data@shipment_detail/text/chart/nodata"
                                    }
                                }
                            }
                            Chart.chartIdDataMapDraw()
                        }
                    }
                }
            }
        }
    })

    rootView.addEntityHook(EntInit::class, object:eEntityHook<HTMLElement, EntInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntInit){
            view.sub(K.stateTab).compTabs<Int>{ stateTab ->
                stateTab.setList(
                    stateTab.item("c@Info@shipment_detail/tab/info/02", 0, -1, isSelect = true, isDisabled = false),
                    stateTab.item("c@Chart@shipment_detail/tab/chart/02", 1, -1, isSelect = false, isDisabled = false),
                )
            }
        }
    })
}
package ein2b.core.view

import ein2b.core.cdata.eCdata
import ein2b.core.cdata.eCdataLoader
import ein2b.core.collection.mutableList20
import ein2b.core.collection.splitFold
import ein2b.core.core.err
import ein2b.core.entity.eEntity
import kotlin.reflect.KClass

class eView<T> protected constructor(val factory:(suspend ()->T)?) {
    companion object{
        private val EMPTY_BINDER = object:eBinder<Any>(){
            override suspend fun bind(root: Any, view: eView<Any>): List<eViewItem<Any>> = listOf()
            override suspend fun rebind(root: Any, view: eView<Any>) {}
            override fun addView(parent: Any, child: Any) {}
            override fun removeView(parent: Any, child: Any) {}
            override fun changeView(parent: Any, child: Any, at: Int) {}
            override fun swapView(old: Any, new: Any) {}
            override fun describe(template: Any) = ""
            override fun removeAll(parent: Any) {}
            override fun cdataWrapper(key:String, str:String) = ""
        }
        private val rAttr = """@(.+?)@""".toRegex()
        @Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
        var binder:eBinder<*> = EMPTY_BINDER
        val REMOVE = object {}
        suspend operator fun<T> invoke(factory:(suspend ()->T)?, block:(suspend (eView<T>)->Unit)? = null):eView<T>{
            val view = eView(factory)
            block?.invoke(view)
            return view
        }
    }
    var key:String? = null
    private var keyViews:Map<String, T>? = null

    var template:T? = null
        private set
    var props:HashMap<String, Any>? = null
    var items:List<eViewItem<T>>? = null
    var subViews:HashMap<String, eView<T>>? = null

    var listProcessor:(suspend eView<T>.(template:T, list:MutableList<eView<T>>, oldList:MutableList<eView<T>>?, binder:eBinder<T>)->Unit)? = null
    private var list:MutableList<eView<T>>? = null
    private var oldList:MutableList<eView<T>>? = null

    private lateinit var _binder:eBinder<T>
    private var entityHook:HashMap<KClass<*>, eEntityHook<T, *>>? = null
    private var entityReturnHook:HashMap<KClass<*>, eEntityReturnHook<T, *, *>>? = null

    private var cdataObserver:((String, Any)->Unit)? = null
    private var cdataMap:HashMap<String, String>? = null
    private var cdataKey:HashMap<String, String>? = null

    private var cases:HashMap<Any, Pair<suspend () -> T, suspend (eView<T>)->Unit>>? = null

    private var attrs:HashMap<String, Any>? = null
    private var attrsObservers:HashMap<String, ()->Unit>? = null

    fun clear(){
        key = null
        keyViews = null

        template = null
        props = null
        items = null
        subViews?.values?.forEach{it.clear()}
        subViews = null

        listProcessor = null
        list?.forEach { it.clear() }
        list = null
        oldList?.forEach { it.clear() }
        oldList = null

        entityHook = null
        entityReturnHook = null

        cdataObserver?.let{
            eCdata.removeObserver(it)
            cdataObserver = null
        }
        cdataMap = null

        cases = null

        attrs = null
        attrsObservers = null
    }
    fun listEl():List<T>{
        val result = mutableList20<T>()
        if(template != null) subViews?.values?.forEach{
            if(it.list != null && it.template != null) result += it.template!!
        }
        return result
    }
    operator fun get(k:String):Any?{
        return props?.get(k)
    }
    fun attr(vararg a:Pair<String, Any>){
        if(attrs == null){
            attrs = hashMapOf()
            attrsObservers = hashMapOf()
        }
        a.forEach{ (k, v)-> attrs!![k] = v }
        attrsObservers!!.forEach{ it.value() }
    }
    operator fun set(k:String, v:Any){
        var _v = v
        if(props == null) props = hashMapOf()
        do{
            var isRender = true
            var isUpdate = false
            when (_v) {
                is eViewOnly -> {
                    isRender = false
                    _v = _v.v
                }
                is eViewUpdate -> {
                    isUpdate = true
                    _v = _v.v
                }
            }
            attrsObservers?.remove(k)

            if(cdataKey != null) cdataKey!![k]?.let{
                eCdataLoader.removeObserver(it, cdataObserver!!)
                cdataMap?.remove(it)
                cdataKey?.remove(k)
            }
            if(_v is String && _v.length > 0){
                if(_v.length > 2 && _v[0] == 'c' && _v[1] == '@'){
                    val i = _v.indexOf('@', 2)
                    val cKey = _v.substring(if(i == -1) 2 else i + 1)
                    if(cdataObserver == null){
                        cdataObserver = {key, value-> cdataMap!![key]?.let{ set(it, value) } }
                        cdataMap = hashMapOf()
                        cdataKey = hashMapOf()
                    }
                    cdataMap!![cKey] = k
                    cdataKey!![k] = cKey
                    val r:Any? = eCdata[cKey, cdataObserver]
                    if(r == null) _v = _binder.cdataWrapper(k, if(i == -1) "--" else _v.substring(2, i))
                    else{
                        _v = r
                        if(r is String && rAttr.containsMatchIn(r)) continue
                    }
                }else if(rAttr.containsMatchIn(_v)){
                    if(attrs == null){
                        attrs = hashMapOf()
                        attrsObservers = hashMapOf()
                    }
                    attrsObservers!![k] = {set(k, _v)}
                    _v = _v.replace(rAttr){"${attrs!![it.groupValues[1]] ?: ""}"}
                }
            }
            if(isUpdate || props!![k] != _v){
                props!![k] = _v
                if(isRender && template != null) _binder.addQue(template!!, keyViews, k, _v)
            }
            return
        }while(true)
    }
    suspend fun sub(key:Any, factory: (suspend () -> T)?, block:(suspend (eView<T>)->Unit)?):eView<T>{
        val k = "$key"
        if(k.isEmpty()) return this
        if(subViews == null) subViews = hashMapOf()
        val view = if(k.indexOf('.') == -1) {
                if (k !in subViews!!) subViews!![k] = eView(factory)
                subViews!![k]!!
            }else{
                k.splitFold('.',this){acc, curr, _->
                    if(acc.subViews == null) acc.subViews = hashMapOf()
                    if(curr in acc.subViews!!) acc.subViews!![curr]!!
                    else{
                        val view = eView(factory)
                        acc.subViews?.put(k, view)
                        view
                    }
                }
            }
        block?.invoke(view)
        return view
    }
    suspend fun sub(k:Any, block:(suspend (eView<T>)->Unit)? = null):eView<T>{
        return sub(k, null, block)
    }
    fun subView(k:String):eView<T>{
        if(k.isEmpty()) return this
        return if(k.indexOf('.') == -1) {
                subViews?.get(k) ?: err("invalid or no subView0 key:$k, root:$this, subViews:$subViews, props:$props, template:${_binder.describe(template!!)}")
            }else{
                k.splitFold('.',this){acc, curr, _->
                    acc.subViews?.get(curr) ?: err("invalid or no subView1 key:$k, $curr, root:$this, subViews:$subViews, props:$props, template:${_binder.describe(template!!)}")
                }
            }
    }
    suspend fun setTemplate(v:T, oldItems:List<eViewItem<T>>? = null){
        template = v
        if(oldItems != null) items = oldItems
        if(items == null) items = _binder.bind(v, this)
        else _binder.rebind(v,this)
        bindList()
    }
    suspend fun setTemplate(){
        setTemplate(factory!!())
    }
    suspend fun setClearList(block:suspend (MutableList<eView<T>>)->Unit){
        oldList = list
        list = arrayListOf()
        if(list != null) block(list!!)
        if(template != null) bindList()
    }
    suspend fun setList(block:suspend (MutableList<eView<T>>)->Unit){
        oldList = list
        list = list?.toMutableList() ?: arrayListOf()
        if(list != null) block(list!!)
        if(template != null) bindList()
    }
    private suspend fun bindList(){
        if(template == null || list == null) return
        if(listProcessor != null) listProcessor!!(template!!, list!!, oldList, _binder)
        else{
            val listRoot = template!!
            val newList = list!!
            if (oldList == null) {
                _binder.removeAll(listRoot)
                var i = 0
                while (i < newList.size) {
                    newList[i++].let {
                        it.setTemplate()
                        _binder.addView(listRoot, it.template!!)
                    }
                }
            } else {
                val old = oldList!!
                val oldSize = old.size
                var i = 0
                while (i < newList.size) {
                    val curr = newList[i]
                    if (i < oldSize) {
                        val currOld = old[i]
                        if(currOld !== curr){
                            if (currOld.template != null && curr.factory == currOld.factory) {
                                copyTarget(currOld, curr)
                                curr.setTemplate(currOld.template!!, currOld.items)
                            } else {
                                curr.setTemplate()
                                _binder.swapView(currOld.template!!, curr.template!!)
                            }
                        }
                    } else {
                        curr.setTemplate()
                        _binder.addView(listRoot, curr.template!!)
                    }
                    i++
                }
                while(i < oldSize) _binder.removeView(listRoot, old[i++].template!!)
            }
        }
        oldList = list
    }
    private fun copyTarget(old:eView<T>, curr:eView<T>){
        val items: List<eViewItem<T>> = old.items ?: return
        var i = items.size
        while(i-- > 0){
            val it = items[i]
            val c = if(it.isGenerated) it.target else curr.subView(it.k)
            if (it.k.isNotEmpty()) copyTarget(it.target, c)
            c.items = it.target.items
            it.target = c
        }
    }
    fun clearListEl(){
        subViews?.values?.forEach {
            val root = it.template ?: return@forEach
            val list = it.list ?: return@forEach
            _binder.removeAll(root)
        }
    }
    suspend fun bindView(kView:HashMap<String, T>, el:T, isRebind:Boolean = false):eView<T>{
        if(key != null) kView[key!!] = el
        template = el
        keyViews = kView
        if(props != null) for((k, v) in props!!) _binder.addQue(el, keyViews, k, v)
        oldList = null
        bindList()
        if(isRebind && items != null) _binder.rebind(el, this)
        return this
    }
    fun <R:eEntity> addEntityHook(entityClass:KClass<R>, hook:eEntityHook<T, R>){
        if(entityHook == null) entityHook = hashMapOf()
        entityHook?.put(entityClass, hook)
    }
    fun <R:eEntity, U> addEntityHook(entityClass:KClass<R>, hook:eEntityReturnHook<T, R, U>){
        if(entityReturnHook == null) entityReturnHook = hashMapOf()
        entityReturnHook?.put(entityClass, hook)
    }
    suspend fun<R:eEntity> entity(entity:R){
        @Suppress("UNCHECKED_CAST")
        (entityHook?.get(entity::class) as? eEntityHook<T, R>)?.invoke(this, entity)
    }
    suspend fun<R:eEntity, RETURN> entityReturn(entity:R):RETURN?{
        @Suppress("UNCHECKED_CAST")
        return (entityReturnHook?.get(entity::class) as? eEntityReturnHook<T, R, RETURN>)?.invoke(this, entity)
    }

    fun case(k:Any, factory: (suspend () -> T), block:(suspend (eView<T>)->Unit)):eView<T>{
        if(cases == null) cases = hashMapOf()
        cases!![k] = factory to block
        return this
    }
    suspend fun routeCase(k:Any, entity: eEntity? = null){
        if(cases?.contains(k) != true) return
        val (factory, block) = cases!![k]!!
        setClearList {
            val view = eView(factory, block)
            if(entity != null) view.entity(entity)
            it += view
        }
    }
    init{
        if(binder === EMPTY_BINDER) err("no binder")
        @Suppress("UNCHECKED_CAST")
        (binder as? eBinder<T>)?.let{ _binder = it } ?: err("invalid binder:$binder")
    }
}
package ein2b.core.view

import ein2b.core.core.eNN
import ein2b.core.log.log

import org.w3c.dom.*
import org.w3c.dom.events.Event
import kotlinx.browser.window


fun eView<HTMLElement>.attr(k:String, v:Any) = set("A$k", v)
fun eView<HTMLElement>.attr(k:String) = this["A$k"]
fun eView<HTMLElement>.domStyle(k:String, v:Any) = set(k, v)

//styles
var eView<HTMLElement>.opacity get() = this["opacity"] as? Double
    set(v){this["opacity"] = v!!}
var eView<HTMLElement>.position get() = this["position"]
    set(v){this["position"] = v!!}
fun eView<HTMLElement>.positionRelative() = set("position", "relative")
fun eView<HTMLElement>.positionAbsolute() = set("position", "absolute")
fun eView<HTMLElement>.positionFixed() = set("position", "fixed")
fun eView<HTMLElement>.positionStatic() = set("position", "static")
var eView<HTMLElement>.top get() = this["top"]
    set(v){this["top"] = v!!}
var eView<HTMLElement>.left get() = this["left"]
    set(v){this["left"] = v!!}
var eView<HTMLElement>.right get() = this["right"]
    set(v){this["right"] = v!!}
var eView<HTMLElement>.bottom get() = this["bottom"]
    set(v){this["bottom"] = v!!}
var eView<HTMLElement>.transition get() = this["transition"]
    set(v){this["transition"] = v!!}
fun eView<HTMLElement>.transitionEaseOut(time:Double){this["transition"] = "${time}s all ease-out"}
fun eView<HTMLElement>.transitionEaseIn(time:Double){this["transition"] = "${time}s all ease-in"}
fun eView<HTMLElement>.transitionEaseInOut(time:Double){this["transition"] = "${time}s all ease-inout"}
fun eView<HTMLElement>.transitionEaseOutCubic(time:Double){this["transition"] = "${time}s all cubic-bezier(.215,.61,.355,1)"}
fun eView<HTMLElement>.transitionEaseInOutCubic(time:Double){this["transition"] = "${time}s all cubic-bezier(.645,.045,.355,1)"}
fun eView<HTMLElement>.transitionEaseInCirc(time:Double){this["transition"] = "${time}s all cubic-bezier(.6,.04,.98,.335)"}
fun eView<HTMLElement>.transitionEaseOutCirc(time:Double){this["transition"] = "${time}s all cubic-bezier(.075,.82,.165,1)"}
fun eView<HTMLElement>.transitionEaseInOutCirc(time:Double) {this["transition"] = "${time}s all cubic-bezier(.785,.135,.15,.86)"}
fun eView<HTMLElement>.transitionEaseInExpo(time:Double){this["transition"] = "${time}s all cubic-bezier(.95,.05,.795,.035)"}
fun eView<HTMLElement>.transitionEaseOutExpo(time:Double){this["transition"] = "${time}s all cubic-bezier(.19,1,.22,1)"}
fun eView<HTMLElement>.transitionEaseInOutExpo(time:Double){this["transition"] = "${time}s all cubic-bezier(1,0,0,1)"}
fun eView<HTMLElement>.transitionEaseInQuad(time:Double){this["transition"] = "${time}s all cubic-bezier(.55,.085,.68,.53)"}
fun eView<HTMLElement>.transitionEaseOutQuad(time:Double){this["transition"] = "${time}s all cubic-bezier(.25,.46,.45,.94)"}
fun eView<HTMLElement>.transitionEaseInOutQuad(time:Double){this["transition"] = "${time}s all cubic-bezier(.455,.03,.515,.955)"}
fun eView<HTMLElement>.transitionEaseInQuart(time:Double){this["transition"] = "${time}s all cubic-bezier(.895,.03,.685,.22)"}
fun eView<HTMLElement>.transitionEaseOutQuart(time:Double){this["transition"] = "${time}s all cubic-bezier(.165,.84,.44,1)"}
fun eView<HTMLElement>.transitionEaseInOutQuart(time:Double){this["transition"] = "${time}s all cubic-bezier(.77,0,.175,1)"}
fun eView<HTMLElement>.transitionEaseInQuint(time:Double){this["transition"] = "${time}s all cubic-bezier(.755,.05,.855,.06)"}
fun eView<HTMLElement>.transitionEaseOutQuint(time:Double){this["transition"] = "${time}s all cubic-bezier(.23,1,.32,1)"}
fun eView<HTMLElement>.transitionEaseInOutQuint(time:Double){this["transition"] = "${time}s all cubic-bezier(.86,0,.07,1)"}
fun eView<HTMLElement>.transitionEaseInSine(time:Double){this["transition"] = "${time}s all cubic-bezier(.47,0,.745,.715)"}
fun eView<HTMLElement>.transitionEaseOutSine(time:Double){this["transition"] = "${time}s all cubic-bezier(.39,.575,.565,1)"}
fun eView<HTMLElement>.transitionEaseInOutSine(time:Double){this["transition"] = "${time}s all cubic-bezier(.445,.05,.55,.95)"}
fun eView<HTMLElement>.transitionEaseInBack(time:Double){this["transition"] = "${time}s all cubic-bezier(.6,-.28,.735,.045)"}
fun eView<HTMLElement>.transitionEaseOutBack(time:Double){this["transition"] = "${time}s all cubic-bezier(.175, .885,.32,1.275)"}
fun eView<HTMLElement>.transitionEaseInOutBack(time:Double){this["transition"] = "${time}s all cubic-bezier(.68,-.55,.265,1.55)"}

var eView<HTMLElement>.transform get() = this["transform"]
    set(v){this["transform"] = v!!}
var eView<HTMLElement>.width get() = this["width"]
    set(v){this["width"] = v!!}
var eView<HTMLElement>.height get() = this["height"]
    set(v){this["height"] = v!!}
var eView<HTMLElement>.cursor get() = this["cursor"]
    set(v){this["cursor"] = v!!}
var eView<HTMLElement>.background get() = this["background"]
    set(v){this["background"] = v!!}
var eView<HTMLElement>.backgroundImage get() = this["backgroundImage"]
    set(v){this["backgroundImage"] = v!!}
var eView<HTMLElement>.backgroundColor get() = this["backgroundColor"]
    set(v){this["backgroundColor"] = v!!}
var eView<HTMLElement>.backgroundSize get() = this["backgroundSize"]
    set(v){this["backgroundSize"] = v!!}
var eView<HTMLElement>.color get() = this["color"]
    set(v){this["color"] = v!!}
var eView<HTMLElement>.textDecoration get() = this["textDecoration"]
    set(v){this["textDecoration"] = v!!}
var eView<HTMLElement>.textAlign get() = this["textAlign"]
    set(v){this["textAlign"] = v!!}
var eView<HTMLElement>.border get() = this["border"]
    set(v){this["border"] = v!!}
var eView<HTMLElement>.borderLeft get() = this["borderLeft"]
    set(v){this["borderLeft"] = v!!}
var eView<HTMLElement>.borderRight get() = this["borderRight"]
    set(v){this["borderRight"] = v!!}
var eView<HTMLElement>.borderBottom get() = this["borderBottom"]
    set(v){this["borderBottom"] = v!!}
var eView<HTMLElement>.fontWeight get() = this["fontWeight"]
    set(v){this["fontWeight"] = v!!}
var eView<HTMLElement>.lineHeight get() = this["lineHeight"]
    set(v){this["lineHeight"] = v!!}
var eView<HTMLElement>.padding get() = this["padding"]
    set(v){this["padding"] = v!!}
var eView<HTMLElement>.paddingTop get() = this["paddingTop"]
    set(v){this["paddingTop"] = v!!}
var eView<HTMLElement>.margin get() = this["margin"]
    set(v){this["margin"] = v!!}
var eView<HTMLElement>.marginLeft get() = this["marginLeft"]
    set(v){this["marginLeft"] = v!!}
var eView<HTMLElement>.marginRight get() = this["marginRight"]
    set(v){this["marginRight"] = v!!}
var eView<HTMLElement>.marginTop get() = this["marginTop"]
    set(v){this["marginTop"] = v!!}
var eView<HTMLElement>.marginBottom get() = this["marginBottom"]
    set(v){this["marginBottom"] = v!!}
var eView<HTMLElement>.display get() = this["display"] as? String
    set(v){this["display"] = v!!}
fun eView<HTMLElement>.displayFlex() = set("display","flex")
fun eView<HTMLElement>.displayBlock() = set("display","block")
fun eView<HTMLElement>.displayInlineBlock() = set("display","inline-block")
fun eView<HTMLElement>.displayNone() = set("display","none")
fun eView<HTMLElement>.displayInline() = set("display","inline")
var eView<HTMLElement>.visibility get() = this["visibility"] as? String
    set(v){this["visibility"] = v!!}
fun eView<HTMLElement>.visibilityHidden() = set("visibility", "hidden")
fun eView<HTMLElement>.visibilityVisible() = set("visibility", "visible")
var eView<HTMLElement>.visible get() = this["visible"] as? Boolean
    set(v){this["visible"] = v!!}
//event
@Suppress("UNCHECKED_CAST")
var eView<HTMLElement>.click get() = this["click"] as? (Event, HTMLElement)->Unit
    set(v){this["click"] = v!!}
@Suppress("UNCHECKED_CAST")
var eView<HTMLElement>.change get() = this["change"] as? (Event, HTMLElement)->Unit
    set(v){this["change"] = v!!}
@Suppress("UNCHECKED_CAST")
var eView<HTMLElement>.keydown get() = this["keydown"] as? (Event, HTMLElement)->Unit
    set(v){this["keydown"] = v!!}
@Suppress("UNCHECKED_CAST")
var eView<HTMLElement>.keyup get() = this["keyup"] as? (Event, HTMLElement)->Unit
    set(v){this["keyup"] = v!!}
@Suppress("UNCHECKED_CAST")
var eView<HTMLElement>.focus get() = this["focus"] as? (Event, HTMLElement)->Unit
    set(v){this["focus"] = v!!}
@Suppress("UNCHECKED_CAST")
var eView<HTMLElement>.blur get() = this["blur"] as? (Event, HTMLElement)->Unit
    set(v){this["blur"] = v!!}
@Suppress("UNCHECKED_CAST")
var eView<HTMLElement>.touchstart get() = this["touchstart"] as? (Event, HTMLElement)->Unit
    set(v){this["touchstart"] = v!!}
@Suppress("UNCHECKED_CAST")
var eView<HTMLElement>.touchmove get() = this["touchmove"] as? (Event, HTMLElement)->Unit
    set(v){this["touchmove"] = v!!}
@Suppress("UNCHECKED_CAST")
var eView<HTMLElement>.touchend get() = this["touchend"] as? (Event, HTMLElement)->Unit
    set(v){this["touchend"] = v!!}
//prop
var eView<HTMLElement>.src get() = this["Asrc"] as? String
    set(v){this["Asrc"] = v!!}
var eView<HTMLElement>.className get() = this["className"] as? String
    set(v){this["className"] = v!!}
var eView<HTMLElement>.className_ get() = this["className_"] as? String
    set(v){this["className_"] = v!!}
var eView<HTMLElement>.html get() = this["html"] as? String
    set(v){this["html"] = v!!}
var eView<HTMLElement>.titleName get() = this["Atitle"] as? String
    set(v){this["Atitle"] = v!!}
var eView<HTMLElement>.name get() = this["name"] as? String
    set(v){this["name"] = v!!}
var eView<HTMLElement>.tabIndex get() = this["tabIndex"] as? Int
    set(v){this["tabIndex"] = v!!}
var eView<HTMLElement>.runSubmit get() = this["runSubmit"] == true
    set(v){this["runSubmit"] = v}
var eView<HTMLElement>.runFocus get() = this["runFocus"] == true
    set(v){this["runFocus"] = v}
var eView<HTMLElement>.runBlur get() = this["runBlur"] == true
    set(v){this["runBlur"] = v}
var eView<HTMLElement>.disabled get() = this["disabled"] as? Boolean
    set(v){this["disabled"] = v!!}
var eView<HTMLElement>.checked get() = this["checked"] as? Boolean
    set(v){this["checked"] = v!!}
var eView<HTMLElement>.selected get() = this["selected"] as? Boolean
    set(v){this["selected"] = v!!}
var eView<HTMLElement>.selectedIndex get() = this["selectedIndex"] as? Int
    set(v){this["selectedIndex"] = v!!}
var eView<HTMLElement>.unselect get() = this["unselect"] as? Boolean
    set(v){this["unselect"] = v!!}
var eView<HTMLElement>.value get() = this["value"]
    set(v){this["value"] = v!!}
fun eView<HTMLElement>.valueRemove() = set("value", eView.REMOVE)
var eView<HTMLElement>.valueWithoutAttr get() = this["valueWithoutAttr"]
    set(v){this["valueWithoutAttr"] = v!!}
fun eView<HTMLElement>.valueWithoutAttrRemove() = set("valueWithoutAttr", eView.REMOVE)

@Suppress("UNCHECKED_CAST")
var eView<HTMLElement>.watch get() = this["watch"] as? ()->Boolean
    set(v){this["watch"] = v!!}
fun eView<HTMLElement>.watch(block:((eDomBinder.Stop)->Unit)? = null) = block?.let{set("watch",it)}
    ?: props?.remove("watch")

@Suppress("UNCHECKED_CAST")
var eView<HTMLElement>.lazyBackgroundImage get() = this["lazyBackgroundImage"] as? Pair<String, String>
    set(v){this["lazyBackgroundImage"] = v!!}
fun eView<HTMLElement>.lazyBackgroundImage(lowSrc:String, highSrc:String) = set("lazyBackgroundImage", lowSrc to highSrc)
@Suppress("UNCHECKED_CAST")
var eView<HTMLElement>.enterScreen get() = this["enterScreen"] as? ()->Unit
    set(v){this["enterScreen"] = v!!}
fun eView<HTMLElement>.enterScreen(h:Int, block:(()->Unit)? = null) = block?.let{set("enterScreen", h to it)}
    ?: props?.remove("enterScreen")

var eView<HTMLElement>.inputType get() = this["inputType"]
    set(v){this["inputType"] = v!!}
var eView<HTMLElement>.placeholder get() = this["placeholder"]
    set(v){this["placeholder"] = v!!}
var eView<HTMLElement>.targetscroll get() = this["targetscroll"] == true
    set(v){this["targetscroll"] = v}
var eView<HTMLElement>.min get() = this["min"]
    set(v){this["min"] = v!!}
var eView<HTMLElement>.max get() = this["max"] as? String
    set(v){this["max"] = v!!}
var eView<HTMLElement>.scrollTop get() = this["scrollTop"]
    set(v){this["scrollTop"] = v!!}
var eView<HTMLElement>.scrollX get() = this["scrollX"]
    set(v){this["scrollX"] = v!!}
var eView<HTMLElement>.scrollY get() = this["scrollY"]
    set(v){this["scrollY"] = v!!}
var eView<HTMLElement>.image get() = this["image"] as? String
    set(v){this["image"] = v!!}

val eView<HTMLElement>.selectOptionValue get() = (template as? HTMLSelectElement)?.let{
    val collection = it.selectedOptions
    if(collection.length == 0) null
    else collection.asList().map { it as HTMLOptionElement }.map{ e->
        e.value
    }
}

//클라 사정에 맞추 아무거나 넣어도 됨
var eView<HTMLElement>.type get() = this["type"]
    set(v){this["type"] = v!!}

fun PropBase() = Property.props.let{
    it["image"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){
        when(view){
            is Image->view.src = "$v"
            else-> view.style.backgroundImage = "url('$v')"
        }
    }
    it["className"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){view.className = "$v"}
    it["className_"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){view.className += " $v"}
    it["html"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){
        view.innerHTML = "$v"
    }
    it["name"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){
        when(view){
            is HTMLFormElement-> view.name = "$v"
            is HTMLInputElement-> view.name = "$v"
        }
    }
    it["tabIndex"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){
        (v as? Int)?.let{
            view.tabIndex = v
        }
    }
    it["visible"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){
        view.style.display = if(v == true) "block" else "none"
    }
    it["runSubmit"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){if(v == true)(view as? HTMLFormElement)?.submit()}
    it["runFocus"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){if(v == true) view.focus()}
    it["runBlur"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){if(v == true)view.blur()}
    it["disabled"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){
        if(v !is Boolean) return
        when(view) {
            is HTMLInputElement->view.disabled = v
            is HTMLButtonElement->view.disabled = v
            is HTMLSelectElement->view.disabled = v
            is HTMLOptGroupElement->view.disabled = v
            is HTMLOptionElement->view.disabled = v
            is HTMLTextAreaElement->view.disabled = v
        }
    }
    it["checked"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>) = eNN(view as? HTMLInputElement, v as Boolean){ el, b->el.checked = b}
    it["selected"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>) = eNN(view as? HTMLOptionElement, v as Boolean){ el, b->el.selected = b}
    it["selectedIndex"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>) = eNN(view as? HTMLSelectElement, v as Int){ el, i->el.selectedIndex = i}
    it["unselect"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){
        if(v !is Boolean) return
        if(v){
            Property.style(view, "user-select", "none")
            Property.style(view, "touch-callout", "none")
            view.setAttribute("unselectable", "on")
            view.setAttribute("onselectstart", "return false")
        }else{
            Property.style(view, "user-select", "null")
            Property.style(view, "touch-callout", "null")
            view.removeAttribute("unselectable")
            view.removeAttribute("onselectstart")
        }
    }
    it["value"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){
        if(v === eView.REMOVE) {
            view.removeAttribute("value")
            when(view) {
                is HTMLSelectElement->view.value = ""
                is HTMLInputElement->view.value = ""
                is HTMLTextAreaElement->view.value = ""
            }
        }else{
            view.setAttribute("value", "$v")
            when(view) {
                is HTMLSelectElement->view.value = "$v"
                is HTMLInputElement->view.value = "$v"
                is HTMLTextAreaElement->view.value = "$v"
            }
        }
    }
    it["valueWithoutAttr"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){
        if(v === eView.REMOVE) {
            when(view) {
                is HTMLSelectElement->view.value = ""
                is HTMLInputElement->view.value = ""
                is HTMLTextAreaElement->view.value = ""
            }
        }else{
            when(view) {
                is HTMLSelectElement->view.value = "$v"
                is HTMLInputElement->view.value = "$v"
                is HTMLTextAreaElement->view.value = "$v"
            }
        }
    }
    @Suppress("UNCHECKED_CAST")
    it["enterScreen"] = fun(view:HTMLElement, v:Any, keys:Map<String, HTMLElement>){
        val f = (v as? Pair<Int, ()->Unit>) ?: return
        val top = view.getBoundingClientRect().top
        if(top == 0.0) return
        if(window.innerHeight + f.first > top){
            f.second()
            return
        }
        (eView.binder as eDomBinder).addRenderJob(200.0){stop->
            val t = view.getBoundingClientRect().top
            if(t > 0.0 && window.innerHeight + f.first > t){
                f.second()
                stop.stop()
            }
        }
    }
    @Suppress("UNCHECKED_CAST")
    it["lazyBackgroundImage"] = fun(view:HTMLElement, v:Any, keys:Map<String, HTMLElement>){
        @Suppress("UNCHECKED_CAST")
        (v as? Pair<String, String>)?.let {(low, high)->
            if(window.innerHeight + 100 > view.getBoundingClientRect().top) view.style.backgroundImage = "url('$high')"
            else {
                if(low.isNotBlank()) view.style.backgroundImage = "url('$low')"
                (eView.binder as eDomBinder).addRenderJob(200.0){stop->
                    val t = view.getBoundingClientRect().top
                    if(t > 0.0){
                        if(window.innerHeight + 50 > t){
                            view.style.backgroundImage = "url('$high')"
                            stop.stop()
                        }
                    }
                }
            }
        }
    }
    @Suppress("UNCHECKED_CAST")
    it["watch"] = fun(view:HTMLElement, v:Any, keys:Map<String, HTMLElement>){
        (v as? (eDomBinder.Stop)->Unit)?.let{f->
            (eView.binder as eDomBinder).addRenderJob(200.0, f)
        }
    }
   /* it["lazySrc"] = fun(view:HTMLElement, v:Any, keys:Map<String, HTMLElement>){
        @Suppress("UNCHECKED_CAST")
        (v as? Pair<String, String>)?.let {(low, high)->
            if(window.innerHeight + 100 > view.getBoundingClientRect().top) view.setAttribute("src", high)
            else {
                if(low.isNotBlank()) view.setAttribute("src", low)
                A{
                    ani({
                        delay = 200
                        isInfinity = true
                    }){
                        if(window.innerHeight + 50 > view.getBoundingClientRect().top){
                            view.setAttribute("src", high)
                            it.stop()
                        }
                    }
                }
            }
        }
    }

    it["template"] = fun(view:HTMLElement, v:Any, keys:Map<String, HTMLElement>){
        (v as? eTemplateData)?.apply{eTemplate.render(view, data, templates, ref)}
    }
    it["component"] = fun(view:HTMLElement, v:Any, keys:Map<String, HTMLElement>){
        @Suppress("UNCHECKED_CAST")
        (v as? Pair<String, eViewModel>)?.apply{
            eComponent.render(view, first, second)
        }
    }*/
    it["inputType"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){
        (view as? HTMLInputElement)?.let{
            it.type = "$v"
        }
    }
    it["placeholder"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){
        (view as? HTMLInputElement)?.let{
            it.placeholder = "$v"
        }
        (view as? HTMLTextAreaElement)?.let{
            it.placeholder = "$v"
        }
    }
    /*it["targetscroll"] = fun(view:HTMLElement, v:Any, keys:Map<String, HTMLElement>){
        if(v == true) A{once{ view.scrollIntoView() } }
    }*/
    it["min"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){
        (view as? HTMLInputElement)?.let{
            view.setAttribute("min", "$v")
            it.min = "$v"
        }
    }
    it["max"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){
        (view as? HTMLInputElement)?.let{
            view.setAttribute("max", "$v")
            it.max = "$v"
        }
    }
    it["scrollTop"]  = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){
        if(v == true) window.requestAnimationFrame{ view.parentElement?.scrollTop = view.offsetTop.toDouble() }
    }
    it["scrollX"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){
        if(v !is Number) return
        view.scrollLeft = v.toDouble()
    }
    it["scrollY"] = fun(view:HTMLElement, v:Any, _:Map<String, HTMLElement>){
        if(v !is Number) return
        view.scrollTop = v.toDouble()
    }
}

package member.myinfoMaster

import senscloud.common.domPage.PageBase
import senscloud.user.app.ClientUserApiMemberMasterInfoE
import senscloud.user.entity.member.EntClientMemberMasterInfoE
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement

class MyInfoMasterP(router:eRouter<HTMLElement>): PageBase(::MyInfoMasterView, router){
    override suspend fun net(data:eRouterData){
//        view?.entity(EntClientMemberMasterInfoE(
//            EntUserApiMemberMasterInfoE.Res().also {
//                it.fullName = "지밍키"
//                it.phone = "01054547979"
//                it.email = "test@mobility42.io"
//                it.companyName = "mobility42"
//                it.profileUrlList = mutableListOf("//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/21/f358e9c2224edd3c611355cb1f15b8b8.png",
//                    "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/21/502e76b6fd2ae5432c399ed322a57fda.png",
//                    "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/21/e669304040a5ae3517b6754c8dd4cd50.png",
//                    "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/21/163dc620d6e64451d847ca9a216cae8e.png",
//                    "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/21/aa6eb7f845b11ceacda43689c0437558.png",
//                    "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/21/79843605ef7473c45405adc64c6ca090.png",
//                    "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/21/4ace36a355dd168da906dc3e4e7b4896.png",
//                    "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/21/a1bbb1d6423237dbeaaef4f8bf890c9e.png")
//                it.profileUrl = "https://s3.ap-northeast-2.amazonaws.com/m42upload/m42medi/2021/12/29/f80c00ca93c5b2ca1ef6838700759678.png"
//            }
//        ))
        ClientUserApiMemberMasterInfoE.net()?.also {
            view?.entity(EntClientMemberMasterInfoE(it))
        }
    }
}
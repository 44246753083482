package senscloud.common.entity.api.user.rental

import ein2b.core.entity.eEntity
import ein2b.core.entity.eEntityValidator
import ein2b.core.entity.field.enum.enum
import senscloud.common.enm.EnumApiAccessAllow
import senscloud.common.enm.EnumApiRentalState
import senscloud.common.entity.api.user.common.EntUserApi
import senscloud.common.entity.api.user.common.EntUserApiPageMeta
import senscloud.common.entity.api.user.common.USER_API_RENTAL_PATH
import senscloud.common.vali.*

//rent W
object EntUserApiRentalW: EntUserApi<EntUserApiRentalW.Req, EntUserApiRentalW.Res>(::Req, ::Res){
    const val PATH = "$USER_API_RENTAL_PATH/w"
    const val PATH_JSON = "$PATH/json"
    override val rsckeys = "rentasensor, shipment_add_edit"
    class Req: eEntity()
    class Res: eEntity(true){
        class Sensor: eEntity(){
            var rowid:String by string(::rowid)
            var imageUrl:String by string(::imageUrl)
            var title:String by string(::title)
            var temp:String by string(::temp)
            var contents:String by string(::contents)
        }
        class Address:eEntity(true) {
            var rowid:String by string(::rowid)
            var title:String by string(::title)
            var addr1:String by string(::addr1)
        }
        val sensorList: MutableList<Sensor> by entityList(::sensorList, ::Sensor) { default(mutableListOf(), false)}
        var name:String by string(::name)
        var phone:String by string(::phone)
        var addressList: MutableList<Address> by entityList(::addressList, ::Address){ default(mutableListOf(), false)}
    }

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
    init {
//        isJSON = true
    }
}

//rent Wp
object EntUserApiRentalWp: EntUserApi<EntUserApiRentalWp.Req, EntUserApiRentalWp.Res>(::Req, ::Res) {
    const val PATH = "$USER_API_RENTAL_PATH/wp"
    const val PATH_JSON = "$PATH/json"

    class Req: eEntity(), eEntityValidator {
        class Sensor: eEntity(){
            var rowid:String by string(::rowid) {validator(ValiRowId)}
            var requestQty:String by string(::requestQty) {validator(ValiRentalQuantity)}
        }
        val sensorList: MutableList<Sensor> by entityList(::sensorList, ::Sensor) { default(mutableListOf(), false)}
        var name:String by string(::name) {validator(ValiRentalName)}
        var phone:String by string(::phone) {validator(ValiRentalPhone)}
        var addressRowid:String by string(::addressRowid) {validator(ValiRowId)}
        var addr2:String by string(::addr2) {validator(ValiRentalAddress2)}
        var startDate:String by string(::startDate) {validator(ValiDate)}
        var endDate:String by string(::endDate) {validator(ValiDate)}
        var message:String by string(::message) {validator(ValiRentalMessage)}
        override fun validateEntity(): String? {
            if(sensorList.isEmpty())
                return "Please add non-empty sensorList"
            sensorList.forEach {sensor ->
                val rowid = sensor.rowid.trim()
                if(rowid.isBlank()) return "Sensor rowid cannot be empty or blank"
                val rowidLong = rowid.toLongOrNull() ?: return "Rowid is not an integer value"
                if(rowidLong <= 0L) return "Rowid cannot be zero or negative"

                val qty = sensor.requestQty.trim()
                if(qty.isBlank()) return "Sensor request qty cannot be empty or blank"
                val qtyLong = qty.toLongOrNull() ?: return "Request qty is not an integer value"
                if(qtyLong < 0L) return "Request qty cannot be negative"
            }
            if(sensorList.all{it.requestQty.toLong()==0L}) return "At least one must be above zero"
            return null
        }
    }
    class Res: eEntity(true)

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
    init {
//        isJSON = true
    }
}

//rent list
object EntUserApiRental: EntUserApi<EntUserApiRental.Req, EntUserApiRental.Res>(::Req, ::Res){
    const val PATH = "$USER_API_RENTAL_PATH"
    const val PATH_JSON = "$PATH/json"
    override val rsckeys = "rentallist"

    class Req: eEntity() {
        var page:String by string(::page){
            default("1", false)
            validator(ValiPage)
        }
    }
    class Res: eEntity(true){
        class Meta: EntUserApiPageMeta()

        class Rental: eEntity(){
            var rowid:String by string(::rowid)
            var title:String by string(::title)
            var rentalState: EnumApiRentalState by enum(::rentalState)
            var createDate:String by string(::createDate)
            var period:String by string(::period)
            var sensor:String by string(::sensor)
        }
        val meta: Meta by entity(::meta, ::Meta) {default(Meta(),false)}
        val rentalList: MutableList<Rental> by entityList(::rentalList, ::Rental) { default(mutableListOf(), false)}
    }

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON

}
package mysensors.sensor

import app.hashManager
import senscloud.common.domPage.PageBase
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement
import senscloud.common.entity.api.user.mysensors.EntUserApiMySensors
import senscloud.common.vali.__EMPTY__
import senscloud.user.app.ClientUserApiMySensors

class SensorP(router:eRouter<HTMLElement>): PageBase(::SensorView, router){
    override suspend fun net(data:eRouterData){
        data.parse(EntUserApiMySensors.request())?.also { ent ->
            ClientUserApiMySensors.net{
                it.sensorCatRowid = ent.sensorCatRowid
                it.searchText = __EMPTY__
            }?.also {
                view?.entity(it)
            }
        }?: hashManager.back()
    }
}
package member.subEdit

import Factory
import app.hashManager
import senscloud.common.app.App
import senscloud.common.entity.EntInit
import senscloud.common.entity.api.user.member.EntUserApiMemberSubEp
import senscloud.common.vali.*
import senscloud.user.app.ClientUserApiImageWp
import senscloud.user.app.ClientUserApiMemberSubEp
import senscloud.user.app.RouterKey.SUB_ACCOUNTS
import senscloud.user.entity.member.EntClientMemberSubE
import senscloud.user.entity.member.EntSubEProfile
import comp.CompPageTitle
import comp.compProfileImageListSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
import prop.clearCompValue
import prop.compLabelInputSectionText
import prop.compProfileImageList
import view.CompViewToast

private val factory = Factory.htmlUrl("member/subEditT")
private val profileT = Factory.htmlUrl("common/profileFactoryT")

private enum class K{
    fullName, phone, compProfile,
    backBtn, editBtn,

    ACCOUNTINFO;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

suspend fun SubEditView() = eView(factory){ rootView->
    App.subHtmlFromLabel(rootView,
    K.ACCOUNTINFO to "c@Account info@subaccount_new_edit/title/accountinfo",
    )
    val editForm = EntUserApiMemberSubEp.request()

    CompPageTitle(rootView) {
        it.title = "c@Edit a sub account@subaccount_new_edit/title/editsubaccount"
        it.backClick = { hashManager.goUrl(SUB_ACCOUNTS) }
    }

    rootView.compLabelInputSectionTextSet(K.fullName, "c@Full name@subaccount_new_edit/label/name", ValiMemberFullName)

    rootView.compLabelInputSectionTextSet(K.phone, "c@Phone@subaccount_new_edit/label/phone", ValiMemberPhone)

    rootView.compProfileImageListSet(K.compProfile){
        it.title = "c@Profile image@subaccount_new_edit/title/profileimage"
        it.message = "c@Or select an avatar:@subaccount_new_edit/label/selectprofileimage"
        it.btnLabel = "c@Upload@subaccount_new_edit/button/fileupload"
    }

    rootView.sub(K.backBtn) {
        it.html = "c@Cancel@subaccount_new_edit/button/cancel"
        it.click = {_,_ ->
            eLaunch { hashManager.goUrl(SUB_ACCOUNTS) }
        }
    }
    rootView.sub(K.editBtn) {
        it.html = "c@Edit a sub account@subaccount_new_edit/button/editsubaccount"
        it.click = {_,_ ->
            eLaunch {
                val compFullName = rootView.sub(K.fullName).compLabelInputSectionText()
                val compPhone = rootView.sub(K.phone).compLabelInputSectionText()
                if(App.checkAll(compFullName, compPhone)) {
                    ClientUserApiMemberSubEp.net { req ->
                        req.username = editForm.username
                        req.fullName = compFullName.out()
                        req.phone = compPhone.out()
                        req.profileUrl = editForm.profileUrl
                    }?.also {
                        CompViewToast.open("c@Sub account was edited.@subaccount_new_edit/toast/success/02", "success-badge", CompViewToast.TOAST_TYPE.SUCCESS)
                        hashManager.goUrl(SUB_ACCOUNTS)
                    }
                }
            }
        }
    }
    
    rootView.addEntityHook(EntClientMemberSubE::class, object: eEntityHook<HTMLElement, EntClientMemberSubE> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntClientMemberSubE){
            val entProfile = EntSubEProfile(entity)
            view.entity(entProfile)
            editForm.username = entity.userName
            view.sub(K.fullName).compLabelInputSectionText().inputValue(entity.fullName)
            view.sub(K.phone).compLabelInputSectionText().inputValue(entity.phoneFormat(entity.phone))
        }
    })

    rootView.addEntityHook(EntSubEProfile::class, object:eEntityHook<HTMLElement, EntSubEProfile> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntSubEProfile){
            editForm.profileUrl = entity.profileUrl
            view.sub(K.compProfile).compProfileImageList { comp ->
                comp.setProfileData(entity.profileUrl, entity.profileUrlList)
                comp.setAfterUploaded { file ->
                    eLaunch {
                        ClientUserApiImageWp.uploadNet(file)?.also {
                            comp.setProfileData(it.imageUrl, entity.profileUrlList)
                            editForm.profileUrl = it.imageUrl
                        }
                    }
                }
                comp.setClickItem {
                    editForm.profileUrl = it
                }
            }
        }
    })

    rootView.addEntityHook(EntInit::class, object:eEntityHook<HTMLElement, EntInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntInit){
            view.clearCompValue(K.fullName, K.phone)
        }
    })
}
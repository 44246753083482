package mysensors.sensor

import Factory
import app.hashManager
import comp.*
import comp.input.CompInputText
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import prop.*
import senscloud.common.app.App
import senscloud.common.enm.EnumApiSensorTab
import senscloud.common.entity.EntInit
import senscloud.common.entity.api.user.mysensors.EntUserApiMySensors
import senscloud.common.vali.ValiSearchText
import senscloud.user.app.AppUser
import senscloud.user.app.ClientUserApiMySensors
import senscloud.user.app.RouterKey
import senscloud.user.comp.*
import senscloud.user.entity.mysensors.EntClientMySensorsActive
import senscloud.user.entity.mysensors.EntClientMySensorsAll
import senscloud.user.entity.mysensors.EntClientMySensorsHistory
import senscloud.util.Chart
import senscloud.util.Chart.chart
import senscloud.util.SensorTempInfoList

private val factory = Factory.htmlUrl("mysensors/sensor/sensor")
private val allRecordListT = Factory.htmlUrl("mysensors/sensor/allRecordListT")

private val activeSensorListT = Factory.htmlUrl("mysensors/sensor/activeSensorListT")

private val historySensorListT = Factory.htmlUrl("mysensors/sensor/historySensorListT")

private enum class K {
    nav, searchText, searchBtn, tabs, listWrap, cnt, CNT, listEmpty, infoTitle, infoMinTemp, infoMaxTemp, infoImageUrl,
    section, sensorCode, rental, periodOfUse, batteryReplacedDate, status, seeAllData,
    recordList, historyWrap, historyList, pagination,

    record_sensorCode, record_sensorSessionRowid, record_startDate, record_endDate, record_tempInfo,
    record_graph, record_detail, record_exportPDF, pdfWrap, record_exportExcel;
    override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
}

private suspend fun submit(view: eView<HTMLElement>, request: EntUserApiMySensors.Req) {
    ClientUserApiMySensors.net{ req ->
        req.sensorCatRowid = request.sensorCatRowid
        req.page = request.page
        req.searchText = request.searchText
        req.searchTab = request.searchTab
    }?.also{
        window.scrollTo(0.0, 0.0)
        view.entity(it)
    }
}

suspend fun SensorView() = eView(factory) { rootView ->
    val reqFrom = EntUserApiMySensors.Req()

    CompPageTitle(rootView){}
    rootView.compTabsSet(K.tabs) { comp ->
        comp.wrapperClass = "tab-list"
        comp.afterInited = {
            comp.setList(
                comp.item(EnumApiSensorTab.ALL.label, 0, -1, isSelect = true, isDisabled = false),
                comp.item(EnumApiSensorTab.ACTIVE.label, 1, -1, isSelect = false, isDisabled = false),
                comp.item(EnumApiSensorTab.HISTORY.label, 2, -1, isSelect = false, isDisabled = false)
            )
        }
    }

    App.emptySub(
        rootView, K.searchBtn, K.section, K.cnt, K.CNT, K.listEmpty, K.listWrap, K.infoTitle, K.infoMinTemp, K.infoMaxTemp, K.infoImageUrl, K.historyWrap, K.historyList
    )

    CompViewList(rootView, K.recordList, CompViewList.Companion.EnumCompViewListType.LIST_CARD){
        it.wrapperClass = "margin-top15"
    }

    rootView.compInputSectionOneSet<String>(K.searchText, CompInputText { comp ->
        comp.vali = ValiSearchText
        comp.inputClass = "form-input width4-1"
        comp.placeholder = "c@Search by sensor barcode@sensor/placeholder/search/01"
    }, errorClass = "form-error")

    rootView.compPaginationSet(K.pagination, "page"){}
    val pdfChartId = rootView.compSensorPdfSet(K.pdfWrap)

    rootView.addEntityHook(EntUserApiMySensors.Res::class, object : eEntityHook<HTMLElement, EntUserApiMySensors.Res> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntUserApiMySensors.Res) {
            val totalRows = entity.meta.totalRows
            reqFrom.also {
                it.sensorCatRowid = entity.meta.rowid
                it.page = entity.meta.page
                it.searchText = entity.meta.searchText
                it.searchTab = entity.meta.searchTab
            }
            view.sub(K.nav).compPageTitle().setTitle(entity.info.title)
            view.sub(K.tabs).compTabs { comp ->
                comp.setList(
                    comp.item(EnumApiSensorTab.ALL.label, 0, -1, isSelect = entity.meta.searchTab == EnumApiSensorTab.ALL, isDisabled = false),
                    comp.item(EnumApiSensorTab.ACTIVE.label, 1, -1, isSelect = entity.meta.searchTab == EnumApiSensorTab.ACTIVE, isDisabled = false),
                    comp.item(EnumApiSensorTab.HISTORY.label, 2, -1, isSelect = entity.meta.searchTab == EnumApiSensorTab.HISTORY, isDisabled = false)
                )
                comp.checkBlock = { idx ->
                    eLaunch {
                        when (idx) {
                            0 -> {
                                submit(view, reqFrom.also { it.searchTab = EnumApiSensorTab.ALL })
                            }
                            1 -> {
                                submit(view, reqFrom.also { it.searchTab = EnumApiSensorTab.ACTIVE })
                            }
                            2 -> {
                                submit(view, reqFrom.also { it.searchTab = EnumApiSensorTab.HISTORY })
                            }
                        }
                    }
                }
            }

            val searchText = view.sub(K.searchText).compInputSection<String>()

            view.sub(K.cnt) { it.html = totalRows }
            view.sub(K.CNT) {
                it.html = if(EnumApiSensorTab.ALL == entity.meta.searchTab){
                    if(totalRows.toInt() < 2) "c@record@sensor/title/record/01" else "c@records@sensor/title/record/02"
                }else if(EnumApiSensorTab.ACTIVE == entity.meta.searchTab) {
                    if(totalRows.toInt() < 2) "c@sensor@sensor/title/sensor/01" else "c@sensors@sensor/title/sensor/02"
                }else{
                    if (totalRows.toInt() < 2) "c@history@sensor/title/history/01" else "c@histories@sensor/title/history/02"
                }
            }
            view.sub(K.listEmpty) {
                it.html = "c@Couldn't find any sensor.@sensor/text/emptylist"
                if (totalRows == "0") it.displayBlock() else it.displayNone()
            }
            view.sub(K.listWrap) {
                if (totalRows == "0") it.displayNone() else it.displayBlock()
            }

            view.sub(K.infoImageUrl) { it.image = entity.info.imageUrl }
            view.sub(K.infoTitle) { it.html = entity.info.title }
            view.sub(K.infoMinTemp) { it.html = entity.info.minTemp }
            view.sub(K.infoMaxTemp) { it.html = entity.info.maxTemp }

            view.sub(K.searchBtn) {
                it.click = { _, _ ->
                    eLaunch {
                            val v = if (searchText.check()) searchText.out() else null
                            v?.also { str -> submit(view, reqFrom.also { it.searchText = str }) }
                        }
                    }
                }
            when (entity.meta.searchTab) {
                EnumApiSensorTab.ACTIVE -> {
                    view.entity(EntClientMySensorsActive(entity))
                }
                EnumApiSensorTab.HISTORY -> {
                    view.entity(EntClientMySensorsHistory(entity))
                }
                EnumApiSensorTab.ALL -> {
                    view.entity(EntClientMySensorsAll(entity))
                }
            }
        }
    })

    rootView.addEntityHook(EntClientMySensorsAll::class, object : eEntityHook<HTMLElement, EntClientMySensorsAll> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientMySensorsAll) {
            view.sub(K.recordList).displayBlock()
            view.sub(K.historyWrap).displayNone()

            Chart.chartIdDataMapInit()
            view.sub(K.recordList).compViewList().setList(entity.recordList, allRecordListT){ d, v ->
                val sensorTempInfoList = SensorTempInfoList().also{ sData->
                    sData.itemConditionMinMax = mutableListOf(Chart.tempFloat(entity.info.minTemp), Chart.tempFloat(entity.info.maxTemp))
                    sData.list = mutableListOf(d.tempInfo)
                    sData.isSmall = true
                }

                App.subHtmlFromEntity(v, d, K.record_startDate, K.record_endDate)
                v.compSensorChartTempInfoSet(
                    K.record_tempInfo,
                    CompSensorChartTempInfo.Companion.Item(entity.info.minTemp, entity.info.maxTemp, d.minTemp, d.maxTemp, d.lastTemp)
                )
                v.sub(K.record_sensorCode).html = d.sensorCode
                v.sub(K.record_sensorSessionRowid) {
                    it.attr("v0" to d.sensorSessionRowid)
                    it.html = "c@Record #@sensor/title/cnt/record/01"
                }

                v.compSensorPdfBtnSet(K.record_exportPDF, "c@Download PDF@sensor/button/downloadpdf", d.sensorSessionRowid, pdfChartId, sensorTempInfoList)
                v.compSensorExcelSet(K.record_exportExcel, "c@Download Excel@sensor/button/downloadexcel", d.sensorSessionRowid)
                v.sub(K.record_detail){
                    it.html = "c@See details@sensor/button/detail"
                    it.click = { _,_-> eLaunch{ hashManager.goUrl(RouterKey.MYSENSOR_DATADETAIL, "rowid" to d.sensorSessionRowid) } }
                }

                val chartId = Chart.id
                Chart.chartIdDataMapAdd(chartId, sensorTempInfoList)
                v.sub(K.record_graph).chart(chartId)
            }
            Chart.chartIdDataMapDraw()

            view.sub(K.pagination).compPagination { compPage ->
                AppUser.setPage(compPage, entity.meta)
                compPage.clickEvent = { page -> submit(view, reqFrom.also { it.page = "$page" }) }
            }
        }
    })

    rootView.addEntityHook(EntClientMySensorsActive::class, object : eEntityHook<HTMLElement, EntClientMySensorsActive> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientMySensorsActive) {
            view.sub(K.recordList).displayBlock()
            view.sub(K.historyWrap).displayNone()

            Chart.chartIdDataMapInit()
            view.sub(K.recordList).compViewList().setList(entity.sensorList, activeSensorListT){ d, v ->
                App.subHtmlFromEntity(v, d, K.sensorCode, K.rental, K.periodOfUse, K.batteryReplacedDate)
                v.sub(K.seeAllData) {
                    it.html = "c@See all data@sensor/title/seeAllData"
                    it.click = { _, _ ->
                        hashManager.goUrl(RouterKey.MYSENSOR_DATALIST, "r" to d.rowid)
                    }
                }
                v.sub(K.record_sensorCode).html = d.sensorCode
                if(d.tempInfo.cnt==0) {
                     v.sub(K.record_graph) {
                        it.className = "noRecords"
                        it.html = "c@No records@sensor/title/norecords"
                    }
                }
                else {
                    val chartId = Chart.id
                    val sensorTempInfoList = SensorTempInfoList().also{ sData->
                        sData.itemConditionMinMax = mutableListOf(Chart.tempFloat(entity.info.minTemp), Chart.tempFloat(entity.info.maxTemp))
                        sData.list = mutableListOf(d.tempInfo)
                        sData.isSmall = true
                    }
                    Chart.chartIdDataMapAdd(chartId, sensorTempInfoList)
                    v.sub(K.record_graph).chart(chartId)
                }
            }
            Chart.chartIdDataMapDraw()

            view.sub(K.pagination).compPagination { compPage ->
                AppUser.setPage(compPage, entity.meta)
                compPage.clickEvent = { page -> submit(view, reqFrom.also { it.page = "$page" }) }
            }
        }
    })

    rootView.addEntityHook(EntClientMySensorsHistory::class, object : eEntityHook<HTMLElement, EntClientMySensorsHistory> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientMySensorsHistory) {
            view.sub(K.recordList).displayNone()
            view.sub(K.historyWrap).displayBlock()
            view.sub(K.historyList).setClearList { list ->
                entity.sensorList.forEach { sensor ->
                    list += eView(historySensorListT) { itemView ->
                        itemView.sub(K.sensorCode) { it.html = sensor.code }
                        itemView.sub(K.rental) { it.html = sensor.rental }
                        itemView.sub(K.periodOfUse) { it.html = sensor.periodOfUse }
                        itemView.sub(K.status) {
                            it.html = sensor.status.label
                            it.className = sensor.status.className
                        }
                        itemView.sub(K.seeAllData) {
                            it.html = "c@See all data@sensor/title/seeAllData"
                            it.click = { _, _ ->
                                hashManager.goUrl(RouterKey.MYSENSOR_DATALIST, "r" to sensor.rowid)
                            }
                        }
                    }
                }
            }
            view.sub(K.pagination).compPagination { compPage ->
                AppUser.setPage(compPage, entity.meta)
                compPage.clickEvent = { page -> submit(view, reqFrom.also { it.page = "$page" }) }
            }
        }
    })

    rootView.addEntityHook(EntInit::class, object : eEntityHook<HTMLElement, EntInit> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntInit) {
            view.clearCompValue(K.searchText)
            view.sub(K.tabs).compTabs { comp ->
                comp.setList(
                    comp.item(EnumApiSensorTab.ACTIVE.label, 0, -1, isSelect = true, isDisabled = false), comp.item(EnumApiSensorTab.HISTORY.label, 1, -1, isSelect = false, isDisabled = false)
                )
            }
        }
    })
}
package member.subInfo

import senscloud.common.domPage.PageBase
import senscloud.user.app.ClientUserApiMemberSubInfoE
import senscloud.user.entity.member.EntClientMemberSubInfoE
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement

class MyInfoSubP(router:eRouter<HTMLElement>): PageBase(::MyInfoSubView, router){
    override suspend fun net(data:eRouterData){
        ClientUserApiMemberSubInfoE.net()?.also {
            view?.entity(EntClientMemberSubInfoE(it))
        }
    }
}
package senscloud.user.comp

import comp.Comp
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import senscloud.common.entity.api.user.mysensors.EntUserApiMySensorsAllDataExcel
import senscloud.user.app.ClientUserApiMySensorsAllDataExcel

suspend fun eView<HTMLElement>.compSensorExcelSet(subKey:Any, label:String, sensorSessionRowid:String, block:((CompSensorExcel)->Unit)? = null){
    CompSensorExcel(this, subKey, label, sensorSessionRowid, block)
}
class CompSensorExcel:Comp{
    companion object{
        suspend operator fun invoke(rootView:eView<HTMLElement>, subKey:Any, label:String, sensorSessionRowid:String, block:((CompSensorExcel)->Unit)? = null){
            val comp = CompSensorExcel()
            comp.target = rootView.sub(subKey){
                it.html = label
                it.disabled = false
                it.className = "btn border flex-grow-1 margin-left15"
                it.click = {e,_ ->
                    e.stopImmediatePropagation()
                    comp.target.opacity = 0.5
                    comp.click()
                }
            }

            block?.invoke(comp) ?: also{
                comp.createApi = {
                    ClientUserApiMySensorsAllDataExcel.net{
                        it.rowid = sensorSessionRowid
                    }
                }
            }
        }
    }
    lateinit var createApi:(suspend ()-> EntUserApiMySensorsAllDataExcel.Res?)
    lateinit var target:eView<HTMLElement>
    var clickEnd:((String)->Unit)? = null
    fun targetInit(){
        target.opacity = 1.0
    }
    fun click(){
        eLaunch{
            createApi()?.also{
                targetInit()
                clickEnd?.invoke(it.fileUrl) ?: window.open(it.fileUrl, "_blank")
            } ?: targetInit()
        }
    }
}
package member.subEdit

import senscloud.common.domPage.PageBase
import senscloud.common.entity.api.user.member.EntUserApiMemberSubE
import senscloud.user.app.ClientUserApiMemberSubE
import senscloud.user.entity.member.EntClientMemberSubE
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement

class SubEditP(router:eRouter<HTMLElement>): PageBase(::SubEditView, router){
    override suspend fun net(data:eRouterData){
        data.parse(EntUserApiMemberSubE.request())?.also{ ent ->
            ClientUserApiMemberSubE.net {req ->
                req.username = ent.username
            }?.also {
                view?.entity(EntClientMemberSubE(it))
            }
        }
    }
}
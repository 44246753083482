package senscloud.common.vali

import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali
import senscloud.common.enm.EnumApiAddressSort

val ValiAddressSort = eRuleVali{
    Case {
        Rule("address sort type을 잘못 입력했습니다.") {
            EnumApiAddressSort.values().firstOrNull{it.name=="$it"}?.name ?: eRuleSet.FALSE
        }
    }
}

val ValiAddressName = eRuleVali{
    Case {
        String()
        Trim()
        MinLength(1, "c@Enter a location name.@common/vali/addrname/01")
        MaxLength(32, "c@Location name must be under 32 characters.@common/vali/addrname/02")
    }
}

val ValiAddressAddress = eRuleVali{
    val msg = ""
    Case {
        String()
        Trim()
        MinLength(1, msg)
        MaxLength(1024, msg)
    }
}
val ValiAddressPhone = eRuleVali{
    Case{
        String()
        Trim()
        Empty()
    }
    Case{
        String()
        Trim()
        Rule{ "$it".replace("-","").replace(" ", "") }
        MinLength(1, "c@Enter your phone number.@myinfo/vali/phone/01")
        Rule("c@Invalid phone number format.@myinfo/vali/phone/02"){ _valiPhoneCheck(it) }
    }
}
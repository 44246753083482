package senscloud.user.entity.mysensors

import GoogleMapModal.default
import ein2b.core.entity.eEntity
import ein2b.core.entity.field.enum.enum
import senscloud.common.enm.EnumApiSensorStatus
import senscloud.common.entity.api.user.common.EntTempInfo
import senscloud.common.entity.api.user.common.EntUserApiPageMeta
import senscloud.common.entity.api.user.mysensors.EntUserApiMySensors

class EntClientMySensorsAll(target: EntUserApiMySensors.Res) : eEntity() {
    class Info : eEntity(true) {
        var minTemp: String by string(::minTemp)
        var maxTemp: String by string(::maxTemp)
    }
    class Record : eEntity(true) {
        var sensorCode:String by string(::sensorCode) //211111000243
        var sensorSessionRowid:String by string(::sensorSessionRowid) //#2
        var startDate:String by string(::startDate) //2022-04-14(Thu) 5:00 PM
        var endDate:String by string(::endDate) //2022-04-14(Thu) 7:00 PM
        var minTemp:String by string(::minTemp) //5.1°C
        var maxTemp:String by string(::maxTemp) //7.0°C
        var lastTemp:String by string(::lastTemp) //6.0°C
        var tempInfo: EntTempInfo by entity(::tempInfo, ::EntTempInfo) { default(EntTempInfo(), false)}
    }
    var meta: EntUserApiPageMeta by entity(::meta, ::EntUserApiPageMeta) {
        default(
            EntUserApiPageMeta().also {
                it.page = target.meta.page
                it.totalRows = target.meta.totalRows
                it.pagePerGroup = target.meta.pagePerGroup
                it.rowPerPage = target.meta.rowPerPage
            }
            ,false)
    }
    var info:Info by entity(::info, ::Info) {
        default(
            Info().also {
                it.minTemp = target.info.minTemp
                it.maxTemp = target.info.maxTemp
            }
        )
    }
    var recordList: MutableList<Record> by entityList(::recordList,
        EntClientMySensorsAll::Record
    ) {
        default(
            target.recordList.map { record ->
                Record().also {
                    it.sensorCode = record.sensorCode
                    it.sensorSessionRowid = record.sensorSessionRowid
                    it.startDate = record.startDate
                    it.endDate = record.endDate
                    it.minTemp = record.minTemp
                    it.maxTemp = record.maxTemp
                    it.lastTemp = record.lastTemp
                    it.tempInfo = record.tempInfo
                }
            }.toMutableList(), false)
    }
}
class EntClientMySensorsActive(target: EntUserApiMySensors.Res) : eEntity() {
    class Info : eEntity(true) {
        var minTemp: String by string(::minTemp)
        var maxTemp: String by string(::maxTemp)
    }
    class Sensor : eEntity(true) {
        var rowid: String by string(::rowid)
        var sensorCode: String by string(::sensorCode) //211111000243
        var rental: String by string(::rental) //10
        var periodOfUse: String by string(::periodOfUse) //2022-02-01 ~ 2022-07-31
        var batteryReplacedDate: String by string(::batteryReplacedDate) //2021-06-01
        var status: EnumApiSensorStatus by enum(::status) // History에서의 - Active, InAcitve
        var tempInfo: EntTempInfo by entity(::tempInfo, ::EntTempInfo) { default(EntTempInfo(), false)}
    }
    var info: Info by entity(::info, ::Info) {
        default(
            Info().also {
                it.minTemp = target.info.minTemp
                it.maxTemp = target.info.maxTemp
            }
        )
    }

    var meta: EntUserApiPageMeta by entity(::meta, ::EntUserApiPageMeta) {
        default(
            EntUserApiPageMeta().also {
                it.page = target.meta.page
                it.totalRows = target.meta.totalRows
                it.pagePerGroup = target.meta.pagePerGroup
                it.rowPerPage = target.meta.rowPerPage
            }
        ,false)
    }
    var sensorList: MutableList<Sensor> by entityList(::sensorList, ::Sensor) {
        default(
            target.sensorList.map { sensor ->
                Sensor().also {
                    it.rowid = sensor.rowid
                    it.sensorCode = sensor.code
                    it.rental = sensor.rental
                    it.periodOfUse = sensor.periodOfUse
                    it.batteryReplacedDate = sensor.batteryReplacedDate
                    it.status = sensor.status
                    it.tempInfo = sensor.tempInfo
                }
            }.toMutableList()
        , false)
    }
}

class EntClientMySensorsHistory(target: EntUserApiMySensors.Res) : eEntity() {
    class Sensor : eEntity(true) {
        var rowid:String by string(::rowid)
        var code:String by string(::code)
        var rental:String by string(::rental)
        var periodOfUse:String by string(::periodOfUse)
        var status: EnumApiSensorStatus by enum(::status)
    }
    var meta: EntUserApiPageMeta by entity(::meta, ::EntUserApiPageMeta) {
        default(
            EntUserApiPageMeta().also {
                it.page = target.meta.page
                it.totalRows = target.meta.totalRows
                it.pagePerGroup = target.meta.pagePerGroup
                it.rowPerPage = target.meta.rowPerPage
            }
        ,false)
    }

    var sensorList: MutableList<Sensor> by entityList(::sensorList, ::Sensor) {
        default(
            target.sensorList.map { sensor ->
                Sensor().also {
                    it.rowid = sensor.rowid
                    it.code = sensor.code
                    it.rental = sensor.rental
                    it.periodOfUse = sensor.periodOfUse
                    it.status = sensor.status
                }
            }.toMutableList()
            ,false)
    }
}

package mysensors.recordlist

import Factory
import app.hashManager
import comp.CompPageTitle
import comp.CompViewList
import comp.compPaginationSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import prop.compPageTitle
import prop.compPagination
import prop.compViewList
import senscloud.common.app.App
import senscloud.common.entity.api.user.mysensors.EntUserApiMySensorsAllData
import senscloud.user.app.AppUser
import senscloud.user.app.ClientUserApiMySensorsAllData
import senscloud.user.app.RouterKey
import senscloud.user.comp.*
import senscloud.util.Chart
import senscloud.util.Chart.chart
import senscloud.util.SensorTempInfoList

private val factory = Factory.htmlUrl("mysensors/recordlist/recordList")
private val sensorFactory = Factory.htmlUrl("mysensors/recordlist/recordT")

private enum class K{
    nav, section, emptySection, submit, recordList, date, pdfWrap, cnt,

    record_title, record_startDate, record_endDate, record_tempInfo,
    record_exportPDF, record_exportExcel, record_detail, record_graph,

    page;

    override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
}

suspend fun RecordListView() = eView(factory){ rootView ->
    CompPageTitle(rootView){ it.backClick = { eLaunch{ hashManager.back() } } }
    App.emptySub(rootView, K.section, K.cnt, K.submit, K.recordList)
    rootView.compPaginationSet(K.page, "page"){}
    rootView.sub(K.date){ it.displayNone() }
    rootView.sub(K.emptySection).html = "c@Couldn't find any record.@recordlist/text/emptylist"

    CompViewList(rootView, K.recordList, CompViewList.Companion.EnumCompViewListType.LIST_CARD){
        it.wrapperClass = "margin-top15"
    }
    val pdfChartId = rootView.compSensorPdfSet(K.pdfWrap)

    rootView.addEntityHook(EntUserApiMySensorsAllData.Res::class, object:eEntityHook<HTMLElement, EntUserApiMySensorsAllData.Res>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntUserApiMySensorsAllData.Res){
            view.sub(K.nav).compPageTitle{ pageTitle -> pageTitle.setTitle(entity.title) }
            if(entity.recordList.isEmpty()){
                view.sub(K.section).displayNone()
                view.sub(K.emptySection).displayBlock()
            }else{
                view.sub(K.section).displayBlock()
                view.sub(K.emptySection).displayNone()
            }
            view.sub(K.cnt){
                it.attr("v0" to entity.meta.totalRows)
                it.html = if(entity.meta.totalRows.toInt()<2) "c@record@recordlist/title/cnt" else "c@records@recordlist/title/cnt2"
            }

            Chart.chartIdDataMapInit()
            view.sub(K.recordList).compViewList().setList(entity.recordList, sensorFactory){d, v ->
                val sensorTempInfoList = SensorTempInfoList().also{ sData->
                    sData.itemConditionMinMax = mutableListOf(Chart.tempFloat(entity.minTemp), Chart.tempFloat(entity.maxTemp))
                    sData.list = mutableListOf(d.tempInfo)
                    sData.isSmall = true
                }

                App.subHtmlFromEntity(v, d, K.record_startDate, K.record_endDate)
                v.compSensorChartTempInfoSet(
                    K.record_tempInfo,
                    CompSensorChartTempInfo.Companion.Item(entity.minTemp, entity.maxTemp, d.minTemp, d.maxTemp, d.lastTemp)
                )
                v.sub(K.record_title){
                    it.attr("v0" to d.title)
                    it.html = "c@Record #@recordlist/text/record/title"
                }
                v.compSensorPdfBtnSet(K.record_exportPDF, "c@Download PDF@recordlist/button/downloadpdf", d.rowid, pdfChartId, sensorTempInfoList)
                v.compSensorExcelSet(K.record_exportExcel, "c@Download Excel@recordlist/button/downloadexcel", d.rowid)
                v.sub(K.record_detail){
                    it.html = "c@See details@recordlist/button/detail"
                    it.click = { _,_-> eLaunch{ hashManager.goUrl(RouterKey.MYSENSOR_DATADETAIL, "rowid" to d.rowid) } }
                }

                val chartId = Chart.id
                Chart.chartIdDataMapAdd(chartId, sensorTempInfoList)
                v.sub(K.record_graph).chart(chartId)
            }
            Chart.chartIdDataMapDraw()

            view.sub(K.page).compPagination{ page ->
                AppUser.setPage(page, entity.meta)
                page.clickEvent = { idx ->
                    ClientUserApiMySensorsAllData.net{ req ->
                        req.page = "$idx"
                        req.rowid = entity.meta.rowid
                    }?.also{
                        window.scrollTo(0.0,0.0)
                        view.entity(it)
                    }
                }
            }
        }
    })
}

package rental.rentallist

import senscloud.common.domPage.PageBase
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement
import senscloud.user.app.ClientUserApiRental

class RentalListP(router:eRouter<HTMLElement>): PageBase(::RentalListView, router){
    override suspend fun net(data:eRouterData){
        ClientUserApiRental.net{}?.let {
            view?.entity(it)
        }
    }
}
package comp.input

import Factory
import comp.OutType
import comp.input.CompInput.Companion.CONV
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eVali
import ein2b.core.view.*
import ein2b.js.js.eDate
import org.w3c.dom.HTMLElement
import kotlin.js.Date

/* ********************* CompInputDate 사용법 *********************
val date = CompInputDate{
    it.wrapperClass = "input-date"          // 날짜 인풋창 스타일 변경 : it.wrapperClass = "input-date {추가className}"
    it.dayWeekList = listOf("일", "Mon", "TUE", "WED", "THU", "FRI", "SAT")      // 날짜 선택창 요일 타이틀 변경
    it.ymPattern = "y.m"            // 날짜 선택창 상단 날짜 포맷 변경
    it.ymdPattern = "Y/m/d(w)"      // 날짜 선택창에서 날짜 선택 시 인풋값에 나타나는 날짜 포맷을 변경
    it.isDisabled = true            // 날짜 선택창 비활성화
}
-date.clear() 하면 초기화

 */
/* **************** 날짜 비교하기 ***********************************
val startDate = CompInputDate{
        it.wrapperClass = "input-date"
        it.ymPattern = "y.m"
        it.vali = eRuleVali{
            Case{
                Trim()
                Rule("등록 시간을 다시 입력하세요"){ str ->
                    if("$str".isNotBlank()) str else eRuleSet.FALSE
                }
                Rule("오늘 이후 날짜로 입력하세요"){ str ->
                    if("$str" > eDate.part("Y/m/d(w)", Date())) str else eRuleSet.FALSE
                }
            }
        }
    }
    val endDate = CompInputDate{
        it.wrapperClass = "input-date"
        it.ymPattern = "y.m"
        it.vali = eRuleVali{
            Case{
                Trim()
                Rule("등록 시간을 다시 입력하세요"){ str ->
                    if("$str".isNotBlank()) str else eRuleSet.FALSE
                }
                Rule("시작날짜보다 이후로 입력하세요"){ str ->
                    if("$str" > startDate.value.value) str else eRuleSet.FALSE
                }
            }
        }
    }
    val dateList = rootView.compInputSection("date6", errorClass = "form-error") {
        listOf( startDate, endDate )
    }

 (1)시작 날짜 ~ 종료 날짜 비교하기
       1) list 밖에서 각각 date 컴포넌트 만들기
       2) rule 만들 때 startDate.value.value 비교
       3) compInputSection.listOf 에 넣기
 (2)오늘 날짜와 컴포넌트 value 비교하기
       1) 형식을 맞춰서 비교할 것!
       2) eDate.part("Y/m/d(w)", Date())
 */


class CompInputDate:CompInput<String,String,String>{
    companion object{
        private val LIST = mutableListOf<CompInputDate>()
        private const val CLASS_NAME = "CompInputDate"
        private const val WRAPPER = "${CLASS_NAME}_wrapper"
        private const val DATE_WRAPPER = "${CLASS_NAME}_date_wrapper"
        private const val DATE = "${CLASS_NAME}_date"
        private const val CALENDAR = "${CLASS_NAME}_calendar"
        private const val CALENDAR_CLOSE = "${CALENDAR}_close"
        private const val CALENDAR_PREV = "${CALENDAR}_prev"
        private const val CALENDAR_YM = "${CALENDAR}_ym"
        private const val CALENDAR_NEXT = "${CALENDAR}_next"
        private const val CALENDAR_DAY_WEEK_LIST = "${CALENDAR}_day_week_list"
        private const val CALENDAR_DAY_LIST = "${CALENDAR}_day_list"
        private const val ERROR_CLASS:String = "error"
        private const val SELECTED_CLASS:String = "selected"
        private const val DISABLED_CLASS:String = "disabled"
        private val dayFactory = Factory.html("""<li data-view=""></li>""")
        private enum class DAY_TYPE{
            NONE{ override fun html(v:String) = v },
            SELECTED{ override fun html(v:String) = """<span class="selected">$v</span>""" },
            TODAY{ override fun html(v:String) = """<span class="today">$v</span>""" };
            open fun html(v:String) = ""
        }
        private fun dateAddMonthLastDay(sDate:Date, addDay:Int = 1) = eDate.addDay(-1, eDate.addMonth(addDay, sDate))
        private class DayValue(var value:String, var title:String, var className:String, var dayType: DAY_TYPE)

        operator fun invoke(block:(CompInputDate) -> Unit):CompInputDate{
            val comp = CompInputDate()
            LIST.add(comp)
            block(comp)
            eWindow.addClick{ eLaunch{ if(comp.isOpen) comp.calendarClose() } }
            return comp
        }
    }
    override lateinit var value:CompValue<String, String>
    override var errorListener:((Boolean, String)->Unit)? = null
    override var vali:eVali? = null

    lateinit var target:eView<HTMLElement>
    override val factory:suspend ()->HTMLElement = Factory.html("""
<div data-view="$WRAPPER">
    <div data-view="$DATE_WRAPPER" class="date-wrapper">
        <div class="calendar-icon"></div>
        <div data-view="$DATE" class="date"></div>
    </div>
    <div data-view="$CALENDAR" class="calendar">
        <div data-view="$CALENDAR_CLOSE" class="calendar-close"></div>
        <div class="calendar-header">
            <div data-view="$CALENDAR_PREV" class="calendar-prev"></div>
            <span data-view="$CALENDAR_YM" class="calendar-ym"></span>
            <div data-view="$CALENDAR_NEXT" class="calendar-next"></div>
        </div>
        <ul data-view="$CALENDAR_DAY_WEEK_LIST" class="calendar-day-week-list"></ul>
        <ul data-view="$CALENDAR_DAY_LIST" class="calendar-day-list"></ul>
    </div>
</div>""")
    override suspend fun init(it:eView<HTMLElement>){
        target = it
        today = eDate.part(ymdPattern, Date())
        if(default.isBlank()) default = today
        target.sub(WRAPPER).className = wrapperClass
        target.sub(DATE_WRAPPER).click = { e, _->
            if(!isDisabled){
                e.stopImmediatePropagation()
                e.stopPropagation()
                eLaunch{
                    LIST.forEach{ it.calendarClose() }
                    calendarOpen(dateValue())
                }
            }
        }
        target.sub(DATE).html = default
        target.sub(CALENDAR).displayNone()
        target.sub(CALENDAR_CLOSE).click = { _, _-> eLaunch{ calendarClose() } }
        target.sub(CALENDAR_PREV).click = { e, _->
            e.stopImmediatePropagation()
            e.stopPropagation()
            eLaunch{ prev() }
        }
        target.sub(CALENDAR_YM)
        target.sub(CALENDAR_NEXT).click = { e, _->
            e.stopImmediatePropagation()
            e.stopPropagation()
            eLaunch{ next() }
        }
        target.sub(CALENDAR_DAY_WEEK_LIST).setList{
            dayWeekList.mapIndexed{ idx, day->
                it += eView(dayFactory){
                    it.html = day
                    it.className = if(idx == 0) "weekend" else ""
                }
            }
        }
        target.sub(CALENDAR_DAY_LIST)

        value = CompValue("", "", vali, errorListener, CONV){
            eLaunch{ target.sub(DATE).html = it }
        }
        if(notSelectedDate) target.sub(DATE).html = placeholder
        else value.inputValue(default)
    }

    var isDisabled = false
    var wrapperClass = "input-date"
    var dayWeekList = listOf("일", "월", "화", "수", "목", "금", "토")
    var ymPattern = "Y/m"
    var ymdPattern = "Y/m/d(w)"
    private var today = eDate.part(ymdPattern, Date())
    var default = ""
    private var isOpen = false

    private suspend fun dateValue() = if(notSelectedDate) default else target.sub(DATE).html ?: default
    private suspend fun prev() = prevNext(-1)
    private suspend fun next() = prevNext(1)
    private suspend fun prevNext(addMonth:Int) = calendarOpen(eDate.part(ymdPattern, eDate.addMonth(addMonth, Date("${target.sub(
        CALENDAR_YM
    ).html}-01"))))

    private suspend fun calendarOpen(calendarYmd:String){
        isOpen = true
        target.className = setClassName(SELECTED_CLASS)
        val ymdDate = Date(calendarYmd)
        target.sub(CALENDAR_YM).html = eDate.part(ymPattern, ymdDate)
        val dateYmd = eDate.part(ymdPattern, Date(dateValue()))
        val daysArr = mutableListOf<DayValue>()
        fun daysArrAdd(sIdx:Int, eIdx:Int, oldDate:Date, isCurrMonth:Boolean = false){
            for(i in sIdx..eIdx){
                val date = eDate.addDay(i, oldDate)
                val ymd = eDate.part(ymdPattern, date)
                val dayType = when(ymd){
                    dateYmd -> DAY_TYPE.SELECTED
                    today -> DAY_TYPE.TODAY
                    else -> DAY_TYPE.NONE
                }
                daysArr.add(DayValue(ymd, eDate.part("j", date), if(isCurrMonth) "" else "other", dayType))
            }
        }

        val year = eDate.part("Y", ymdDate).toInt()
        val month = eDate.part("m", ymdDate).toInt()

        val startDate = Date(year, month-1, 1)
        val sWeek = startDate.getDay()
        if(sWeek > 0) daysArrAdd(sWeek*-1, -1, startDate)

        val endDate = dateAddMonthLastDay(startDate)
        val endDay = eDate.part("j", endDate).toInt()
        daysArrAdd(0, endDay-1, startDate, true)

        val eWeek = endDate.getDay()
        if(eWeek < 6) daysArrAdd(1, 6-eWeek, endDate)

        target.sub(CALENDAR_DAY_LIST){ view ->
            view.setClearList{ list ->
                daysArr.mapIndexed{ idx, d ->
                    list += eView(dayFactory){
                        it.html = d.dayType.html(d.title)
                        it.className = "${d.className}${if(idx%7 == 0) " weekend" else ""}"
                        it.click = { e,_->
                            e.stopImmediatePropagation()
                            e.stopPropagation()
                            eLaunch{
                                notSelectedDate = false
                                value.inputValue(d.value)
                                checkBlock?.invoke(d.value)
                                calendarClose()
                            }
                        }
                    }
                }
            }
        }

        target.sub(CALENDAR).displayBlock()
    }
    private suspend fun calendarClose(){
        isOpen = false
        target.sub(CALENDAR).displayNone()
        target.className = "$wrapperClass ${if(isDisabled) " $DISABLED_CLASS" else ""}"
    }
    fun displayBlock(){ target.displayBlock() }
    fun displayNone(){ target.displayNone() }

    fun enable(v:Boolean){
        isDisabled = !v
        target.className = "$wrapperClass ${if(isDisabled) " $DISABLED_CLASS" else ""}"
    }
    override suspend fun error(isOk:Boolean){
        target.className = setClassName(if(isOk) "" else ERROR_CLASS)
    }
    private fun setClassName(cls:String):String = "$wrapperClass ${if(isDisabled) DISABLED_CLASS else cls}"
    override suspend fun clear(){
        value.isOk = true
        value.inputValue(default)
        enable(true)
    }
    var checkBlock:((v:String)->Unit)? = null
    var notSelectedDate = false
    override var placeholder = ""
    override val outs: HashMap<OutType, suspend () -> String> = hashMapOf(OutType.DEFAULT to { value.value })
}
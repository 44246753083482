package shipment.detail

import senscloud.common.domPage.PageBase
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement
import senscloud.common.entity.api.user.shipment.*
import senscloud.user.app.ClientUserApiShipmentV
import senscloud.user.entity.shipping.EntClientShipmentDetail

class DetailP(router:eRouter<HTMLElement>): PageBase(::DetailView, router){
    override suspend fun net(data:eRouterData){
        data.parse(EntUserApiShipmentV.request())?.also { ent ->
            ClientUserApiShipmentV.net {
                it.shipmentRowid = ent.shipmentRowid
            }?.also { res ->
                view?.entity(EntClientShipmentDetail(res))
            }
        }
    }
}
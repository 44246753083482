package senscloud.common.entity.api.user.member

import ein2b.core.entity.eEntity
import senscloud.common.enm.EnumApiAccessAllow
import senscloud.common.entity.api.user.common.EntUserApi
import senscloud.common.entity.api.user.common.USER_API_MEMBER_PATH
import senscloud.common.vali.*

//회원가입
object EntUserApiMemberMasterJoinWp: EntUserApi<EntUserApiMemberMasterJoinWp.Req, EntUserApiMemberMasterJoinWp.Res>(::Req, ::Res){
    const val PATH = "$USER_API_MEMBER_PATH/master/join/wp"
    const val PATH_JSON = "$PATH/json"
    class Req: eEntity(){
        var rsaId:String by string(::rsaId)
        var username:String by string(::username){validator(ValiMemberUserName)}
        var pw:String by string(::pw) //view 단에서 rsa로 변환해서 주기 때문에 밸리 걸 수 없음
        var fullName:String by string(::fullName){validator(ValiMemberFullName)}
        var phone:String by string(::phone){validator(ValiMemberPhone)}
        var companyName:String by string(::companyName){validator(ValiMasterCompanyName)}
        var email:String by string(::email){validator(ValiMasterEmail)}
    }
    open class Res: eEntity(){
        companion object{
            private val ERROR = Error()
        }
        class Error: eEntity(true){
            // - 아이디: 이미 등록됨 -> "이미 사용 중인 아이디입니다."
            var username:String by string(::username)
        }
        var username:String by string(::username){default("")}
        var fullName:String by string(::fullName){default("")}
        var phone:String by string(::phone){default("")}
        var companyName:String by string(::companyName){default("")}
        var email:String by string(::email){default("")}
        var error: Error by entity(::error, ::Error){
            default(ERROR, true)
        }
        val isError get() = error !== ERROR
    }
    override val accessAllow get() = EnumApiAccessAllow.LOGOUT
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val rsckeys = "signup"
}

//내정보 수정 준비(마스터)
object EntUserApiMemberMasterInfoE: EntUserApi<EntUserApiMemberMasterInfoE.Req, EntUserApiMemberMasterInfoE.Res>(::Req, ::Res){
    const val PATH = "$USER_API_MEMBER_PATH/master/info/e"
    const val PATH_JSON = "$PATH/json"

    class Req: eEntity()
    class Res: eEntity(){
        var fullName:String by string(::fullName)
        var phone:String by string(::phone)
        var companyName:String by string(::companyName)
        var email:String by string(::email)
        var profileUrl:String by string(::profileUrl)
        var profileUrlList: MutableList<String> by stringList(::profileUrlList){
            default(mutableListOf(), false)
        }
    }
    override val rsckeys = "myinfo"
    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
}

//내정보 수정(마스터)
object EntUserApiMemberMasterInfoEp: EntUserApi<EntUserApiMemberMasterInfoEp.Req, EntUserApiMemberMasterInfoEp.Res>(::Req, ::Res){
    const val PATH = "$USER_API_MEMBER_PATH/master/info/ep"
    const val PATH_JSON = "$PATH/json"

    class Req: eEntity(){
        var fullName:String by string(::fullName){validator(ValiMemberFullName)}
        var phone:String by string(::phone){validator(ValiMemberPhone)}
        var companyName:String by string(::companyName){validator(ValiMasterCompanyName)}
        var email:String by string(::email){validator(ValiMasterEmail)}
        var profileUrl:String by string(::profileUrl){validator(ValiMemberProfileUrl)}
    }
    class Res: eEntity()
    override val rsckeys = "myinfo"
    override val accessAllow get() = EnumApiAccessAllow.MASTER
    override val path = PATH
    override val jsonPath = PATH_JSON
}
package rental.rentalDetail

import Factory
import app.hashManager
import comp.CompPageTitle
import comp.compTabsSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view. eEntityHook
import ein2b.core.view.eView
import org.w3c.dom.HTMLElement
import prop.compPageTitle
import prop.compTabs
import senscloud.common.entity.EntInit
import senscloud.common.pageView.rentalDetail.K
import senscloud.common.pageView.rentalDetail.rentalDetailView
import senscloud.user.entity.rental.EntClientUserRentalV

private val factory = Factory.htmlUrl("rental/detail/rentalDetail")

private val factory_Tab1 = Factory.htmlUrl("rental/detail/rentalDetail_TAB1")
private val sensorListT = Factory.htmlUrl("rental/detail/tab1_sensorListT")

private val factory_Tab2 = Factory.htmlUrl("rental/detail/rentalDetail_TAB2")
private val receiveListT = Factory.htmlUrl("rental/detail/tab2_receiveListT")
private val barcodeListT = Factory.htmlUrl("rental/detail/tab2_barcodeListT")

suspend fun RentalDetailView() = rentalDetailView(factory, factory_Tab1, sensorListT, factory_Tab2, receiveListT, barcodeListT){ rootView->
    CompPageTitle(rootView){ it.backClick = { eLaunch { hashManager.back() } } }
    rootView.compTabsSet<Int>(K.stateTab) { it.wrapperClass = "tab-list" }

    rootView.addEntityHook(EntClientUserRentalV::class, object: eEntityHook<HTMLElement, EntClientUserRentalV> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientUserRentalV){
            view.sub(K.stateTab).compTabs<Int> { stateTab ->
                stateTab.setList(
                    stateTab.item("c@Info@rentallist_detail/tab/request", 0, -1, isSelect = true, isDisabled = false),
                    stateTab.item("c@Receiving@rentallist_detail/tab/receiving", 1, -1, isSelect = false, isDisabled = false)
                )
                stateTab.checkBlock = {
                    eLaunch {
                        when (it) {
                            0 -> rootView.entity(entity.tab1)
                            1 -> rootView.entity(entity.tab2)
                        }
                    }
                }
            }
            rootView.sub(K.nav).compPageTitle{ it.setTitle("c@Rental #@rentallist_detail/title/header", "v0" to entity.title) }
            rootView.entity(entity.tab1)
        }
    })

    rootView.addEntityHook(EntInit::class, object: eEntityHook<HTMLElement, EntInit> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntInit){
            view.sub(K.stateTab).compTabs<Int> { stateTab ->
                stateTab.setList(
                    stateTab.item("c@Info@rentallist_detail/tab/info", 0, -1, isSelect = true, isDisabled = false),
                    stateTab.item("c@Receiving@rentallist_detail/tab/receiving", 1, -1, isSelect = false, isDisabled = false)
                )
            }
        }
    })
}
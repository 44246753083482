package comp.input

import Factory
import comp.OutType
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eVali
import ein2b.core.view.*
import ein2b.js.dom.eEvent
import ein2b.js.js.isTruthy
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event

external fun matchList(search:String, arr:String, tag:String, attr:String):dynamic
/* ********* compInputData 사용법
// 데이터 리스트
val list = mutableListOf(
    EntApiTest().apply {
        r = 1
        title = "가나다"
    },
    EntApiTest().apply {
        r = 2
        title = "나가다"
    },
    EntApiTest().apply {
        r = 3
        title = "다나가"
    },
    EntApiTest().apply {
        r = 4
        title = "라"
    }
)

CompInputData<EntApiTest> { //CompInputData<EntApiTest> 만들 때 데이터 리스트에 올 데이터 타입을 명시
        it.placeholder = "플레이스홀더"   // 플레이스 홀더
        it.dataList = list  // 데이터 리스트
        it.addEvent         // add 버튼 눌렀을 때
        it.inputClass       // input클래스
        it.dataListClick    // 데이터 리스트에서 클릭했을 때
        it.stringList = {   // it.dataList를 JSON으로 파싱 가능한 형태로 만들어주는 애 (항상 갖고있어야한다)
            val result = list.fold("") {acc, curr -> acc+"${curr.stringify()}, "}
            "[${result.substring(0, result.length-2)}]"
        }
        it.addOn = listOf(CompInputData.AddOnType.ADD_BUTTON) // 상단에 추가 버튼
        it.emptyMsg = "데이터가 없어요" // it.addOn이 listOf(CompInputData.AddOnType.EMPTY)인 경우 메시지
        it.decorator         // 데이터 리스트를 꾸밈(현재는 string만 옴)
        it.mustSelect        // 무조건 리스트에서만 선택해야 할 때 true
        it.vali = {}        // 밸리데이션
*/

class CompInputData <T> private constructor():CompInput<String, String, String>{
    companion object{
        private const val CLASS_NAME = "CompInputData"
        private const val WRAPPER = "${CLASS_NAME}_wrapper"
        private const val INPUT = "${CLASS_NAME}_input"
        private const val CLEAR = "${CLASS_NAME}_clear"
        private const val REFRESH = "${CLASS_NAME}_refresh"
        private const val DISPLAY = "${CLASS_NAME}_display"
        private const val ADD = "${CLASS_NAME}_add"
        private const val EMPTY = "${CLASS_NAME}_empty"
        private const val LIST = "${CLASS_NAME}_list"
        private const val ERROR_CLASS:String = " error"
        private const val SELECTED_CLASS:String = " selected"
        private const val DISABLED_CLASS:String = " disabled"
        internal val FACTORY = Factory.html("""
<div data-view="$WRAPPER" class="input-data">
    <div style="display:flex;flex-grow:1">
        <div class="input-wrapper">
            <input data-view="$INPUT" class="input" type="text">
            <div data-view="$CLEAR" class="clear-btn"></div>
        </div>
        <div data-view="$REFRESH" class="refresh-btn"></div>
    </div>
    <div data-view="$DISPLAY" class="display">
        <div data-view="$ADD" class="link-btn add-btn"></div>
        <div data-view="$EMPTY" style="padding:10px 10px;font-size:12px"></div>
        <ul data-view="$LIST" class="data-list"></ul>
    </div>
</div>""")
        private val dataFactory = Factory.html("""<li data-view="" class="ellipsis" style="display:flex;align-items:center"></li>""")
        operator fun <T> invoke(block:(CompInputData<T>)->Unit):CompInputData<T>{
            val comp = CompInputData<T>()
            block(comp)
            eWindow.addClick{ eLaunch{ comp.close() } }
            return comp
        }
    }
    class InputData <T> (val value:T, val title:String, val search:String)
    var addEvent:((String)->Unit)? = null
    var dataList = mutableListOf<InputData<T>>()
    private val stringList:String get() = dataList.fold(""){ acc, curr -> acc + """{"value":"${curr.value}", "title":"${curr.title}", "search":"${curr.search}"}, """}.let{ "[${it.substring(0, it.length - 2)}]" }
    var prefix:((T)->String)? = null
    var suffix:((T)->String)? = null
    var dataListClick:((T)->Unit) ?= null
    var clearClick:(()->Unit) ?= null
    var refreshClick:(()->Unit) ?= null
    var emptyMsg:String = ""
    var addMsg:String = ""
    var mustSelect:Boolean = false
    var addOn = listOf<AddOnType>()
    var isReverse = false
    enum class AddOnType(val key:String){
        ADD_BUTTON("addBtn"),
        EMPTY("empty")
    }
    var wrapperClass = "input-data"
    lateinit var target:eView<HTMLElement>
    private var isSelected = false

    private suspend fun selectData(v:String){
        isSelected = true
        target.sub(DISPLAY).displayNone()
        target.sub(REFRESH).displayBlock()
        target.sub(CLEAR).displayNone()
        target.sub(INPUT){
            it.value = v
            if(mustSelect) {
                isDisabled = true
                it.attr("readonly", "readonly")
                target.sub(WRAPPER).className = "$wrapperClass $DISABLED_CLASS"
            }
        }
    }
    private suspend fun deselectData(){
        target.sub(DISPLAY).displayNone()
        target.sub(INPUT) {
            it.value = ""
            if(mustSelect) {
                isDisabled = false
                it.attr("readonly", eView.REMOVE)
                target.sub(WRAPPER).className = setClassName(if(isOk) "" else ERROR_CLASS)
            }
        }
        target.sub(REFRESH).displayNone()
        target.sub(CLEAR).displayNone()
    }
    private suspend fun close() {
        target.sub(DISPLAY).displayNone()
        if(mustSelect && !isSelected) clearWithError()
    }
    private fun debounce(d:Int, f:(Event, HTMLElement)->Unit):(Event, HTMLElement)->Unit{
        var id = 0
        return {e, el->
            window.clearTimeout(id)
            id = window.setTimeout({
                f(e,el)
            }, d)
        }
    }

    override lateinit var value: CompValue<String, String>
    override var errorListener:((Boolean, String)->Unit)? = null
    override var vali: eVali? = null

    override val factory:suspend ()->HTMLElement = FACTORY
    override suspend fun init(it:eView<HTMLElement>){
        target = it
        target.sub(WRAPPER).className = wrapperClass
        val display = target.sub(DISPLAY) {
            it.className = if(isReverse) "display-reverse" else "display"
            it.displayNone()
        }
        val list = target.sub(LIST)
        val addBtn = target.sub(ADD) {
            it.displayNone()
            it.click = { _, _ ->
                if(addOn.contains(AddOnType.ADD_BUTTON)) {
                    addEvent?.let {
                        if(value.check()){ it(value.value?:"") }
                    }
                }
            }
        }
        val empty = target.sub(EMPTY) {
            it.displayNone()
        }
        target.sub(INPUT){ inputView ->
            inputView.className = inputClass
            inputView.placeholder = placeholder
            isDisabled = false
            fun changedValue(v:String, isViewOnly:Boolean = false){
                inputView.value = if(isViewOnly) eViewOnly(v) else v
                value.inputValue(v)
                var isExist = false
                var length = 0
                if(dataList.isNotEmpty()){
                    val matchList = matchList(v, stringList, "span", """class="mark"""")
                    if(isTruthy(matchList)){
                        val j = (matchList.length as Int)-1
                        length = j+1
                        eLaunch {
                            list.setClearList { listView ->
                                for(i in 0..j){
                                    val obj = matchList[i]
                                    if(isTruthy(obj) && v.isNotBlank()){
                                        val objValue = obj.value as T
                                        val pre = prefix?.invoke(objValue) ?: ""
                                        val suf = suffix?.invoke(objValue) ?: ""
                                        if(!isExist && obj.title == v) isExist = true
                                        listView += eView(dataFactory){
                                            it.value = obj.value
                                            it.html = "$pre${obj.html}$suf"
                                            it.click = {e,_->
                                                e.stopImmediatePropagation()
                                                value.inputValue("${it.value}")
                                                eLaunch { selectData("${obj.title}") }
                                                dataListClick?.invoke(objValue)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                if(length > 0 && v.isNotBlank()) display.displayBlock() else display.displayNone()
                if(addOn.contains(AddOnType.ADD_BUTTON)) addBtn.also{
                    if(!isExist){
                        it.value = v
                        it.html = addMsg.ifBlank { "+ $v" }
                        it.displayInlineBlock()
                        display.displayBlock()
                    }else{
                        it.value = ""
                        it.displayNone()
                    }
                }
                if(addOn.contains(AddOnType.EMPTY)) empty.also{ et ->
                    if(length==0) {
                        et.attr("v0" to v)
                        et.html = emptyMsg
                        et.displayBlock()
                        display.displayBlock()
                    } else et.displayNone()
                }

            }
            inputView.keyup = debounce(300){ e,el->
                val ev = eEvent(e, el)
                var v = ev.value.trim()
                if(!(mustSelect && isSelected)) {
                    eLaunch{
                        setClearDisplay(v.isNotBlank())
                        setRefreshDisplay(false)
                    }
                    changedValue(v, true)
                    changeBlock?.invoke(v)
                }
            }
            inputView.keydown = { e, el ->
                val ev = eEvent(e, el)
                if(maxLength > -1 && ev.keycode() !in hashSetOf(8,13,45,46,33,34,35,36,37,38,39,40) && ev.value.length >= maxLength) {
                    e.stopImmediatePropagation()
                    ev.prevent()
                }
            }
            inputView.focus = { _,_-> if(!isDisabled) eLaunch{ focus() } }
            inputView.blur = { _,_-> if(!isDisabled) eLaunch{ blur() } }
            inputView.click = {e,el->
                eEvent(e,el).prevent()
                e.stopImmediatePropagation()
            }
        }
        target.sub(CLEAR){
            it.displayNone()
            it.click = {e, el ->
                eEvent(e,el).prevent()
                e.stopImmediatePropagation()
                eLaunch {
                    clear()
                    clearClick?.invoke()
                }
            }
        }
        target.sub(REFRESH){
            it.displayNone()
            it.click = {e, el ->
                eEvent(e,el).prevent()
                e.stopImmediatePropagation()
                eLaunch {
                    clear()
                    refreshClick?.invoke()
                }
            }
        }
        value = CompValue("", "", vali, errorListener, CompInput.CONV){ target.value = it }
    }
    suspend fun clearWithError(){
        isSelected = false
        target.sub(INPUT).value = "-"
        target.sub(INPUT).value = ""
        value.inputValue("")
        deselectData()
    }
    override suspend fun clear(){
        isOk = true
        isSelected = false
        target.sub(INPUT).value = "-"
        target.sub(INPUT).value = ""
        value.inputValue("")
        deselectData()
    }
    override suspend fun error(isOk:Boolean){
        this.isOk = isOk
        target.sub(WRAPPER).className = setClassName(if(isOk) "" else ERROR_CLASS)
    }

    override var placeholder:String = ""
    private var inputClass:String = "input"
    var changeBlock:((String)->Unit)? = null
    var maxLength:Int = -1

    suspend fun displayBlock(){ target.sub(WRAPPER).displayBlock() }
    suspend fun displayNone(){
        target.sub(WRAPPER).displayNone()
        clear()
    }
    suspend fun disabled(bool:Boolean) {
        isDisabled = bool
        target.sub(INPUT).attr("readonly", if(isDisabled) "readonly" else eView.REMOVE)
        target.sub(WRAPPER).className = "$wrapperClass${if(isDisabled) " $DISABLED_CLASS" else ""}"
    }
    suspend fun removeData(key:T){
        dataList = dataList.filter { it.value != key }.toMutableList()
    }
    private var isFocus = false
    private var isDisabled = false
    private var isOk = true
    private suspend fun focus(){
        isFocus = true
        target.sub(WRAPPER).className = setClassName(SELECTED_CLASS)
    }
    private suspend fun blur(){
        isFocus = false
        target.sub(WRAPPER).className = setClassName("")
    }
    suspend fun setInputValue(str:String, isSelected:Boolean = false) {
        if(isSelected) {
            selectData(str)
        } else {
            target.sub(INPUT).value = str
            setClearDisplay(str.isNotBlank())
        }
    }
    suspend fun setInputValueSelect(v:T) {
        dataList.find { it.value == v }?.also {
            selectData(it.title)
            value.inputValue("$v")
        }
    }
    private suspend fun setClearDisplay(b:Boolean){ if(b) target.sub(CLEAR).displayBlock() else target.sub(CLEAR).displayNone() }
    private suspend fun setRefreshDisplay(b:Boolean){ if(b) target.sub(REFRESH).displayBlock() else target.sub(REFRESH).displayNone() }
    private fun setClassName(cls:String) = "$wrapperClass${if(isDisabled) DISABLED_CLASS else cls}"
    override val outs: HashMap<OutType, suspend () -> String> = hashMapOf(OutType.DEFAULT to { value.value })
}
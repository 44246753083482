package senscloud.common.vali

import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali

const val __EMPTY__ = "__EMPTY__"
private val REG_FLOAT = """^[-+]?[0-9]+\.?([0-9]+)?$""".toRegex()
internal fun _valiFloatCheck(v:Any) =
    (v as?String)?.let{v->
        if(REG_FLOAT.matches(v)) v else eRuleSet.FALSE
    }?: eRuleSet.FALSE


private val REG_UINT = """^[0-9]+$""".toRegex()
internal fun _valiUintCheck(v:Any) =
    (v as?String)?.let{v->
        if(REG_UINT.matches(v)) v else eRuleSet.FALSE
    }?: eRuleSet.FALSE

private val REG_ONEORZERO = """^[0-1]+$""".toRegex()
internal fun _valiOneOrZeroCheck(v:Any) =
    (v as?String)?.let{v->
        if(REG_ONEORZERO.matches(v)) v else eRuleSet.FALSE
    }?: eRuleSet.FALSE

private val REG_PHONE = """^01(?:[0|1|6|7|8|9])(?:[0-9]{3,4})(?:[0-9]{4})$""".toRegex()
internal fun _valiPhoneCheck(v:Any) =
    (v as? String)?.let{v->
        if(REG_PHONE.matches(v)) v else eRuleSet.FALSE
    }?: eRuleSet.FALSE
//YYYY-MM-DD HH:mm
private val dateFomatStr = "20[0-9]{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0123]):[0-5][0-9]"
private val timeFomatStr = "(0[0-9]|1[0-9]|2[0123]):[0-5][0-9]"
private val REG_DATE_FORMAT1 = """^${dateFomatStr}$""".toRegex()
private val REG_DATE_FORMAT2 = """^${dateFomatStr}:[0-5][0-9]$""".toRegex()
private val REG_TIME_FORMAT = """^${timeFomatStr}$""".toRegex()
internal fun _valiDateTimeCheck(v:Any) =
    (v as? String)?.let{
        if(it.matches(REG_DATE_FORMAT1)) "$it:00"
        else if(it.matches(REG_DATE_FORMAT2)) it
        else eRuleSet.FALSE
    }?: eRuleSet.FALSE
//뒤에 00 안붙이는 버전
internal fun _valiDateTimeCheck2(v:Any) =
    (v as? String)?.let{
        if(it.matches(REG_DATE_FORMAT1)) it
        else if(it.matches(REG_DATE_FORMAT2)) it
        else eRuleSet.FALSE
    }?: eRuleSet.FALSE

internal fun _valiHourMinCheck(v:Any) =
    (v as? String)?.let{
        if(it.matches(REG_TIME_FORMAT)) it
        else eRuleSet.FALSE
    }?: eRuleSet.FALSE


//날짜 체크용
private val REG_DATE = """^20[0-9]{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$""".toRegex()
internal fun _valiDateCheck(v:Any) =
    (v as? String)?.let{
        if(it.matches(REG_DATE)) it else eRuleSet.FALSE
    }?: eRuleSet.FALSE

//INT 체크용
private val REG_INT = """^[-+]?[0-9]+""".toRegex()
internal fun _valiIntCheck(v:Any) =
    (v as?String)?.let{v->
        if(REG_INT.matches(v)) v else eRuleSet.FALSE
    }?: eRuleSet.FALSE



//INT 양수 체크용
private val REG_POSITIVE_INT = """^(0|[+]?[1-9]\d*)$""".toRegex()
internal fun _valiPositiveIntCheck(v:Any) =
    (v as?String)?.let{v->
        if(REG_POSITIVE_INT.matches(v)) v else eRuleSet.FALSE
    }?: eRuleSet.FALSE

//UUID 체크용
private val REG_UUID = """^[0-9a-zA-Z]+$""".toRegex()
internal fun _valiUUIDCheck(v:Any):Any{
    val v = "$v"
    return if(v.matches(REG_UUID)) v else eRuleSet.FALSE
}

//https://s3.ap-northeast-2.amazonaws.com/
//사인 이미지 체크용 - 판매(출하)담당자, 배송 제품 패키지 수령완료시
private val REG_IMAGE_URL = """^(https?:)?//(.+)?""".toRegex()
internal fun _valiImageUrlCheck(v:Any):Any{
    val v = "$v"
    return if(v.matches(REG_IMAGE_URL)) v else eRuleSet.FALSE
}

val ValiRowId = eRuleVali{
    Case{
        Rule{"$it".trim()}
        Rule("RowId는 정수입니다."){
            _valiUintCheck(it)
        }
    }
}

//페이지
val ValiPage = eRuleVali{
    Case{
        Rule("Page는 정수이어야 합니다.") {
            _valiUintCheck(it)
        }
        Rule("Page는 1 이상값이어야 합니다."){
            if("$it".toLong() > 0L) it else eRuleSet.FALSE
        }
    }
}
/*
val ValiImagePrefix = eRuleVali{
    Case{
        Rule{"$it".trim()}
        Rule("사용할 수 없는 prefix입니다."){
            when("$it"){
                "profile", "license", "sign"->it
                else->eRuleSet.FALSE
            }
        }
    }
}*/


val ValiDate = eRuleVali {
    Case {
        Rule("날짜 형식이 틀렸습니다.") {
            _valiDateCheck(it)
        }
    }
}
val ValiDateTime = eRuleVali {
    Case {
        Rule("날짜 형식이 틀렸습니다.") {
            _valiDateTimeCheck(it)
        }
    }
}

val ValiDateTimeOrBlank = eRuleVali {
    Case {
        Rule("날짜 형식이 틀렸습니다.") {
            _valiDateTimeCheck(it)
        }
    }
    Case {
        Rule{"$it".trim()}
        Blank()
    }
}

val ValiSearchText = eRuleVali{
    Case{
        Trim()
        Equal(__EMPTY__ , "c@Enter a keyword.@common/vali/search/01")
    }
    Case{
        Trim()
        MinLength(1, "c@Enter a keyword.@common/vali/search/01")
        MaxLength(100, "c@Keyword must be under 100 characters.@common/vali/search/02")
    }
}

val ValiLatitude = eRuleVali{
    Case{
        Rule("Latitude is wrong"){
            try{
                val lat = "$it".toDouble()
                if(lat in -90.0..90.0) it
                else eRuleSet.FALSE
            }catch (e:Throwable){
                eRuleSet.FALSE
            }
        }
    }
}

val ValiLongitude = eRuleVali{
    Case{
        Rule("Longitude is wrong"){
            try{
                val lon = "$it".toDouble()
                if(lon in -180.0..180.0) it
                else eRuleSet.FALSE
            }catch (e:Throwable){
                eRuleSet.FALSE
            }
        }
    }
}
//Quantity
val ValiQuantity = eRuleVali{
    Case{
        val msg = "Invalid quantity."
        Rule(msg) {
            _valiUintCheck(it)
        }
        Rule(msg){
            if("$it".toLong() > 0L) it else eRuleSet.FALSE
        }
    }
}

val ValiBlank = eRuleVali{
    Case{
        Trim()
        MinLength(1, "Enter a keyword.")
    }
}

val ValiSensorBarcode = eRuleVali{
    Case {
        Trim()
        Length(12, "c@Sensor barcode must be 12-digit number.@common/vali/sensorbarcode/02")
    }
}

val ValiSensorBarcodeOrBlank = eRuleVali{
    Case {
        String()
        Trim()
        Rule("c@Sensor barcode must be 12-digit number.@common/vali/sensorbarcode/02"){
            (it as?String)?.let{ v->
                if(v.isBlank()) v
                else if(REG_INT.matches(v))
                    if(v.length == 12) v
                    else eRuleSet.FALSE
                else eRuleSet.FALSE
            }?: eRuleSet.FALSE
        }
    }
}

val ValiAddress= eRuleVali{
    Case{
        Trim()
        MinLength(1, "c@Select an address.@shipment_add_edit/vali/addr/01")
    }
}
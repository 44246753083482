package member.subManagement

import Factory
import app.hashManager
import senscloud.common.app.App
import senscloud.user.app.RouterKey.SUB_ACCOUNT_ADD
import senscloud.user.app.RouterKey.SUB_ACCOUNT_EDIT
import senscloud.user.entity.member.EntClientMemberSub
import comp.CompPageTitle
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

private val factory = Factory.htmlUrl("member/subManagementT")
private val subT = Factory.htmlUrl("member/subT")

private enum class K{
    regSubBtn, emptyMsg, listArea, cnt, list,
    sub_profile, sub_fullName, sub_username, sub_phone, sub_editBtn;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

suspend fun SubManagementView() = eView(factory){ rootView->
    App.emptySub(rootView, K.listArea, K.cnt, K.list)
    CompPageTitle(rootView){
        it.title = "c@Sub accounts@subaccount/title/subaccount"
    }
    rootView.sub(K.emptyMsg) {
        it.html = "c@Couldn't find any sub account.@subaccount/title/nosubaccount"
        it.displayNone()
    }

    rootView.sub(K.regSubBtn) {
        it.html = "c@Create new@subaccount/button/newsubaccount"
        it.click = {_,_ ->
            hashManager.goUrl(SUB_ACCOUNT_ADD)
        }
    }

    rootView.addEntityHook(EntClientMemberSub::class, object: eEntityHook<HTMLElement, EntClientMemberSub> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntClientMemberSub){

            view.sub(K.listArea).displayNone()
            view.sub(K.emptyMsg).displayNone()
            if(entity.subList.isEmpty()) {
                view.sub(K.listArea).displayNone()
                view.sub(K.emptyMsg).displayBlock()
            }else {
                view.sub(K.listArea).displayBlock()
                view.sub(K.emptyMsg).displayNone()
            }

            view.sub(K.cnt) {
                it.attr("v0" to entity.cnt)
                it.html = "c@sub account@subaccount/title/subaccountnumber${if(entity.cnt.toInt() > 1) "2" else "1"}"
            }
            view.sub(K.list).setClearList { list ->
                entity.subList.forEach { sub ->
                    list += eView(subT) { subT ->
                        subT.sub(K.sub_phone) { it.html = entity.phoneFormat(sub.phone) }
                        subT.sub(K.sub_fullName) { it.html = sub.fullName }
                        subT.sub(K.sub_username) { it.html = "@${sub.username}"}
                        subT.sub(K.sub_profile) { it.lazyBackgroundImage = "" to sub.profileUrl }
                        subT.sub(K.sub_editBtn) {
                            it.html = "c@Edit@subaccount/button/editsubaccount"
                            it.click = {_,_ -> hashManager.goUrl(SUB_ACCOUNT_EDIT, "username" to sub.username) }
                        }
                    }
                }
            }
        }
    })
}
package member.join

import Factory
import app.hashManager
import senscloud.common.app.App
import senscloud.common.entity.EntInit
import senscloud.common.entity.api.user.member.EntUserApiMemberLoginCheck
import senscloud.common.entity.api.user.member.EntUserApiMemberMasterJoinWp
import senscloud.common.vali.*
import senscloud.user.app.ClientUserApiMemberMasterJoinWp
import senscloud.user.app.ClientUserApiRsa
import senscloud.user.app.RouterKey.LOGIN
import compLabelInputSectionPasswordSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import ein2b.js.crypto.eCrypto
import org.w3c.dom.HTMLElement
import prop.*
private val factory = Factory.htmlUrl("member/joinT")

private enum class K{
    joinArea,
    username, pw, fullName, phone, companyName, email,
    createBtn, backBtn,
    completeArea, completeUserId, completeUserName, completeTel, completeCompanyName, completeEmail,
    confirmBtn,
    SIGNUPTITLE, SIGNUPTITLE2, SIGNUPCOMPLETE, COMPLETEID, COMPLETENAME, COMPLETEPHONE, COMPLETECOMPANYNAME, COMPLETEEMAIL;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

suspend fun JoinView() = eView(factory){ rootView->
    App.subHtmlFromLabel(rootView,
        K.SIGNUPTITLE to "c@Sign up@signup/title/signup",
        K.SIGNUPTITLE2 to "c@Sign up@signup/title2/signup",
        K.SIGNUPCOMPLETE to "c@Thanks for signing up!@signup_complete/title/signupcomplete",
        K.COMPLETEID to "c@Username@signup_complete/label/username",
        K.COMPLETENAME to "c@Full name@signup_complete/label/name",
        K.COMPLETEPHONE to "c@Phone@signup_complete/label/phone",
        K.COMPLETECOMPANYNAME to "c@Company name@signup_complete/label/companyname",
        K.COMPLETEEMAIL to "c@Email address@signup_complete/label/email",
        )
    rootView.sub(K.joinArea) {
        it.displayBlock()
    }
    rootView.sub(K.completeArea) {
        it.displayNone()
    }
    rootView.sub(K.completeUserId)
    rootView.sub(K.completeUserName)
    rootView.sub(K.completeTel)
    rootView.sub(K.completeCompanyName)
    rootView.sub(K.completeEmail)
    rootView.sub(K.confirmBtn)

    rootView.compLabelInputSectionTextSet(K.username, "c@Username@signup/placeholder/username", ValiMemberUserName)

    rootView.compLabelInputSectionPasswordSet(K.pw, "c@Password@signup/placeholder/pw", ValiMemberPw)

    rootView.compLabelInputSectionTextSet(K.fullName, "c@Full name@signup/placeholder/name", ValiMemberFullName)

    rootView.compLabelInputSectionTextSet(K.phone, "c@Phone@signup/placeholder/phone", ValiMemberPhone)

    rootView.compLabelInputSectionTextSet(K.companyName, "c@Company name@signup/placeholder/companyname", ValiMasterCompanyName)

    rootView.compLabelInputSectionTextSet(K.email, "c@Email address@signup/placeholder/email", ValiMasterEmail)

    rootView.sub(K.createBtn) {
        it.html = "c@Sign up@signup/button/signup"
        it.click = {_,_ ->
            eLaunch {
                val compUserName = rootView.sub(K.username).compLabelInputSectionText()
                val compPw = rootView.sub(K.pw).compLabelInputSectionPassword()
                val compFullName = rootView.sub(K.fullName).compLabelInputSectionText()
                val compTel = rootView.sub(K.phone).compLabelInputSectionText()
                val compCompanyName = rootView.sub(K.companyName).compLabelInputSectionText()
                val compEmail = rootView.sub(K.email).compLabelInputSectionText()
                if(App.checkAll(compUserName, compPw, compFullName, compTel, compCompanyName, compEmail)) {
                    ClientUserApiRsa.net()?.also { rsa ->
                        ClientUserApiMemberMasterJoinWp.net { req ->
                            req.rsaId = rsa.rsa.id
                            req.username = compUserName.out()
                            req.pw =  eCrypto.rsaEncrypt(compPw.out(), rsa.rsa.key)
                            req.fullName = compFullName.out()
                            req.phone = compTel.out()
                            req.companyName = compCompanyName.out()
                            req.email = compEmail.out()
                        }?.also { res ->
                            if(res.isError) {
                                compUserName.changeError(res.error.username, res.error.username.isBlank())
                            }else {
                                rootView.entity(res)
                            }
                        }
                    }
                }
            }
        }
    }

    rootView.sub(K.backBtn) {
        it.html = "c@Go back to login page@signup/link/movetologin"
        it.click = {_,_ ->
            hashManager.goUrl(LOGIN)
        }
    }

    rootView.sub(K.confirmBtn) {
        it.html = "c@Login@signup/button/login"
        it.click = {_,_ ->
            hashManager.goUrl(LOGIN)
        }
    }

    rootView.addEntityHook(EntInit::class, object:eEntityHook<HTMLElement, EntInit> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntInit){
            view.sub(K.joinArea).displayBlock()
            view.sub(K.completeArea).displayNone()
            view.clearCompValue(K.username, K.pw, K.fullName, K.phone, K.companyName, K.email)
        }
    })

    rootView.addEntityHook(EntUserApiMemberLoginCheck.Res::class, object:eEntityHook<HTMLElement, EntUserApiMemberLoginCheck.Res> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntUserApiMemberLoginCheck.Res){
            view.entity(EntInit)
        }
    })

    rootView.addEntityHook(EntUserApiMemberMasterJoinWp.Res::class, object:eEntityHook<HTMLElement, EntUserApiMemberMasterJoinWp.Res>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntUserApiMemberMasterJoinWp.Res){
            view.sub(K.joinArea).displayNone()
            view.sub(K.completeArea).displayBlock()
            view.sub(K.completeUserId) { it.html = entity.username }
            view.sub(K.completeUserName) { it.html = entity.fullName }
            view.sub(K.completeTel) { it.html = entity.phone }
            view.sub(K.completeCompanyName) { it.html = entity.companyName }
            view.sub(K.completeEmail) { it.html = entity.email }
        }
    })

}
package senscloud.util

import ein2b.core.core.uuid
import ein2b.core.entity.eEntity
import ein2b.core.view.attr
import ein2b.core.view.eView
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import senscloud.common.entity.api.user.common.EntTempInfo
import senscloud.util.Chart.dataKey

external fun makeGraph(d:String):dynamic
class SensorTempInfoList:eEntity(){
    var itemConditionMinMax: MutableList<Float> by floatList(::itemConditionMinMax){ default(mutableListOf(), false) }
    var deliveryStartDate:String by string(::deliveryStartDate){ default("", false) }
    var deliveryEndDate:String by string(::deliveryEndDate){ default("", false) }
    var list: MutableList<EntTempInfo> by entityList(::list, ::EntTempInfo)
    var isSmall = false
}
private class ChartData:eEntity(true){
    class Item:eEntity(true){
        var sensorCode:String by string(::sensorCode)
        var date: MutableList<String> by stringList(::date)
        var temp: MutableList<Float> by floatList(::temp)
    }
    var isSmall: Boolean by bool(::isSmall)
    var isPdf: Boolean by bool(::isPdf)
    var isSensorCodeShow: Boolean by bool(::isSensorCodeShow)
    var width: Int by int(::width)
    var height: Int by int(::height)
    var selector:String by string(::selector)
    var chartId:String by string(::chartId)
    var itemConditionMinMax: MutableList<Float> by floatList(::itemConditionMinMax){ default(mutableListOf(), false) }
    var deliveryStartDate:String by string(::deliveryStartDate)
    var deliveryEndDate:String by string(::deliveryEndDate)
    var language:String by string(::language)
    var list: MutableList<Item> by entityList(::list, ChartData::Item)
}
//https://observablehq.com/@d3/axis-ticks
object Chart{
    private var chartIdDataMap = hashMapOf<String, SensorTempInfoList>()
    private const val chartMaxWidth = 1564
    const val chartWidth = 900
    const val chartMinHeight = 150
    const val chartMaxHeight = 600
    fun chartIdDataMapInit(){ chartIdDataMap = hashMapOf() }
    fun chartIdDataMapAdd(id:String, data:SensorTempInfoList){
        chartIdDataMap[id] = data
    }
    fun chartIdDataMapDraw(){
        if(chartIdDataMap.isNotEmpty()){
            val intervalIdMap = mutableMapOf<String, Int>()
            chartIdDataMap.forEach{ (id,s)->
                intervalIdMap[id] = window.setInterval({
                    window.document.querySelector("[${dataKey}=$id]")?.also{
                        tempInfoGraph(id, s, chartWidth, chartMinHeight, s.isSmall)
                        intervalIdMap[id]?.also{ intervalId-> window.clearInterval(intervalId) }
                    }
                }, 50)
            }
        }
    }

    val id get() = "chart_${uuid("")}"
    const val dataKey = "data-d3id"
    fun eView<HTMLElement>.chart(chartId:String) = this.attr(dataKey, chartId)
    fun tempInfoGraph(selector:String, entity:SensorTempInfoList, width:Int = chartMaxWidth, height:Int = 600, isSmall:Boolean = false, isPdf:Boolean = false){
        val chartData = ChartData().also{
            it.isSensorCodeShow = false
            it.width = width
            it.height = height
            it.isSmall = isSmall
            it.isPdf = isPdf
            it.chartId = selector
            it.selector = "[$dataKey=$selector]"
            it.itemConditionMinMax = entity.itemConditionMinMax
            it.deliveryStartDate = entity.deliveryStartDate
            it.deliveryEndDate = entity.deliveryEndDate
            it.language = "en"
            it.list = entity.list.map{ tempInfo->
                ChartData.Item().also{ d->
                    d.temp = tempInfo.temp.map{ v-> tempFloat(v) }.toMutableList()
                    d.date = tempInfo.date.map{ v-> EntTempInfo.DateFormat(v).toDate() }.toMutableList()
                }
            }.toMutableList()
        }
        makeGraph(chartData.stringify())
    }
    fun tempFloat(v:String) = v.replace("°C", "").replace(" ", "").toFloat()
    fun tempFloat(v:Int) = v.toFloat() / 100
}
package senscloud.common.pageView.rentalDetail

import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
import senscloud.common.app.App
import senscloud.user.entity.rental.EntClientUserRentalV

enum class K{
    nav, basicArea, stateTab,

    tab1_SENSORS, tab1_REQUESTED, tab1_SHIPTO, tab1_FULLNAME, tab1_PHONE ,tab1_ADDR1, tab1_ADDR2, tab1_RENTALINFO, tab1_STATUS, tab1_RENTALPERIOD, tab1_MESSAGE,
    tab1_sensorList, tab1_sensorImage, tab1_sensorName, tab1_temp, tab1_contents, tab1_requestQty,
    tab1_fullName, tab1_phone, tab1_addr1, tab1_addr2, tab1_rentalState, tab1_date, tab1_message,

    tab2_receiveCnt, tab2_noListArea, tab2_receiveList,
    tab2_DATE, tab2_SENSORNAME, tab2_QUANTITY, tab2_PERIODOFUSE, tab2_regDate, tab2_sensorName, tab2_qty, tab2_date, tab2_arrowArea, tab2_arrowImg, tab2_barcodeListArea,
    tab2_SENSORBARCODE, tab2_barcodeList, tab2_barcode;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}
suspend fun rentalDetailView(
    factory:suspend()-> HTMLElement, factory_Tab1:suspend()-> HTMLElement, sensorListT:suspend()-> HTMLElement,
    factory_Tab2:suspend()-> HTMLElement, receiveListT:suspend()-> HTMLElement, barcodeListT:suspend()-> HTMLElement,
    tab1InitBlock:(suspend (eView<HTMLElement>)->Unit)? = null, initBlock:(suspend (eView<HTMLElement>)->Unit)? = null
) = eView(factory){ rootView ->
    initBlock?.invoke(rootView)
    App.emptySub(rootView, K.basicArea)
    rootView.addEntityHook(EntClientUserRentalV.Tab1::class, object:eEntityHook<HTMLElement, EntClientUserRentalV.Tab1>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntClientUserRentalV.Tab1){
            view.sub(K.basicArea).setClearList{ basicList ->
                basicList += eView(factory_Tab1) { v ->
                    App.subHtmlFromLabel(v, K.tab1_SENSORS to "c@Sensors@rentallist_detail/title/sensors", K.tab1_SHIPTO to "c@Ship to@rentallist_detail/title/shipto",
                        K.tab1_FULLNAME to "c@Full name@rentallist_detail/label/fullname",
                        K.tab1_PHONE to "c@Phone@rentallist_detail/label/phone",
                        K.tab1_ADDR1 to "c@Address@rentallist_detail/label/address",
                        K.tab1_ADDR2 to "c@Address 2 (optional)@rentallist_detail/label/address2",
                        K.tab1_RENTALINFO to "c@Rental info@rentallist_detail/title/rentalinfo",
                        K.tab1_STATUS to "c@Status@rentallist_detail/label/status",
                        K.tab1_RENTALPERIOD to "c@Rental period@rentallist_detail/label/rentalperiod",
                        K.tab1_MESSAGE to "c@Message (optional)@rentallist_detail/label/message"
                    )
                    tab1InitBlock?.invoke(v)
                    App.setCommonList(v.sub(K.tab1_sensorList), sensorListT, entity.sensorList,  K.tab1_sensorName, K.tab1_temp, K.tab1_contents, K.tab1_requestQty){ _, v1, sl ->
                        v1.sub(K.tab1_REQUESTED).html = "c@Requested@rentallist_detail/label/requested"
                        v1.sub(K.tab1_sensorImage).image = sl.imageUrl
                    }
                    App.subHtmlFromEntity(v, entity.shipTo, K.tab1_fullName, K.tab1_phone, K.tab1_addr1, K.tab1_addr2)
                    App.subHtmlFromEntity(v, entity.rentalInfo, K.tab1_rentalState, K.tab1_date, K.tab1_message)
                }
            }
        }
    })
    rootView.addEntityHook(EntClientUserRentalV.Tab2::class, object:eEntityHook<HTMLElement, EntClientUserRentalV.Tab2>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntClientUserRentalV.Tab2){
            view.sub(K.basicArea).setClearList{ basicList ->
                basicList += eView(factory_Tab2) { v ->
                    v.sub(K.tab2_receiveCnt) {
                        it.attr("v0" to entity.receiveCnt)
                        it.html = "c@receiving@rentallist_detail/title/receivingcnt/${if(entity.receiveCnt>1) "02" else "01"}"
                    }
                    v.sub(K.tab2_noListArea){
                        it.html = "c@Couldn't find any receiving.@rentallist_detail/text/emptylist"
                        it.displayNone()
                    }
                    v.sub(K.tab2_receiveList).displayNone()
                    if(entity.receiveList.isEmpty()) v.sub(K.tab2_noListArea).displayBlock() else v.sub(K.tab2_receiveList).displayBlock()
                    App.setCommonList(v.sub(K.tab2_receiveList), receiveListT, entity.receiveList, K.tab2_regDate, K.tab2_qty, K.tab2_sensorName, K.tab2_date){ idx, v1, rl ->
                        App.subHtmlFromLabel(v1, K.tab2_DATE to "c@Date@rentallist_detail/label/date", K.tab2_SENSORNAME to "c@Sensor name@rentallist_detail/label/sensorname",
                            K.tab2_QUANTITY to "c@Quantity@rentallist_detail/label/quantity", K.tab2_PERIODOFUSE to "c@Period of use@rentallist_detail/label/periodofuse",
                            K.tab2_SENSORBARCODE to "c@Sensor barcode@rentallist_detail/label/sensorbarcode")
                        v1.sub(K.tab2_barcodeListArea).displayNone()
                        val arrowImg = v1.sub(K.tab2_arrowImg){it.className = "closeArrow"}
                        v1.sub(K.tab2_arrowArea){
                            it.click = {_,_->
                                if(arrowImg.className == "closeArrow"){
                                    arrowImg.className = "openArrow"
                                    eLaunch { v1.sub(K.tab2_barcodeListArea).displayBlock() }
                                }else{
                                    arrowImg.className = "closeArrow"
                                    eLaunch { v1.sub(K.tab2_barcodeListArea).displayNone() }
                                }
                            }
                        }
                        v1.sub(K.tab2_barcodeList).setClearList { list ->
                            rl.codeList.forEach { cl ->
                                list += eView(barcodeListT){ bView ->
                                    bView.sub(K.tab2_barcode).html = cl
                                }
                            }
                        }
                    }
                }
            }
        }
    })
}
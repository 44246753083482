package senscloud.common.entity.api.user.shipment

import ein2b.core.entity.eEntity
import ein2b.core.entity.field.enum.enum
import ein2b.core.entity.field.enum.enumStringMap
import senscloud.common.enm.EnumApiAccessAllow
import senscloud.common.enm.EnumApiShipmentSearchType
import senscloud.common.entity.api.user.common.*
import senscloud.common.entity.api.user.common.USER_API_SHIPMENT_PATH
import senscloud.common.vali.*

class Sensor:eEntity(true) {
    var sensorRowid:String by string(::sensorRowid)
    var code:String by string(::code)
    var minTemp:String by string(::minTemp)
    var maxTemp:String by string(::maxTemp)
}

//Creat a shipment ready
object EntUserApiShipmentW: EntUserApi<EntUserApiShipmentW.Req, EntUserApiShipmentW.Res>(::Req, ::Res){
    const val PATH = "$USER_API_SHIPMENT_PATH/w"
    const val PATH_JSON = "$PATH/json"
    override val rsckeys = "shipment_add_edit"

    class Req: eEntity()
    class Res: eEntity() {
        var addressList: MutableList<Address> by entityList(::addressList, ::Address){ default(mutableListOf(), false) }
        var sensorList: MutableList<Sensor> by entityList(::sensorList, ::Sensor){ default(mutableListOf(), false) }
    }
    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
}

//Creat a shipment
object EntUserApiShipmentWp: EntUserApi<EntUserApiShipmentWp.Req, EntUserApiShipmentWp.Res>(::Req, ::Res){
    const val PATH = "$USER_API_SHIPMENT_PATH/wp"
    const val PATH_JSON = "$PATH/json"

    class Req: eEntity() {
        var name:String by string(::name){ validator(ValiShipmentName) }
        var refNum:String by string(::refNum){ validator(ValiReferenceNum) }
        var description:String by string(::description){ validator(ValiShipmentDescription) }
        var sensorCodeList: MutableList<String> by stringList(::sensorCodeList)
        var fromAddressRowid:String by string(::fromAddressRowid){ validator(ValiRowId) }
        var toAddressRowid:String by string(::toAddressRowid){ validator(ValiRowId) }
        var startDate:String by string(::startDate){ validator(ValiDateTime) }
        var arrivalDate:String by string(::arrivalDate){ validator(ValiDateTimeOrBlank) }
    }
    class Res: eEntity(){
        companion object{
            private val ERROR = Error()
        }
        class Error: eEntity(true){
            var refNum:String by string(::refNum) { default("") }
            var sensorCodeList: MutableList<String> by stringList(::sensorCodeList) { default(mutableListOf(),false) }
        }
        var error: Error by entity(::error, ::Error){ default(ERROR, true) }
        val isError get() = error !== ERROR
    }

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
}

open class EntUserShipmentDelivered:eEntity(true) {
    var shipmentRowid:String by string(::shipmentRowid)
    var name:String by string(::name)
    var regDate:String by string(::regDate)
    var fromAddressName:String by string(::fromAddressName)
    var toAddressName:String by string(::toAddressName)
}

//Delivered
object EntUserApiShipmentDelivered: EntUserApi<EntUserApiShipmentDelivered.Req, EntUserApiShipmentDelivered.Res>(::Req, ::Res){
    const val PATH = "$USER_API_SHIPMENT_PATH/delivered"
    const val PATH_JSON = "$PATH/json"
    override val rsckeys = "delivered,shipment"

    class Req: eEntity() {
        var searchType: EnumApiShipmentSearchType by enum(::searchType){
            default(EnumApiShipmentSearchType.ALL, false)
            validator(ValiDeliveredShipmentSearchType)
        }
        var searchText:String by string(::searchText){
            default(__EMPTY__,false)
            validator(ValiSearchText)
        }
        var page:String by string(::page){
            default("1", false)
            validator(ValiPage)
        }
        var filterList: MutableMap<EnumApiShipmentSearchType, String> by enumStringMap(::filterList){ default(mutableMapOf(), false) }
    }
    class Res: eEntity() {
        class Meta: EntUserApiPageMeta(){
            var searchType: EnumApiShipmentSearchType by enum(::searchType){ default(EnumApiShipmentSearchType.ALL, false) }
            var searchText:String by string(::searchText){ default("", false) }
            var filterList: MutableMap<EnumApiShipmentSearchType, String> by enumStringMap(::filterList){ default(mutableMapOf(), false) }
        }
        var deliveredList: MutableList<EntUserShipmentDelivered> by entityList(::deliveredList, ::EntUserShipmentDelivered){ default(mutableListOf(), false) }
        var meta: Meta by entity(::meta, ::Meta){ default(Meta(), false) }
    }

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
}

//Ongoing shipment list
object EntUserApiShipmentOngoing: EntUserApi<EntUserApiShipmentOngoing.Req, EntUserApiShipmentOngoing.Res>(::Req, ::Res){
    const val PATH = "$USER_API_SHIPMENT_PATH/ongoing"
    const val PATH_JSON = "$PATH/json"
    override val rsckeys = "shipment,ongoing,mark_as_delivered"

    class Req: eEntity(){
        var searchType: EnumApiShipmentSearchType by enum(::searchType){
            default(EnumApiShipmentSearchType.ALL,false)
            validator(ValiOngoingShipmentSearchType)
        }
        var searchText:String by string(::searchText){
            default("", false)
        }
        var filterList: MutableMap<EnumApiShipmentSearchType, String> by enumStringMap(::filterList){ default(mutableMapOf(), false) }
    }
    class Res: eEntity(true){
        class Meta: eEntity(true){
            var searchType: EnumApiShipmentSearchType by enum(::searchType){ default(EnumApiShipmentSearchType.ALL, false) }
            var searchText:String by string(::searchText){ default("", false) }
            var filterList: MutableMap<EnumApiShipmentSearchType, String> by enumStringMap(::filterList){ default(mutableMapOf(), false) }
        }
        class Ongoing:eEntity(){
            var departureDate:String by string(::departureDate) // Mark as delivered 모달에서 필요한 정보
            var regDate:String by string(::regDate)
            var name:String by string(::name)
            var shipmentRowid:String by string(::shipmentRowid)
            var from: AddressLocation by entity(::from, ::AddressLocation)
            var to: AddressLocation by entity(::to, ::AddressLocation)
            class AddressLocation:Address(){
                var lat:String by string(::lat)
                var lng:String by string(::lng)
            }
        }
        var ongoingList: MutableList<Ongoing> by entityList(::ongoingList, ::Ongoing){ default(mutableListOf(), false) }
        var meta: Meta by entity(::meta, ::Meta){ default(Meta(), false) }
    }

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
    init {
        //isJSON = true
    }
}

//Ongoing shipment delivered add
object EntUserApiShipmentDeliveredWp: EntUserApi<EntUserApiShipmentDeliveredWp.Req, EntUserApiShipmentDeliveredWp.Res>(::Req, ::Res){
    const val PATH = "$USER_API_SHIPMENT_PATH/delivered/wp"
    const val PATH_JSON = "$PATH/json"
    override val rsckeys = "ongoing,mark_as_delivered"
    // TODO
    // 존재하지 않는 shipment일 때
    // Couldn't find the shipment. 알럿
    class Req: eEntity(){
        var shipmentRowid:String by string(::shipmentRowid){ validator(ValiRowId) }
        var arrivalDate:String by string(::arrivalDate){ validator(ValiDateTime) }
    }
    class Res: eEntity()

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
    init {
     //   isJSON = true
    }
}


class Shipment: eEntity(true){
    var shipmentRowid:String by string(::shipmentRowid)
    var name:String by string(::name)
    var num:String by string(::num) //reference num
    var description:String by string(::description)
}

class Route: eEntity(true){
    var shipmentRouteRowid:String by string(::shipmentRouteRowid)
    var startName:String by string(::startName)
    var startAddr:String by string(::startAddr)
    var startDate:String by string(::startDate)
    var endName:String by string(::endName)
    var endAddr:String by string(::endAddr)
    var endDate:String by string(::endDate)
}

//Shipment detail info
object EntUserApiShipmentV: EntUserApi<EntUserApiShipmentV.Req, EntUserApiShipmentV.Res>(::Req, ::Res){
    const val PATH = "$USER_API_SHIPMENT_PATH/v"
    const val PATH_JSON = "$PATH/json"
    override val rsckeys = "shipment_detail,mark_as_delivered"
    class Req: eEntity(){
        var shipmentRowid:String by string(::shipmentRowid){ validator(ValiRowId) }
    }
    class Res: eEntity(true){
        var sensorList: MutableList<Sensor> by entityList(::sensorList, ::Sensor){ default(mutableListOf(), false) }
        var shipment: Shipment by entity(::shipment, ::Shipment)
        var route: Route by entity(::route, ::Route)
    }

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
    init {
        //isJSON = true
    }
}

//shipment detail sensorList
object EntUserApiShipmentVSensor:EntUserApi<EntUserApiShipmentVSensor.Req, EntUserApiShipmentVSensor.Res>(::Req, ::Res){
    const val PATH = "$USER_API_SHIPMENT_PATH/v/sensor"
    const val PATH_JSON = "$PATH/json"
    override val rsckeys = "shipment_detail"

    class Req:eEntity(){
        var shipmentRowid:String by string(::shipmentRowid){ validator(ValiRowId) }
    }
    class Res:eEntity(true){
        class Sensor:eEntity(true){
            var code:String by string(::code)
            var minTemp:String by string(::minTemp)
            var maxTemp:String by string(::maxTemp)
            val tempInfoList:MutableList<EntTempInfo> by entityList(::tempInfoList, ::EntTempInfo){ default(mutableListOf(), false) }
        }
        class Route:eEntity(true){
            var startDateLabel:String by string(::startDateLabel)
            var endDateLabel:String by string(::endDateLabel)
            var startDate:String by string(::startDate)
            var endDate:String by string(::endDate)
        }
        var shipmentRowid:String by string(::shipmentRowid)
        var route:Route by entity(::route, ::Route)
        var sensorList:MutableList<Sensor> by entityList(::sensorList, ::Sensor){ default(mutableListOf(), false) }
    }

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
}


//edit a shipment
object EntUserApiShipmentEp: EntUserApi<EntUserApiShipmentEp.Req, EntUserApiShipmentEp.Res>(::Req, ::Res){
    const val PATH = "$USER_API_SHIPMENT_PATH/ep"
    const val PATH_JSON = "$PATH/json"
    override val rsckeys = "shipment_detail,shipment_add_edit"
    class Req: eEntity() {
        var shipmentRowid:String by string(::shipmentRowid){ validator(ValiRowId) }
        var name:String by string(::name){ validator(ValiShipmentName) }
        var refNum:String by string(::refNum){ validator(ValiReferenceNum) }
        var description:String by string(::description){ validator(ValiShipmentDescription) }
        var sensorCodeList: MutableList<String> by stringList(::sensorCodeList)
        var fromAddressRowid:String by string(::fromAddressRowid){ validator(ValiRowId) }
        var toAddressRowid:String by string(::toAddressRowid){ validator(ValiRowId) }
        var startDate:String by string(::startDate){ validator(ValiDateTime) }
        var arrivalDate:String by string(::arrivalDate){ validator(ValiDateTimeOrBlank) }
    }
    class Res: eEntity(){
        companion object{
            private val ERROR = Error()
        }
        class Error: eEntity(true){
            var refNum:String by string(::refNum) { default("") }
            var sensorCodeList: MutableList<String> by stringList(::sensorCodeList) { default(mutableListOf()) }
        }
        var error: Error by entity(::error, ::Error){ default(ERROR, true) }
        val isError get() = error !== ERROR
    }

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
    init {
    //    isJSON = true
    }
}

//delete a shipment
object EntUserApiShipmentDp: EntUserApi<EntUserApiShipmentDp.Req, EntUserApiShipmentDp.Res>(::Req, ::Res){
    const val PATH = "$USER_API_SHIPMENT_PATH/dp"
    const val PATH_JSON = "$PATH/json"
    override val rsckeys = "shipment_detail,shipment_add_edit"

    class Req: eEntity(){
        var shipmentRowid:String by string(::shipmentRowid){ validator(ValiRowId) }
    }
    class Res: eEntity()

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
    init {
     //   isJSON = true
    }
}

//edit a shipment ready
object EntUserApiShipmentE: EntUserApi<EntUserApiShipmentE.Req, EntUserApiShipmentE.Res>(::Req, ::Res){
    const val PATH = "$USER_API_SHIPMENT_PATH/e"
    const val PATH_JSON = "$PATH/json"
    override val rsckeys = "shipment_detail, shipment_add_edit"

    class Req: eEntity() {
        var shipmentRowid:String by string(::shipmentRowid){ validator(ValiRowId) }
    }
    class Res: eEntity(){
        var addressList: MutableList<Address> by entityList(::addressList, ::Address){ default(mutableListOf(), false) }
        var registeredSensorList: MutableList<Sensor> by entityList(::registeredSensorList, ::Sensor){ default(mutableListOf(), false) } //해당 shipment에 등록된 sensorList
        var sensorList: MutableList<Sensor> by entityList(::sensorList, ::Sensor){ default(mutableListOf(), false) } //전체 sensorList
        var shipment: Shipment by entity(::shipment, ::Shipment)
        var route: Route by entity(::route, ::Route)
    }

    override val accessAllow get() = EnumApiAccessAllow.MEMBER
    override val path = PATH
    override val jsonPath = PATH_JSON
    init {
       // isJSON = true
    }
}


package senscloud.user.comp

import Factory
import comp.Comp
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import senscloud.common.entity.api.user.mysensors.EntUserApiMySensorsAllDataPdf
import senscloud.user.app.ClientUserApiMySensorsAllDataPdf
import senscloud.util.Chart
import senscloud.util.Chart.chart
import senscloud.util.SensorTempInfoList

suspend fun eView<HTMLElement>.compSensorPdfSet(subKey:Any) = CompSensorPdf(this, subKey)
class CompSensorPdf:Comp{
    companion object{
        private val pdfT = Factory.html("""<div style="width:700px;background:#FFF"><div data-view="chart"></div></div>""")
        suspend operator fun invoke(rootView:eView<HTMLElement>, subKey:Any):String{
            val chartId = Chart.id
            rootView.sub(subKey, pdfT){ pdfWrap ->
                pdfWrap.displayNone()
                pdfWrap.sub("chart").chart(chartId)
            }
            return chartId
        }
    }
}

suspend fun eView<HTMLElement>.compSensorPdfBtnSet(subKey:Any, label:String, sensorSessionRowid:String, chartId:String, data:SensorTempInfoList, block:((CompSensorPdfBtn)->Unit)? = null){
    CompSensorPdfBtn(this, subKey, label, sensorSessionRowid, chartId, data, block)
}
class CompSensorPdfBtn{
    companion object{
        suspend operator fun invoke(rootView:eView<HTMLElement>, subKey:Any, label:String, sensorSessionRowid:String, chartId:String, data:SensorTempInfoList, block:((CompSensorPdfBtn)->Unit)? = null){
            val comp = CompSensorPdfBtn()
            comp.target = rootView.sub(subKey){
                it.html = label
                it.disabled = false
                it.className = "btn border flex-grow-1"
                it.click = { e, _ ->
                    e.stopImmediatePropagation()
                    comp.target.opacity = 0.5
                    comp.click(chartId, data)
                }
            }
            block?.invoke(comp) ?:also{
                comp.click = {
                    ClientUserApiMySensorsAllDataPdf.net{
                        it.rowid = sensorSessionRowid
                    }
                }
                comp.createApi = { svg:String->
                    ClientUserApiMySensorsAllDataPdf.net{
                        it.rowid = sensorSessionRowid
                        it.svg = svg
                    }
                }
            }
        }
    }

    lateinit var click:(suspend ()->EntUserApiMySensorsAllDataPdf.Res?)
    lateinit var createApi:(suspend (svg:String)->EntUserApiMySensorsAllDataPdf.Res?)
    lateinit var target:eView<HTMLElement>
    var clickEnd:((String)->Unit)? = null

    private fun targetInit(){
        target.opacity = 1.0
    }
    private fun clickEnd(res:EntUserApiMySensorsAllDataPdf.Res){
        targetInit()
        clickEnd?.invoke(res.fileUrl) ?: window.open(res.fileUrl, "_blank")
    }
    fun click(chartId:String, data:SensorTempInfoList){
        eLaunch{
            click()?.also{ res1->
                if(res1.fileUrl.isBlank()) eLaunch{
                        Chart.tempInfoGraph(chartId, data, 700, 600, isPdf = true)

                        var intervalId = -1
                        intervalId = window.setInterval({
                            window.document.querySelector("[${Chart.dataKey}=$chartId]>svg")?.also{
                                window.clearInterval(intervalId)
                                val svg = """<svg style="position:relative;width:700px;height:600px">${it.innerHTML}</svg>"""
                                eLaunch{ createApi(svg)?.also{ res2-> clickEnd(res2) } ?: targetInit() }
                            }
                        }, 50)
                    }
                else clickEnd(res1)
            } ?: targetInit()
        }
    }
}
package senscloud.user.modalView.shipping

import comp.compInputSectionSet
import comp.input.CompInputDate
import comp.input.CompInputTime
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
import prop.*
import senscloud.common.app.App
import senscloud.common.entity.EntInit
import senscloud.user.app.ClientUserApiShipmentDeliveredWp
import senscloud.user.app.ClientUserApiShipmentOngoing
import senscloud.user.entity.shipping.EntClientMarkDelivered
import senscloud.user.entity.shipping.EntClientOngoingShipping
import senscloud.user.markAsDeliveredModalEV
import view.CompViewToast


private val factory = Factory.htmlUrl("modal/shipping/markAsDeliveredModal")
private enum class K{
    TITLE, DEPARTURE,
    departureDate,
    closeBtn, markBtn, date;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

private lateinit var ongoingRootView:eView<HTMLElement>
suspend fun markAsDeliveredModalOpen(ent: EntClientMarkDelivered, rootView:eView<HTMLElement>){
    markAsDeliveredModalEV.entity(ent)
    markAsDeliveredModalEV.displayBlock()
    ongoingRootView = rootView
}

suspend fun markAsDeliveredModalClose(){
    markAsDeliveredModalEV.entity(EntInit)
    markAsDeliveredModalEV.displayNone()
}
suspend fun markAsDeliveredModalView() = eView(factory){ rootView->
    App.emptySub(rootView, K.departureDate, K.markBtn)
    App.subHtmlFromLabel(rootView, K.TITLE to "c@Mark as delivered@mark_as_delivered/title/mark",
    K.DEPARTURE to "c@Departure@mark_as_delivered/label/departure")

    rootView.sub(K.closeBtn){
        it.html = "c@Close@mark_as_delivered/button/close"
        it.click = {_,_ -> eLaunch { markAsDeliveredModalClose() } }
    }

    rootView.compInputSectionSet<String>(
        K.date,
        errorClass = "form-error", isOneError = true, inputClass = "input-section"
    ){
        mutableListOf(
            CompInputDate{ comp ->
                comp.ymdPattern = "Y-m-d"
            },
            CompInputTime{ comp ->
                comp.wrapperClass ="input-time margin-left15"
            }
        )
    }

    rootView.addEntityHook(EntClientMarkDelivered::class, object:eEntityHook<HTMLElement, EntClientMarkDelivered>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientMarkDelivered) {
            view.sub(K.departureDate) { it.html = entity.departureDate }
            view.sub(K.date).compInputSection<String>().also { it.out{ it.values().joinToString(" ") } }

            view.sub(K.markBtn){
                it.html = "c@Mark as delivered@mark_as_delivered/button/mark"
                it.click = {_,_ ->
                    eLaunch {
                        val date = view.sub(K.date).compInputSection<String>()
                            if(App.checkAll(date)) {

                                val startDate = entity.dateFormatting(entity.departureDate)
                                val endDate = date.out()
                                if(startDate >= endDate) {
                                    date.changeError(false,"c@Arrival date must be later than the departure date.@mark_as_delivered/vali/date")
                                }
                                else {
                                    ClientUserApiShipmentDeliveredWp.net { req ->
                                        req.shipmentRowid = entity.shipmentRowid
                                        req.arrivalDate = endDate
                                    }?.also {
                                        markAsDeliveredModalClose()
                                        CompViewToast.open("c@Shipment was marked as delivered.@mark_as_delivered/toast/delivered", "success-badge", CompViewToast.TOAST_TYPE.SUCCESS)
                                        ClientUserApiShipmentOngoing.net()?.also { res ->
                                            ongoingRootView.entity(EntClientOngoingShipping(res))
                                        }
                                    }
                                }
                            }
                    }
                }
            }
    }})

    rootView.addEntityHook(EntInit::class, object:eEntityHook<HTMLElement, EntInit>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntInit) {
            view.clearCompValue(K.date)
        }
    })
}


package ein2b.js.browser

import kotlinx.browser.document
import org.w3c.dom.HTMLScriptElement
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

object eScriptLoader{
    suspend fun load(url:String) = suspendCoroutine<Boolean> {cont->
        val s = document.createElement("script") as HTMLScriptElement
        s.src = url
        s.onload = {
            document.head?.removeChild(s)
            cont.resume(true)
        }
        s.onerror = {_, _, _, _, _ ->
            cont.resume(false)
        }
        document.head?.appendChild(s) ?: cont.resume(false)
    }
}
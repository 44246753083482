package senscloud.user.app

import comp.CompPagination
import ein2b.core.entity.eEntity
import ein2b.js.browser.eLocalStorage
import ein2b.js.browser.eSessionStorage
import senscloud.common.entity.api.user.common.EntUserApiPageMeta

external fun xmlParse(file:String):dynamic
object AppUser{
    suspend fun setPage(compPage:CompPagination, pageMeta: EntUserApiPageMeta){
        compPage.setPage(pageMeta.totalRows.toLong(), pageMeta.page.toLong(), pageMeta.rowPerPage.toLong(), pageMeta.pagePerGroup.toLong())
    }
    fun setFrontMsg(frontMsg:String) { eLocalStorage["frontMsg"] = frontMsg }
    fun getFrontMsg() = eLocalStorage["frontMsg"] ?: ""
    class Logistics:eEntity(true){
        var logisticsList: MutableList<String> by stringList(::logisticsList){ default(mutableListOf(), false) }
    }
    var loginPage = true
    internal val colorList = mutableListOf("#ffe5d6", "#ffd6d6", "#d6e1ff", "#dcf2de", "#f2e0dc", "lightgray")
    var sessionUserId:String
        get() = eSessionStorage["userId"] ?: ""
        set(v){ eSessionStorage["userId"] = v }
    var userId:String
        get():String  = eLocalStorage["userId"] ?: ""
        set(v){ eLocalStorage["userId"] = v }
    fun setLogisticsList(logistics:String) {
        eLocalStorage["logistics"] = Logistics().also { to ->
            to.logisticsList = getLogisticsList().logisticsList.also { from ->
                from += logistics
            }.toSet().toMutableList()
        }.stringify()
    }
    fun getLogisticsList() = eLocalStorage["logistics"]?.let{ eEntity.parse(Logistics(), it)} ?: Logistics()
    fun phoneFormatter(phone: String) =
        when(phone.length) {
            11 -> "${phone.substring(0,3)}-${phone.substring(3,7)}-${phone.substring(7)}"
            10 -> if(phone.substring(0,2)=="02") "${phone.substring(0,2)}-${phone.substring(2,6)}-${phone.substring(6)}"
                else "${phone.substring(0,3)}-${phone.substring(3,6)}-${phone.substring(6)}"
            8 -> "${phone.substring(0,4)}-${phone.substring(4)}"
            else -> phone
        }
}
package member.subAdd

import Factory
import app.hashManager
import senscloud.common.app.App
import senscloud.common.entity.EntInit
import senscloud.common.entity.api.user.member.EntUserApiMemberSubWp
import senscloud.user.app.*
import senscloud.user.app.RouterKey.SUB_ACCOUNTS
import senscloud.user.entity.member.*
import comp.CompPageTitle
import comp.compProfileImageListSet
import compLabelInputSectionPasswordSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import ein2b.js.crypto.eCrypto
import org.w3c.dom.HTMLElement
import prop.clearCompValue
import prop.compLabelInputSectionPassword
import prop.compLabelInputSectionText
import prop.compProfileImageList
import senscloud.common.vali.*
import view.CompViewToast

private val factory = Factory.htmlUrl("member/subAddT")
private val profileT = Factory.htmlUrl("common/profileFactoryT")

private enum class K{
    username, pw, fullName, phone, compProfile,
    backBtn, regBtn,
    ACCOUNTINFO;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

suspend fun SubAddView() = eView(factory){ rootView->
    val addForm = EntUserApiMemberSubWp.request()

    App.subHtmlFromLabel(rootView,
    K.ACCOUNTINFO to "c@Account info@subaccount_new_edit/title/accountinfo"
    )

    CompPageTitle(rootView){
        it.title = "c@Create a sub account@subaccount_new_edit/title/createsubaccount"
        it.backClick = { hashManager.goUrl(SUB_ACCOUNTS) }
    }


    rootView.compLabelInputSectionTextSet(K.username, "c@Username@subaccount_new_edit/label/username", ValiMemberUserName)

    rootView.compLabelInputSectionPasswordSet(K.pw, "c@Password@subaccount_new_edit/label/password", ValiMemberPw)

    rootView.compLabelInputSectionTextSet(K.fullName, "c@Full name@subaccount_new_edit/label/name", ValiMemberFullName)

    rootView.compLabelInputSectionTextSet(K.phone, "c@Phone@subaccount_new_edit/label/phone", ValiMemberPhone)


    rootView.compProfileImageListSet(K.compProfile){
        it.title = "c@Profile image@subaccount_new_edit/title/profileimage"
        it.message = "c@Or select an avatar:@subaccount_new_edit/label/selectprofileimage"
        it.btnLabel = "c@Upload@subaccount_new_edit/button/fileupload"
    }

    rootView.sub(K.backBtn) {
        it.html = "c@Cancel@subaccount_new_edit/button/cancel"
        it.click = {_,_ ->
            eLaunch { hashManager.goUrl(SUB_ACCOUNTS) }
        }
    }

    rootView.sub(K.regBtn) {
        it.html = "c@Create a sub account@subaccount_new_edit/button/createsubaccount"
        it.click = {_,_ ->
            eLaunch {
                val compUserName = rootView.sub(K.username).compLabelInputSectionText()
                val compPw = rootView.sub(K.pw).compLabelInputSectionPassword()
                val compFullName = rootView.sub(K.fullName).compLabelInputSectionText()
                val compPhone = rootView.sub(K.phone).compLabelInputSectionText()
                if(App.checkAll(compUserName, compPw, compFullName, compPhone)) {
                    ClientUserApiRsa.net()?.also { rsa ->
                        ClientUserApiMemberSubWp.net{ req ->
                            req.rsaId = rsa.rsa.id
                            req.username = compUserName.out()
                            req.pw = eCrypto.rsaEncrypt(compPw.out(), rsa.rsa.key)
                            req.phone = compPhone.out()
                            req.fullName = compFullName.out()
                            req.profileUrl = addForm.profileUrl
                        }?.also { res ->
                            if(res.isError) {
                                compUserName.changeError(res.error.username, res.error.username.isBlank())
                            }else {
                                CompViewToast.open("c@Sub account was created.@subaccount_new_edit/toast/success/01", "success-badge", CompViewToast.TOAST_TYPE.SUCCESS)
                                hashManager.goUrl(SUB_ACCOUNTS)
                            }
                        }
                    }

                }
            }
        }
    }
    rootView.addEntityHook(EntClientMemberSubW::class, object: eEntityHook<HTMLElement, EntClientMemberSubW> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntClientMemberSubW){
            val entProfile = EntSubAddProfile(entity)
            view.entity(entProfile)
        }
    })

    rootView.addEntityHook(EntSubProfile::class, object:eEntityHook<HTMLElement, EntSubProfile> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntSubProfile){
            addForm.profileUrl = entity.profileUrl
            view.sub(K.compProfile).compProfileImageList { comp ->
                comp.setProfileData(entity.profileUrl, entity.profileUrlList)
                comp.setAfterUploaded { file ->
                    eLaunch {
                        ClientUserApiImageWp.uploadNet(file)?.also {
                            comp.setProfileData(it.imageUrl, entity.profileUrlList)
                            addForm.profileUrl = it.imageUrl
                        }
                    }
                }
                comp.setClickItem {
                    addForm.profileUrl = it
                }
            }
        }
    })

    rootView.addEntityHook(EntInit::class, object:eEntityHook<HTMLElement, EntInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntInit){
            view.clearCompValue(K.username, K.pw, K.fullName, K.phone)
        }
    })
}
package comp

import Factory
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

class CompPageTitle:Comp{
    companion object{
        class Btn(val className:String = "btn-image", val block:(()->Unit))
        //language=html
        private val factory = Factory.html("""
<div data-view="">
    <ul data-view="nav" class="nav"></ul>
    <div class="page-title middle">
        <div data-view="back" class="ic-back cursor dummy-icon" style="margin-right:16px"></div>
        <div data-view="title" class="h2 title dummy-title"></div>
        <ul data-view="btnList" class="btn-list middle"></ul>
    </div>
</div>""")
        private val subTitleFactory1 = Factory.html("""<li class="nav-item"><span data-view="sub" class="dummy-title"></span> /</li>""")
        private val subTitleFactory2 = Factory.html("""<li class="nav-item dummy-title" data-view="sub"></li>""")
        private val btnFactory = Factory.html("""<li class="dummy-icon" data-view=""></li>""")

        suspend operator fun invoke(root:eView<HTMLElement>, block:(CompPageTitle)->Unit):CompPageTitle{
            val comp = CompPageTitle()
            block(comp)
            comp.target = root.sub("nav", factory){
                it.sub("nav"){ navView->
                    if(comp.subTitleList.isEmpty()){
                        navView.displayNone()
                    }else{
                        navView.displayBlock()
                        navView.setClearList{ list->
                            comp.subTitleList.forEachIndexed{ idx, s ->
                                list += eView(if(idx < comp.subTitleList.size - 1) subTitleFactory1 else subTitleFactory2){ sub-> sub.sub("sub").html = s }
                            }
                        }
                    }
                }
                it.sub("back"){ backView->
                    comp.backClick?.also{
                        backView.click = {_,_-> it() }
                        backView.displayInlineBlock()
                    } ?:also{
                        backView.displayNone()
                    }
                }
                it.sub("title") {
                    it.html = comp.title
                    it.className = "h2 title dummy-title ellipsis"
                    if(comp.isEllipsis) it.attr("style", "width:1600px")
                }
                it.sub("btnList"){ btnList->
                    if(comp.btnList.isEmpty()){
                        btnList.displayNone()
                    }else{
                        btnList.displayInlineBlock()
                        btnList.setClearList{ btnListList->
                            comp.btnList.forEach{ btn->
                                btnListList += eView(btnFactory){
                                    it.className_ = btn.className
                                    it.click = {_,_-> btn.block() }
                                }
                            }
                        }
                    }
                }
            }
            comp.baseProp(root.sub("nav"))
            return comp
        }
    }
    lateinit var target:eView<HTMLElement>
    var subTitleList = listOf<String>()
    var btnList = listOf<Btn>()
    var title = ""
    var backClick:(()->Unit)? = null
    var isEllipsis = false

    suspend fun setTitle(v:String, vararg attrList:Pair<String, String>){
        target.sub("title"){t ->
            attrList.forEach{ t.attr(it) }
            t.html = v
        }
    }

    suspend fun setBack(){
        target.sub("back"){ backView->
            backClick?.also{
                backView.click = {_,_-> it() }
                backView.displayInlineBlock()
            } ?:also{
                backView.displayNone()
            }
        }
    }

    suspend fun setSubTitle(list:List<String>){
        target.sub("nav"){ navView->
            if(list.isEmpty()){
                navView.displayNone()
            }else{
                navView.displayBlock()
                navView.setClearList{ listView->
                    list.forEachIndexed{ idx, s ->
                        listView += eView(if(idx < list.size - 1) subTitleFactory1 else subTitleFactory2){ sub-> sub.sub("sub").html = s }
                    }
                }
            }
        }
    }
}